import type { History } from 'history'
import { useMemo } from 'react'
import type { LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link as RouterLink, useLocation } from 'react-router-dom'

type LinkProps<T extends Record<string, unknown> = Record<string, unknown>> = MOmit<
	RouterLinkProps<T>,
	'to'
> & {
	readonly to: History.LocationDescriptor<T>
}

// Creates a react-router link but adds state to it
// so that we know where we're navigating from
export const Link = <T extends Record<string, unknown> = Record<string, unknown>>(
	p: LinkProps<T>,
) => {
	const { pathname } = useLocation()

	const to = useMemo(() => {
		const fromState = { from: pathname }

		return typeof p.to === 'string'
			? { pathname: p.to, state: fromState }
			: { ...p.to, state: { ...p.to.state, ...fromState } }
	}, [p.to, pathname])

	return <RouterLink {...p} to={to} />
}
