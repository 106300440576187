// FIXME: Move this to persistence enhancer package in Eturi shared

import { useBoolState } from '@eturi/react'
import type { PersistenceExtra } from '@op/services'
import type { Store } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import { useLayoutEffect } from 'react'

type PersistGateProps = {
	readonly children?: ReactNode
	readonly loading: ReactNode
	readonly store: Store & PersistenceExtra
}

export const PersistGate = ({ children, loading, store }: PersistGateProps) => {
	const [isReady, setReady] = useBoolState(false)

	useLayoutEffect(() => {
		store.onReady(setReady)
	}, [])

	return <>{isReady ? children : loading}</>
}
