import { notEmpty, setIfNotEqual } from '@eturi/util'
import type { IdPayloadAction } from '@op/services'
import { createIdPayloadPrepare, pickIdPayload, resetAction } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import identity from 'lodash/identity'
import map from 'lodash/map'
import some from 'lodash/some'
import { createSliceTransformer } from 'rtk-slice-transformer'

export type HttpRetry = {
	readonly error: boolean
	readonly retries: number
}

export type HttpRetryState = {
	readonly [reqId: string]: HttpRetry
}

export type WithHttpRetryState = {
	readonly httpRetry: HttpRetryState
}

const initialState: HttpRetryState = {}

export const resetHttpRetries = createAction('httpRetry/reset')

export const httpRetrySlice = /*@__PURE__*/ createSlice({
	name: 'httpRetry',
	initialState,
	reducers: {
		deleteHttpRetry(s, a: PayloadAction<string>) {
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete s[a.payload]
		},

		updateHttpRetry: {
			prepare: createIdPayloadPrepare<Partial<HttpRetry>>(),
			reducer(s, a: IdPayloadAction<Partial<HttpRetry>>) {
				const [reqId, retryStateUpdate] = pickIdPayload(a)

				setIfNotEqual(s, reqId, { error: false, retries: 0, ...retryStateUpdate })
			},
		},
	},
	extraReducers: (builder) =>
		builder.addMatcher(isAnyOf(resetAction, resetHttpRetries), () => initialState),
})

export const { deleteHttpRetry, updateHttpRetry } = httpRetrySlice.actions

const isHttpRetryAction = /*@__PURE__*/ isAnyOf(deleteHttpRetry, updateHttpRetry)

// Just convert the map to a list. We don't care about the ids

export const httpRetrySliceTransformer = /*@__PURE__*/ createSliceTransformer(
	httpRetrySlice,
	(s) => map(s, identity),
	(a) => (isHttpRetryAction(a) ? null : a),
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithHttpRetryState>(s: T) => s.httpRetry

export const getHttpRetry = <T extends WithHttpRetryState>(s: T, reqId: string): Maybe<HttpRetry> =>
	state$(s)[reqId]

export const hasHttpRetryError$ = /*@__PURE__*/ createSelector(state$, (s) => some(s, 'error'))
export const isHttpRetrying$ = /*@__PURE__*/ createSelector(state$, notEmpty)
