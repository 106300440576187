import type { CSSProperties } from 'react'

type RecursiveArray<T> = (T | readonly T[] | RecursiveArray<T>)[]
type StyleProp<T> = T | RecursiveArray<T> | null | undefined | false

/**
 * Black magic for turning a union into an intersection.
 * @see https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
 */
type ReadonlyUnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
	k: infer I,
) => void
	? // NOTE: This extra mapping "flattens" the intersection into a single readonly object
	  { readonly [P in keyof I]: I[P] }
	: never

type CreateNamedStyles<T> = {
	readonly [P in keyof T]: StyleProp<CSSProperties>
}

type FlattenedNamedStyles<T extends CreateNamedStyles<any>> = {
	readonly [P in keyof T]: T[P] extends (infer U)[] ? ReadonlyUnionToIntersection<U> : T[P]
}

const flattenStyle = <S extends CSSProperties, T extends StyleProp<S>>(s: T): S => {
	if (!s) return undefined as any

	if (!Array.isArray(s)) return s as any

	const result: any = {}

	for (let i = 0, len = s.length; i < len; ++i) {
		const computedStyle = flattenStyle(s[i] as any)

		if (computedStyle) {
			for (const k in computedStyle) {
				result[k] = (computedStyle as any)[k]
			}
		}
	}

	return result
}

export const createStyles = <S extends CreateNamedStyles<any>>(
	styles: S,
): FlattenedNamedStyles<S> => {
	const v: any = {}

	for (const k in styles) {
		v[k] = flattenStyle(styles[k])
	}

	return v
}
