import type { AccessLevel } from './AccessLevel'
import { DEFAULT_ACCESS_LEVEL } from './AccessLevel'
import type { PurchasePlatform } from './PurchasePlatform'

/** Duration of a subscription cycle for the given def */
export type SubCycle = 'annual' | 'monthly'
export type PremTier = 'premium' | 'premium_plus'
export type PaidTier = 'plus' | PremTier

/**
 * These are specific tier defs associated with each sku def and are used to
 * delineate between internal SKUs (free, grandfathered, etc)
 */
export type TierDef = 'free' | 'grandfathered' | PaidTier

/** These are the main tier levels for SKUs. */
export type TierLevel = 'internal' | 'plus' | 'premium' | 'premium_plus'

const PremTiers: ReadonlySet<PremTier> = new Set<PremTier>(['premium', 'premium_plus'])
const PaidTiers: ReadonlySet<PaidTier> = new Set<PaidTier>(['plus', 'premium', 'premium_plus'])

/**
 * SKUDefs are expanded to include the associated ACL of their tier
 */
export type BaseSKUDef = {
	readonly billing_cycle?: SubCycle
	// NOTE: We comment this out so we don't use it. Names are mapped client side
	//  at least until the server decides to support these fields.
	// readonly description: string
	// readonly name: string
	readonly price_usd: number
	readonly purchasable_with?: any
	readonly purchase_platforms?: any
	readonly recurring: boolean
	readonly sku: string
	readonly tier: TierLevel
	readonly tier_def: TierDef
	readonly trial_days?: number
}

export type RawSKUDef = (BaseSKUDef & AccessLevel) & {
	readonly purchasable?: boolean
	readonly purchase_platforms?: string
	readonly purchasable_with?: string
}

export type SKUDef = AccessLevel &
	MOmit<BaseSKUDef, 'purchase_platforms' | 'purchasable_with'> & {
		readonly billing_cycle: SubCycle
		readonly purchase_platforms: PurchasePlatform[]
		readonly purchasable_with: PurchasePlatform[]
		readonly weight: number
	}

// NOTE: These names exist because we don't translate them
export const PLUS_NAME = 'Plus'
export const PREM_NAME = 'Premium'
export const PREM_PLUS_NAME = 'Premium+'

const TIER_TO_NAME = new Map<TierDef, string>([
	['plus', PLUS_NAME],
	['premium', PREM_NAME],
	['premium_plus', PREM_PLUS_NAME],
])

const TIER_DEF_WEIGHTS = new Map<TierDef, number>([
	['free', 0],
	['grandfathered', 1],
	['plus', 2],
	['premium', 3],
	['premium_plus', 4],
])

export const mapTierToName = (tier: Maybe<TierDef>): string =>
	tier ? TIER_TO_NAME.get(tier) || '' : ''
export const mapTierToFullName = (tier: Maybe<TierDef>): string =>
	tier ? `OurPact ${mapTierToName(tier)}` : ''
export const mapSKUDefToName = (def: SKUDef) => mapTierToName(def.tier_def)
export const mapSKUDefToFullName = (def: SKUDef) => mapTierToFullName(def.tier_def)

export const getTierWeight = (tierDef: TierDef): number => TIER_DEF_WEIGHTS.get(tierDef) || -1

export const isPlatformSKU = (def: SKUDef, pp: PurchasePlatform) =>
	def.purchase_platforms.includes(pp) && def.purchasable_with.includes(pp)

/** Try to find a SKU matching the TierLevels / PurchasePlatform */
export const getSKUForPlatformTier = (
	defs: SKUDef[],
	tierDef: TierDef,
	pp: PurchasePlatform,
): Maybe<SKUDef> => defs.find((d) => d.tier_def === tierDef && isPlatformSKU(d, pp))

export const toPriceStr = (price: Maybe<number>) => `${(price || 0).toFixed(2)}`
export const toSKUPriceStr = (sku: Maybe<SKUDef>) => toPriceStr(intToFloatPrice(sku?.price_usd))
export const intToFloatPrice = (price: Maybe<number>) => (price || 0) / 100
export const isPaidTier = (tier: any): tier is PaidTier => PaidTiers.has(tier)
export const isPremTier = (tier: any): tier is PremTier => PremTiers.has(tier)
export const isTierPrem = (tier: any): tier is 'premium' => tier === 'premium'

export const mapRawToSKUDef = (raw: RawSKUDef): SKUDef => ({
	...raw,
	billing_cycle: raw.billing_cycle || 'monthly',
	purchasable_with: (raw.purchasable_with || '').split(',') as PurchasePlatform[],
	purchase_platforms: (raw.purchase_platforms || '').split(',') as PurchasePlatform[],
	weight: getTierWeight(raw.tier_def),
})

export const DEFAULT_SKU_DEF = {
	...DEFAULT_ACCESS_LEVEL,
	billing_cycle: 'monthly',
	price_usd: 0,
	purchasable_with: [],
	purchase_platforms: [],
	recurring: false,
	sku: 'free',
	tier: 'internal',
	tier_def: 'free',
	weight: getTierWeight('free'),
} satisfies SKUDef
