import {
	IOS_DEBOUNCE_SCROLL,
	PASSIVE_CAPTURE,
	useConstant,
	useDebounce,
	useFn,
	useWindowEvent,
} from '@eturi/react'
import { pick } from '@eturi/util'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useRef } from 'react'
import isEqual from 'react-fast-compare'

type LineClampProps = {
	readonly ellipsis?: string
	readonly maxLines?: number
	readonly text: string
}

type LineClampRequiredKeys = (typeof LineClampRequiredKeys)[number]
const LineClampRequiredKeys = ['font', 'fontSize', 'lineHeight', 'wordBreak'] as const
type LineClampStyle = Pick<CSSStyleDeclaration, LineClampRequiredKeys>

type SplitStrCurrent = string
type SplitStrRemaining = string
type SplitStr = [SplitStrCurrent, SplitStrRemaining]

type LineClampMeasurements = {
	readonly maxHeight: number
	readonly offsetWidth: number
	readonly scrollWidth: number
}

export const LineClamp = ({ ellipsis = '...', maxLines = 3, text }: LineClampProps) => {
	// Reference to the canvas context we use to measure text
	const canvasCtx = useConstant(() => document.createElement('canvas').getContext('2d')!)

	// Reference to the element that contains the text
	const textElRef = useRef<HTMLParagraphElement>(null as any)

	// The following are references that we want to maintain between renders
	const measurements = useRef<LineClampMeasurements>({
		maxHeight: 0,
		offsetWidth: 0,
		scrollWidth: 0,
	})

	const styles = useRef<LineClampStyle>({
		font: '',
		fontSize: '',
		lineHeight: 'normal',
		wordBreak: '',
	})

	// Apply given chunks to current string and ellipsis as text content of
	// native element
	const applyChunks = useFn((current: string, chunks: string[]) => {
		const joinedText = chunks.join('') + current
		const applyEllipsis = joinedText.length < text.length

		textElRef.current.textContent = applyEllipsis ? joinedText + ellipsis : joinedText
	})

	// Recursively calibrates chunks until they fit
	const calibrateChunksToFit = useFn((splitStr: SplitStr, chunks: string[]) => {
		// Try to use current part of split string
		const [current, remaining] = splitStr
		const fits = isFitToHeight(current, chunks)

		if (fits) chunks.push(current)

		// If only one symbol is left at current part or current chunks fit to the
		// height and no remaining parts are left then use current chunks and return
		if ((current.length <= 1 || fits) && remaining.length === 0) {
			return applyChunks('', chunks)
		}

		// Obtain the next splitStr based on isFitToHeight if adding current part
		// fit to the height use it and try to add more from start of remaining
		// part else split current part to smaller parts (slice from the end)
		const next = fits ? split(remaining, true) : split(current, false)

		calibrateChunksToFit(next, chunks)
	})

	// Check is current text and given chunks fit into the max height
	const isFitToHeight = useFn((current: string, chunks: string[]): boolean => {
		const { maxHeight, offsetWidth, scrollWidth } = measurements.current

		applyChunks(current, chunks)

		return textElRef.current.offsetHeight <= maxHeight && scrollWidth <= offsetWidth
	})

	// Get estimated start split str with slicing str by estimated length
	const getEstimatedStartSplitStr = useFn((measuredText: number): SplitStr => {
		const avgCharWidth = measuredText / (text.length + ellipsis.length)
		const estimatedLength = Math.floor((measurements.current.offsetWidth * maxLines) / avgCharWidth)

		return [text.slice(0, estimatedLength), text.slice(estimatedLength)]
	})

	const getMeasurement = useFn((): SplitStr => {
		// Calculate measured text width including ellipsis. At this point we know
		// that we definitely will need to apply ellipsis an ellipsis.
		const textWidth = Math.round(canvasCtx.measureText(text + ellipsis).width)

		return textWidth ? getEstimatedStartSplitStr(textWidth) : bisect(text)
	})

	// Checks if text will fit using word-break: break-word
	const isFitWithWordBreak = useFn((): boolean => {
		const $el = textElRef.current
		const style = styles.current

		if (style.wordBreak !== 'break-word') {
			$el.style.wordBreak = style.wordBreak = 'break-word'

			// Re-measure w/ break-word
			measurements.current = { ...measurements.current, scrollWidth: $el.scrollWidth }

			return isFitToHeight(text, [])
		}

		return false
	})

	// Clamp textData to fit into the element height if required
	const clampToFitHeight = useFn(() => {
		// Attempt clamp operations in order of speed:
		// - If text is empty, it always fits
		// - If text is measured and fits, do nothing
		// - If we try to apply `word-break: break-word` and it fits, return
		// - Otherwise, perform more expensive calibration
		if (isEmpty(text) || isFitToHeight(text, []) || isFitWithWordBreak()) return

		calibrateChunksToFit(getMeasurement(), [])
	})

	// Check if current and stored style / dimensions are different and if so
	// clamp textData to fit into the element height with new values. On init and
	// inputs changes we need to force clamping.
	const checkIfClampRequired = useFn((force: boolean) => {
		const $el = textElRef.current
		const { offsetWidth, scrollWidth } = measurements.current
		const newStyles = pick(window.getComputedStyle($el), LineClampRequiredKeys)
		const newOffsetWidth = $el.offsetWidth
		const newScrollWidth = $el.scrollWidth

		if (
			force ||
			!isEqual(styles, newStyles) ||
			offsetWidth !== newOffsetWidth ||
			scrollWidth !== newScrollWidth
		) {
			canvasCtx.font = newStyles.font
			measurements.current = {
				// Max height of the element is just the line height times the maxLines
				maxHeight: getLineHeightFromStyles(newStyles) * maxLines,
				offsetWidth: newOffsetWidth,
				scrollWidth: newScrollWidth,
			}
			styles.current = newStyles

			clampToFitHeight()
		}
	})

	// Recheck if line clamp is required if text, ellipsis, or max lines change
	useEffect(() => {
		checkIfClampRequired(true)
	}, [ellipsis, maxLines, text])

	// On resize we should check line clamp again
	useWindowEvent(
		'resize',
		useDebounce(() => checkIfClampRequired(false), IOS_DEBOUNCE_SCROLL),
		PASSIVE_CAPTURE,
	)

	return (
		<p ref={textElRef} title={text}>
			{text}
		</p>
	)
}

// Divide string into two parts
const bisect = (str: string): SplitStr => {
	const middle = Math.floor(str.length / 2)
	const canBisect = str.length >= 2

	return [canBisect ? str.slice(0, middle) : str, canBisect ? str.slice(middle, str.length) : '']
}

// NOTE: 1.2 is the default if 'normal'. To get px, multiply times fontSize
const getLineHeightFromStyles = ({ fontSize, lineHeight }: LineClampStyle): number =>
	lineHeight === 'normal'
		? Number.parseInt(fontSize || '1', 10) * 1.2
		: Number.parseInt(lineHeight || '1', 10)

// Split string into two parts
const split = (str: string, isSliceFromStart: boolean): SplitStr =>
	str.length > 4 ? splitByFour(str, isSliceFromStart) : bisect(str)

// Split string into two parts. Four chars from start or end
// (according to direction) and rest of string
const splitByFour = (str: string, isSliceFromStart: boolean): SplitStr => {
	const splitIndex = isSliceFromStart ? 4 : str.length - 4

	return [str.slice(0, splitIndex), str.slice(splitIndex)]
}
