import type { CssGlyphName } from '@op/icons'
import cls from 'classnames'
import type { BtnSize, ButtonBaseProps, ButtonTags } from '../../types'
import { getBtnCls, getBtnType } from '../../types'
import { Glyph } from '../../widgets/Glyph'

export type ActionBtnIconPos = 'L' | 'R'

export type ActionBtnProps<T extends ButtonTags = 'button'> = ButtonBaseProps<T> & {
	readonly icon: CssGlyphName
	readonly iconCls?: string
	readonly iconPos?: ActionBtnIconPos
	readonly wrapClassName?: string
}

const BtnSizeMap = {
	lg: 'action-btn-lg',
	md: 'action-btn-md',
	sm: 'action-btn-sm',
} satisfies { [S in BtnSize]: string }

export const ActionBtn = <T extends ButtonTags = 'button'>({
	active = false,
	color = 'teal',
	children,
	className,
	fat = false,
	icon,
	iconCls,
	iconPos = 'L',
	invert = false,
	size = 'md',
	tag = 'button' as T,
	wrapClassName,
	...p
}: ActionBtnProps<T>) => {
	const hasBtnTxt = Boolean(children)
	const btnCls = getBtnCls(
		active,
		color,
		fat,
		invert,
		size,
		'action-btn',
		BtnSizeMap[size],
		hasBtnTxt && 'action-text-btn',
		className,
	)
	const type = getBtnType(tag, p)
	const Wrapper = tag as any

	return (
		<Wrapper {...p} className={btnCls} type={type}>
			<span
				className={cls(
					'flex-center h-full w-full',
					iconPos === 'R' && 'flex-row-reverse',
					wrapClassName,
				)}
			>
				<Glyph className={iconCls} name={icon} size={size} />
				{hasBtnTxt && <span className={iconPos === 'L' ? 'ml-3' : 'mr-3'}>{children}</span>}
			</span>
		</Wrapper>
	)
}
