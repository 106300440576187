import type { MomentInput } from 'moment-timezone'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { momentLocale$ } from '../reducers/app-misc.slice'

type DateTimeProps = {
	readonly date: MomentInput
	readonly format: string
}

export const DateTime = ({ date, format }: DateTimeProps) =>
	useMemo(
		() => <span className="v-date-time">{moment(date).format(format)}</span>,
		[useSelector(momentLocale$), date, format],
	)
