import { useConstant, useFn } from '@eturi/react'
import type { FormState } from 'final-form'
import { useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { Form, FormSpy } from 'react-final-form'
import { Btn } from '../components/Buttons'
import {
	Check,
	CheckboxGroup,
	CheckBtn,
	CheckField,
	FormLabel,
	Radio,
	RadioBtn,
	RadioField,
	RadioGroup,
} from '../components/Forms'
import { minLength, required } from '../validators'

const FormStateOutput = ({
	errors,
	submitFailed,
	submitSucceeded,
	valid,
	values,
}: FormState<any>) => (
	<pre>
		<code>{JSON.stringify({ errors, submitFailed, submitSucceeded, valid, values }, null, 2)}</code>
	</pre>
)

const CHECK_BUTTONS_NAME = 'checkGroupButtons' as const
const CHECK_LABELS_NAME = 'checkGroupLabelled' as const
const H3_STYLES = { marginBottom: '2rem' }
const RADIO_BUTTONS_NAME = 'radioGroupButtons' as const
const RADIO_LABELS_NAME = 'radioGroupLabelled' as const

type CheckLabelledType = ValueUnion<typeof CheckLabelledType>
const CheckLabelledType = {
	BUTTONS_REVERSED: 'areButtonsReversed',
	BUTTONS_SPACED: 'areButtonsSpaced',
	BUTTONS_STACKED: 'areButtonsStacked',
	LABELLED_REVERSED: 'areLabelledReversed',
	LABELLED_SPACED: 'areLabelledSpaced',
}

type CheckButtonType = ValueUnion<typeof CheckButtonType>
const CheckButtonType = {
	A: 'CheckboxGroupButtonA',
	B: 'CheckboxGroupButtonB',
	C: 'CheckboxGroupButtonC',
}

type RadioButtonType = ValueUnion<typeof RadioButtonType>
const RadioButtonType = {
	A: 'RadioGroupButtonA',
	B: 'RadioGroupButtonB',
	C: 'RadioGroupButtonC',
}

type RadioLabelledType = ValueUnion<typeof RadioLabelledType>
const RadioLabelledType = {
	A: 'RadioGroupLabelA',
	B: 'RadioGroupLabelB',
	C: 'RadioGroupLabelC',
}

type GroupsWithLabelsProps = {
	readonly isReversed: boolean
	readonly isSpaced: boolean
}

type GroupsWithButtonsProps = {
	readonly isReversed: boolean
	readonly isSpaced: boolean
	readonly isStacked: boolean
}

type RadioCheckTestPageFormValues = {
	readonly checkGroupButtons: CheckButtonType[]
	readonly checkGroupLabelled: CheckLabelledType[]
	readonly radioGroupButtons: RadioButtonType
	readonly radioGroupLabelled: RadioLabelledType
}

const CheckboxButtonsGroupTest = (p: GroupsWithButtonsProps) => (
	<div>
		<h3 style={H3_STYLES}>Checkbox Group Buttons</h3>

		<div className="field">
			<FormLabel label="Select two or more" {...p}>
				<CheckboxGroup name={CHECK_BUTTONS_NAME} validate={minLength('Select at least two!', 2)}>
					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.A}
						render={({ input }) => <CheckBtn {...input}>A</CheckBtn>}
					/>

					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.B}
						render={({ input }) => <CheckBtn {...input}>B</CheckBtn>}
					/>

					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.C}
						render={({ input }) => <CheckBtn {...input}>C</CheckBtn>}
					/>
				</CheckboxGroup>
			</FormLabel>
		</div>
	</div>
)

const CheckboxLabelsGroupTest = (p: GroupsWithLabelsProps) => (
	<div>
		<h3 style={H3_STYLES}>Checkbox Group Labelled</h3>

		<div className="field">
			<CheckboxGroup isStacked={true}>
				<CheckField
					name={CHECK_LABELS_NAME}
					value={CheckLabelledType.BUTTONS_REVERSED}
					render={({ input }) => (
						<Check {...input} {...p}>
							Reverse Labelled Buttons
						</Check>
					)}
				/>

				<CheckField
					name={CHECK_LABELS_NAME}
					value={CheckLabelledType.BUTTONS_SPACED}
					render={({ input }) => (
						<Check {...input} {...p}>
							Space Labelled Buttons (Unstacked Only)
						</Check>
					)}
				/>

				<CheckField
					name={CHECK_LABELS_NAME}
					value={CheckLabelledType.BUTTONS_STACKED}
					render={({ input }) => (
						<Check {...input} {...p}>
							Stack Labelled Buttons
						</Check>
					)}
				/>

				<CheckField
					name={CHECK_LABELS_NAME}
					value={CheckLabelledType.LABELLED_REVERSED}
					render={({ input }) => (
						<Check {...input} {...p}>
							Reverse Individually Labelled
						</Check>
					)}
				/>

				<CheckField
					name={CHECK_LABELS_NAME}
					value={CheckLabelledType.LABELLED_SPACED}
					render={({ input }) => (
						<Check {...input} {...p}>
							Space Individually Labelled
						</Check>
					)}
				/>
			</CheckboxGroup>
		</div>
	</div>
)

const RadioButtonGroupTest = (p: GroupsWithButtonsProps) => (
	<div>
		<h3 style={H3_STYLES}>Radio Group Buttons</h3>

		<div className="field">
			<FormLabel label="Select a radio button" {...p}>
				<RadioGroup name={RADIO_BUTTONS_NAME} validate={required('Select a radio button value')}>
					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.A}
						render={({ input }) => (
							<RadioBtn {...input} tabIdx={0}>
								A
							</RadioBtn>
						)}
					/>

					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.B}
						render={({ input }) => (
							<RadioBtn {...input} tabIdx={1}>
								B
							</RadioBtn>
						)}
					/>

					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.C}
						render={({ input }) => (
							<RadioBtn {...input} tabIdx={2}>
								C
							</RadioBtn>
						)}
					/>
				</RadioGroup>
			</FormLabel>
		</div>
	</div>
)

const RadioLabelsGroupTest = (p: GroupsWithLabelsProps) => (
	<div>
		<h3 style={H3_STYLES}>Radio Group Labelled</h3>

		<div className="field">
			<RadioGroup
				isStacked={true}
				name={RADIO_LABELS_NAME}
				validate={required('Select a radio with label')}
			>
				<RadioField
					name={RADIO_LABELS_NAME}
					value="RadioGroupLabelA"
					render={({ input }) => (
						<Radio {...input} {...p}>
							Radio Group Label A
						</Radio>
					)}
				/>

				<RadioField
					name={RADIO_LABELS_NAME}
					value="RadioGroupLabelB"
					render={({ input }) => (
						<Radio {...input} {...p}>
							Radio Group Label B
						</Radio>
					)}
				/>

				<RadioField
					name={RADIO_LABELS_NAME}
					value="RadioGroupLabelC"
					render={({ input }) => (
						<Radio {...input} {...p}>
							Radio Group Label C
						</Radio>
					)}
				/>
			</RadioGroup>
		</div>
	</div>
)

export const RadioCheckTestPage = () => {
	const initialFormValues = useConstant(
		(): RadioCheckTestPageFormValues => ({
			checkGroupButtons: [CheckButtonType.C],
			checkGroupLabelled: [CheckLabelledType.BUTTONS_SPACED],
			radioGroupButtons: RadioButtonType.B,
			radioGroupLabelled: RadioLabelledType.A,
		}),
	)

	const [v, setV] = useState(() => initialFormValues.checkGroupLabelled)

	const handleFormChange = useFn((fs: FormState<RadioCheckTestPageFormValues>) => {
		const {
			values: { checkGroupLabelled },
		} = fs

		if (checkGroupLabelled && !isEqual(checkGroupLabelled, v)) {
			setV(checkGroupLabelled)
		}
	})

	const groupsWithLabelsProps = useMemo(
		(): GroupsWithLabelsProps => ({
			isReversed: v.includes(CheckLabelledType.LABELLED_REVERSED),
			isSpaced: v.includes(CheckLabelledType.LABELLED_SPACED),
		}),
		[v],
	)

	const groupsWithButtonsProps = useMemo(
		(): GroupsWithButtonsProps => ({
			isReversed: v.includes(CheckLabelledType.BUTTONS_REVERSED),
			isSpaced: v.includes(CheckLabelledType.BUTTONS_SPACED),
			isStacked: v.includes(CheckLabelledType.BUTTONS_STACKED),
		}),
		[v],
	)

	const handleSubmit = useFn(() => {
		/**/
	})

	return (
		<div className="modal__dialog" style={{ width: '100%' }}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialFormValues}
				render={(fp) => (
					<form onSubmit={fp.handleSubmit} style={{ padding: '15px' }}>
						<CheckboxLabelsGroupTest {...groupsWithLabelsProps} />
						<CheckboxButtonsGroupTest {...groupsWithButtonsProps} />
						<RadioLabelsGroupTest {...groupsWithLabelsProps} />
						<RadioButtonGroupTest {...groupsWithButtonsProps} />

						<FormStateOutput {...fp} />

						<FormSpy subscription={{ values: true }} onChange={handleFormChange} />

						<div className="modal__bottom modal__bottom--stacked">
							<Btn type="submit">Submit</Btn>
							<Btn onClick={fp.form.reset}>Reset</Btn>
						</div>
					</form>
				)}
			/>
		</div>
	)
}
