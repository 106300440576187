import { timeoutPromise } from '@eturi/util'
import type { History } from 'history'
import type { match } from 'react-router-dom'
import type { AppStore } from '../types'

export type OPResolver = (
	store: AppStore,
	history: History,
	match: Maybe<match>,
) => Promise<void> | void

/** Takes in functions and returns a function that resolves them serially */
export const resolveSerial =
	<T extends any[]>(...args: T) =>
	async (store: AppStore, history: History, match: Maybe<match>) => {
		// Wait at least one tick for other history behavior to finish. This might
		// be in `useBackButton`, for example, where we have to pop and replace
		// some records when the user navigates so the history doesn't get screwy.
		await timeoutPromise(0)
		for (const fn of args) await fn(store, history, match)
	}

/** Takes in functions and returns a function that resolves them in parallel */
export const resolveParallel =
	<T extends any[]>(...fns: T) =>
	async (store: AppStore, history: History, match: Maybe<match>) => {
		// See resolveSerial
		await timeoutPromise(0)
		await Promise.all(fns.map((f) => f(store, history, match)))
	}

export type ResolverRedirect = {
	readonly type: typeof RESOLVER_REDIRECT_TOKEN
	readonly path: string
}

export const RESOLVER_REDIRECT_TOKEN = '__Resolver_Redirect__' as const

export const createResolverRedirect = (path: string): ResolverRedirect => ({
	type: RESOLVER_REDIRECT_TOKEN,
	path,
})

export const isResolverRedirect = (err: any): err is ResolverRedirect =>
	err?.type === RESOLVER_REDIRECT_TOKEN
