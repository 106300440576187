import { useFn } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { useHandleSynStop } from '@op/react-web'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { LineClamp } from '../../common'
import { Btn } from '../Buttons'

export type ToastType = 'action' | 'confirm' | 'error' | 'info'

export type ToastProps = {
	readonly close: () => void
	readonly maxLines?: number
	readonly msg: string
	readonly onClose?: () => void
	readonly persistent?: boolean
	readonly type?: ToastType
}

export const Toast = ({
	children,
	close,
	maxLines = 3,
	msg,
	onClose = noop,
	persistent = false,
	type = 'error',
}: ToastProps & { readonly children?: ReactNode }) => {
	const handleClose = useFn(() => !persistent && close())

	// Trigger close when unmounted
	useEffect(
		() => () => {
			onClose()
		},
		[],
	)

	return (
		<div
			className={cls('toast-content', { error: type === 'error' })}
			onClick={handleClose}
			title={msg}
		>
			<LineClamp maxLines={maxLines} text={msg} />
			{children}
		</div>
	)
}

export type ActionToastProps = Omit<ToastProps, 'type'> & {
	readonly actionTxt: string
	readonly onAction: () => void
}

export const ActionToast = ({ actionTxt, onAction, ...p }: ActionToastProps) => {
	const [t] = useT()
	const handleAction = useHandleSynStop(() => {
		onAction()
		p.close()
	})

	return (
		<Toast {...p} close={noop} type="action">
			<Btn autoFocus className="mt-2.5 mx-auto w-1/2" onClick={handleAction}>
				{t(actionTxt)}
			</Btn>
		</Toast>
	)
}

export type ConfirmToastProps = Omit<ToastProps, 'type'> & {
	readonly onCancel: () => void
	readonly onConfirm: () => void
}

export const ConfirmToast = ({ onCancel, onConfirm, ...p }: ConfirmToastProps) => {
	const [t] = useT()

	const handleCancel = useHandleSynStop(() => {
		onCancel()
		p.close()
	})

	const handleConfirm = useHandleSynStop(() => {
		onConfirm()
		p.close()
	})

	return (
		<Toast {...p} close={noop} type="confirm">
			<div className="form-actions-horizontal">
				<Btn onClick={handleCancel} autoFocus={true}>
					{t('actions.cancel')}
				</Btn>

				<Btn className="border border-solid border-teal" invert onClick={handleConfirm}>
					{t('actions.delete')}
				</Btn>
			</div>
		</Toast>
	)
}
