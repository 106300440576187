import findIndex from 'lodash/findIndex'
import isEqual from 'react-fast-compare'

type EntityIdUpdate<T, Id extends keyof T> = Partial<T> & WithRequired<Partial<T>, Id>

/** Mutates the entity in the array. (Use w/ immer) */
export const updateEntityInList = <T, Id extends keyof T, TU extends EntityIdUpdate<T, Id>>(
	arr: T[],
	item: TU,
	id: Id,
) => {
	const currIdx = findIndex(arr, { [id]: item[id] } as any)

	if (currIdx === -1) return

	const currItem = arr[currIdx]

	for (const [k, v] of Object.entries(item)) {
		const currV = (currItem as any)[k]

		if (!isEqual(currV, v)) {
			;(currItem as any)[k] = v
		}
	}
}
