import { lazy } from 'react'

export const PwResetPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'reset' */
			/* webpackPrefetch: 10 */
			'./PwResetPage'
		),
)
