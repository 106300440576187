export type AppDetails = {
	readonly advisories?: string[]
	readonly contentRating?: string
	readonly description?: string
	readonly emptyDetails: boolean
	readonly fetchTs: number
	readonly genres?: string[]
	readonly locale: string
	readonly logoUrl: Maybe<string>
	readonly rating?: number
	readonly screenshotUrls?: string[]
	readonly title?: string
	readonly videoUrls?: string[]
}

export type AppDetailsRes = {
	readonly info: RawAppInfo
	readonly meta: RawAppMetaDetails
	readonly raw: RawAndroidAppDetails | RawIOSAppDetails
	readonly status: string
	readonly ts: number
}

export type RawAppInfo = {
	readonly advisories?: string[]
	readonly app_name: string
	readonly category: string
	readonly content_rating: string
	readonly description: string
	readonly icon: AppDetailIcons
	readonly media: RawAppMedia[]
	readonly publisher: string
	readonly user_rating: number
}

export type RawAppMetaDetails = {
	readonly app_id: string
	readonly cache_ts: number
	readonly cached: boolean
	readonly fetch_ts: number
	readonly info_type: RawAppMetaType
	readonly locale: string
	readonly platform: string
}

export type RawAppMetaType = 'app_store' | 'builtin' | 'not_found'

export type AppDetailIcons = {
	readonly small: string
	readonly medium: string
	readonly large: string
}

export type RawAppMedia = {
	readonly type: string
	readonly url: string
}

export type RawAuthorDetails = {
	readonly '@type': string
	readonly name: string
	readonly url: string
}

export type RawRatingsDetails = {
	readonly '@type': string
	readonly ratingValue: string
	readonly ratingCount: string
}

export type RawOffersDetails = {
	readonly '@type': string
	readonly price: string
	readonly priceCurrency: string
	readonly availability: string
}

export type RawAndroidAppDetails = {
	readonly '@context': string
	readonly '@type': string
	readonly name: string
	readonly image: string
	readonly url: string
	readonly description: string
	readonly operatingSystem: string
	readonly applicationCategory: string
	readonly contentRating: string
	readonly author: RawAuthorDetails
	readonly aggregateRating: RawRatingsDetails
	readonly offers: RawOffersDetails[]
}

export type RawIOSAppDetails = {
	readonly advisories?: string[]
	readonly appletvScreenshotUrls: string[]
	readonly artistId: number
	readonly artistName: string
	readonly artistViewUrl: string
	readonly artworkUrl60: string
	readonly artworkUrl100: string
	readonly artworkUrl512: string
	readonly averageUserRating: number
	readonly averageUserRatingForCurrentVersion: number
	readonly bundleId: string
	readonly contentAdvisoryRating: string
	readonly currency: string
	readonly currentVersionReleaseDate: string
	readonly description: string
	readonly features: string[]
	readonly fileSizeBytes: string
	readonly formattedPrice: string
	readonly genreIds: string[]
	readonly genres: string[]
	readonly ipadScreenshotUrls: string[]
	readonly isGameCenterEnabled: boolean
	readonly isVppDeviceBasedLicensingEnabled: boolean
	readonly kind: string
	readonly languageCodesISO2A: string[]
	readonly minimumOsVersion: string
	readonly price: number
	readonly primaryGenreId: number
	readonly primaryGenreName: string
	readonly releaseDate: string
	readonly releaseNotes: string
	readonly screenshotUrls: string[]
	readonly sellerName: string
	readonly sellerUrl: string
	readonly supportedDevices: string[]
	readonly trackCensoredName: string
	readonly trackContentRating: string
	readonly trackId: number
	readonly trackName: string
	readonly trackViewUrl: string
	readonly userRatingCount: number
	readonly userRatingCountForCurrentVersion: number
	readonly version: string
	readonly wrapperType: string
}

export type AppDetailsMap = {
	readonly [bundleId: string]: Maybe<AppDetails>
}

/**
 *  By default Google adds '-rw' ending to urls but that is used to serve WebP
 *  in order to support safari we omit that to force it to serve PNGs
 */
const _forcePNGImageURL = (url = '') => url.replace(/-rw$/, '')

/* Generates App Details for not found apps */
export const genNotFoundAppDetails = (locale: string): AppDetails => ({
	emptyDetails: true,
	fetchTs: Date.now(),
	locale,
	logoUrl: null,
})

/* Maps Built-in Apps to AppDetails */
export const mapBuiltInRawAppInfoToDetails = (raw: RawAppInfo, locale: string): AppDetails => ({
	emptyDetails: true,
	fetchTs: Date.now(),
	locale,
	logoUrl: _forcePNGImageURL(raw.icon.medium),
})

/** Maps raw app info to AppDetails */
export const mapRawAppInfoToDetails = (raw: RawAppInfo, locale: string): AppDetails => {
	// Media can contain both videos and images, so we separate the two
	const videoUrls: string[] = []
	const screenshotUrls: string[] = []

	for (const { type, url } of raw.media) {
		if (type === 'screenshot') {
			screenshotUrls.push(_forcePNGImageURL(url))
		} else if (type === 'video') {
			videoUrls.push(url)
		}
	}

	return {
		advisories: raw.advisories,
		contentRating: raw.content_rating,
		description: raw.description,
		emptyDetails: false,
		fetchTs: Date.now(),
		// Google Genres come with a '_' for spaces
		genres: [(raw.category || '').replace(/_/g, ' ')],
		locale,
		logoUrl: _forcePNGImageURL(raw.icon.medium),
		rating: Number(raw.user_rating),
		screenshotUrls,
		title: raw.app_name,
		videoUrls,
	}
}

/*
NOTE: Tried to parse dimensions from URIs, but Google URIs do not encode the
 dimensions. They appear to (e.g. =w400-h200), but these numbers only
 represent the max width and height for the server to return. Google will then
 send back a scaled image based on those constraints.
 -
 For example, if the intrinsic dims for an image are 1920x1080, and the URI has
 w400- h200 as above, Google will compare the aspect ratios and will return an
 image that fits those constraints. ~1.78 intrinsic vs 2 requested. Since
 intrinsic has a lower ratio, than requested, Google uses the height as the
 reference constraint returning 356x200 image (1920 / (1080 / 200)x200). There
 may be more to the algorithm, but that's the basics.
 -
 Apple, on the other hand returns the correct size, so we could hypothetically
 use this.
 */

// Apple URI: https://[LONG_CDN]/[IMAGE_NAME].[jpg|png]/[IMAGE_WIDTH]x[IMAGE_HEIGHT]bb.[JPG|PNG]
const APPLE_SCREENSHOT_REGEX = /\/[1-9]\d+x[1-9]\d+bb\.(jpe?g|png)$/

// Google URI: https://play-lh.googleusercontent.com/[LONG_ID]=w[IMAGE_WIDTH]-h[IMAGE_HEIGHT]
const GOOGLE_SCREENSHOT_REGEX = /=w[1-9]\d+-h[1-9]\d+$/i

/**
 * Transforms a screenshot URI into a new one with the desired size. Note that
 * there's no reason to parse and scale each dimension, because each service
 * will automatically keep the correct aspect ratio and will just use the long
 * side as reference.
 *
 * In testing, parsing the dimensions and passing an invalid ratio actually
 * leads to 400 errors, so this is more robust anyway.
 */
export const buildAppDetailsFsURI = (baseURI: string, desiredSize: number) => {
	const appleMatch = APPLE_SCREENSHOT_REGEX.exec(baseURI)

	if (appleMatch) {
		const [, ext] = appleMatch

		return `${baseURI.slice(0, appleMatch.index)}/${desiredSize}x${desiredSize}.${ext}`
	}

	// Google doesn't have an extension, so we can just replace.
	return baseURI.replace(GOOGLE_SCREENSHOT_REGEX, `=w${desiredSize}-h${desiredSize}`)
}
