import type { LatLng } from '@op/services'
import mapValues from 'lodash/mapValues'

export const LOCATION_HISTORY_POLL_TS = 900_000
/**
 * NOTE: All history should add 1 for "today" when displaying, per Dustin. This
 *  means we get 7 days of real history, plus whatever today holds. Only use
 *  this value when displaying to user.
 */
export const MAX_LOCATION_HISTORY_DAYS_PREM = 7

/**
 * NOTE: All history should add 1 for "today" when displaying, per Dustin. This
 *  means we get 30 days of real history, plus whatever today holds. Only use
 *  this value when displaying to user.
 */
export const MAX_LOCATION_HISTORY_DAYS_PREM_PLUS = 30

///////////////////////////////////////
// RAW User Location History Objects //
///////////////////////////////////////

export type RawLocationHistory = {
	readonly [userId: string]: RawUserGeoHistory
}

export type RawUserGeoHistory = {
	readonly device_info: RawGeoDeviceHistory
	readonly history_date: string // ISO format YYYY-MM-DD
}

export type RawGeoDeviceHistory = {
	readonly [deviceId: string]: RawDeviceGeoHistory
}
export type RawDeviceGeoHistory = {
	readonly points: RawLocationHistoryPoint[]
	readonly segments: RawLocationHistorySegment[]
	readonly stats: RawLocationHistoryStats
}

export type RawLocationHistoryInfo = {
	readonly count_loc: number
	readonly count_loc1: number
	readonly end_ts: number
	readonly seg_id: number
	readonly seg_end?: boolean
	readonly seg_start: boolean
	readonly seg_time: boolean
	readonly start_ts: number
}

export type RawLocationHistoryPoint = {
	readonly acc: number
	readonly cdup: number
	readonly end: boolean
	readonly end_ts: number
	readonly engine?: string
	readonly formatted_address?: string
	readonly gap_t: number
	readonly gap_xy: number
	readonly lat: number
	readonly lon: number
	readonly place?: string // ID of Geofence
	readonly point: boolean
	readonly rad: number
	readonly start_ts: number
	readonly stay_sec: number
}

export type RawLocationHistorySegment = {
	readonly area: number
	readonly avg_speed: number
	readonly cloc_path: number
	readonly cloc_per_m: number
	readonly cloc_place: number
	readonly cloc_point: number
	readonly cloc_rest: number
	readonly cloc_total: number
	readonly dt_s: number
	readonly dx_m: number
	readonly dxy_m: number
	readonly dy_m: number
	readonly end_ts: number
	readonly gap_t: number
	readonly impossible: boolean
	readonly lat_cent: number
	readonly lat_max: number
	readonly lat_min: number
	readonly lon_cent: number
	readonly lon_max: number
	readonly lon_min: number
	readonly modality: string
	readonly path: RawLocationHistorySegmentPath[]
	readonly path_dist: number
	readonly seg_dist: boolean
	readonly seg_hash: string
	readonly seg_id: number
	readonly start_ts: number
}

export type RawLocationHistorySegmentPath = {
	readonly acc: number
	readonly end?: boolean
	readonly end_ts: number
	readonly lat: number
	readonly lon: number
	readonly point?: boolean
	readonly start?: boolean
	readonly start_ts: number
	readonly stay_sec?: number
}

export type RawLocationHistoryStats = {
	readonly count_deduped: number
	readonly count_fetched: number
	readonly count_filtered: number
	readonly count_places: number
	readonly count_segments: number
	readonly count_simplified: number
	readonly count_spoints: number
}

export type RawLocationHistoryAvailability = {
	readonly [userId: string]: Maybe<{
		readonly device_info: {
			readonly [deviceId: string]: Maybe<{
				readonly location_dates: {
					readonly [date: string]: Maybe<{
						readonly location_available: boolean
					}>
				}
			}>
		}
	}>
}

////////////////////////////////////////
// DECORATED Location History Objects //
////////////////////////////////////////

export type LocationHistory = {
	readonly [userId: string]: DeviceHistoryMap
}

export type DeviceHistoryMap = {
	readonly [deviceId: string]: DeviceGeoHistory
}

export type DeviceGeoHistory = {
	readonly path: LocationHistoryData[]
	readonly points: LocationHistoryPointData[]
}

export type LocationHistoryData = LatLng & {
	readonly location_ts: number
}

export type LocationHistoryPointData = LatLng & {
	readonly end_ts: Maybe<number>
	readonly location_ts: number
	readonly start_ts: number
	readonly formatted_address?: string
	readonly place?: string // ID of Geofence
}

///// Location Address Lookup
export type LocationLookupRes = {
	readonly result: AddressLocationLookup[]
	readonly status: string
	readonly type: 'reverse_geocode'
}

export type AddressLocationLookup = {
	readonly engine: string
	readonly formatted_address: string
	readonly grid_lat: number
	readonly grid_lon: number
	readonly lat: number
	readonly lon: number
}

export type LocationAddressMap = {
	readonly [latLngKey: string]: string
}

//// Mapping Location History

export const mapRawToLocationHistory = (rawLocationHistory: RawLocationHistory): LocationHistory =>
	mapValues(rawLocationHistory, mapRawToUserGeoHistory)

export const mapRawToUserGeoHistory = (rawUserGeo: RawUserGeoHistory): DeviceHistoryMap =>
	mapValues(rawUserGeo.device_info, mapRawToDeviceGeoHistory)

export const mapRawToDeviceGeoHistory = (rawDeviceGeo: RawDeviceGeoHistory): DeviceGeoHistory => ({
	path: mapRawDeviceSegmentsToPath(rawDeviceGeo.segments),
	points: mapRawDeviceHistoryPoints(rawDeviceGeo.points),
})

const mapRawDeviceSegmentsToPath = (
	rawLocationSegments: RawLocationHistorySegment[],
): LocationHistoryData[] => {
	const path: LocationHistoryData[] = []
	rawLocationSegments.forEach((s) => {
		s.path.forEach((p) => {
			path.push({
				lat: p.lat,
				lng: p.lon,
				location_ts: p.end_ts,
			})
		})
	})

	return path
}

const mapRawDeviceHistoryPoints = (
	rawLocationPoints: RawLocationHistoryPoint[],
): LocationHistoryPointData[] =>
	rawLocationPoints.map((p) => ({
		end_ts: p.end_ts,
		formatted_address: p.formatted_address,
		lat: p.lat,
		lng: p.lon,
		location_ts: p.end_ts || p.start_ts,
		place: p.place,
		start_ts: p.start_ts,
	}))

///// Maps Location Address to LatLng map
export const mapRawLocationLookup = (rawLookupData: LocationLookupRes): LocationAddressMap =>
	rawLookupData.result.reduce((map: Writable<LocationAddressMap>, curr: AddressLocationLookup) => {
		map[`${curr.lat},${curr.lon}`] = curr.formatted_address

		return map
	}, {})
