import { lazy } from 'react'

export const PromoView = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'promo' */
			/* webpackPrefetch: -1 */
			'./PromoView'
		),
)
