import { useFn, useWindowEvent } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import type { CssGlyphName } from '@op/icons'
import { useHandleSynEvent } from '@op/react-web'
import type { PaidTier, PremTier } from '@op/services'
import { PREM_NAME, PREM_PLUS_NAME } from '@op/services'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { MouseEventHandler, Ref } from 'react'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useIsScreenLG } from '../../hooks'
import { Glyph } from '../../widgets/Glyph'
import { Btn } from '../Buttons'
import { ChooseSubSelectBox } from './ChooseSubSelectBox'
import { ChooseSubSelectCheck } from './ChooseSubSelectCheck'

type SelectTiersProps = {
	// Some features are Prem+ only and clicking to upgrade doesn't give options
	// for Premium, b/c it doesn't make sense.
	readonly hidePrem: boolean
	readonly selected: PremTier
	onSelect(tier: PremTier): void
}

// Allow scrolling to a tier imperatively
export type SelectTierInstance = {
	isTierVisible(tier: PremTier): boolean
	scrollTo(tier: PaidTier): void
}

export const SelectTier = forwardRef(
	({ hidePrem, selected, onSelect }: SelectTiersProps, ref: Ref<SelectTierInstance>) => {
		const [t] = useT()
		const scrollElRef$ = useRef<HTMLDivElement>(null!)
		const isPrem = selected === 'premium'
		const isPremPlus = selected === 'premium_plus'
		const [containerWidth, setContainerWidth] = useState(0)

		const scrollTo = useFn((tier: PaidTier) => {
			const scrollView = scrollElRef$.current

			if (!scrollView) return

			scrollView.scrollTo({ left: tier === 'premium' ? containerWidth : 0, behavior: 'smooth' })
		})

		const handleSelect = useFn((tier: PremTier) => {
			scrollTo(tier)
			onSelect(tier)
		})

		// TODO: To be implemented when see more modal/page are implemented
		const handleSeeMoreClick = useHandleSynEvent(noop, true, true)
		const measureScrollContainer = useFn(() => {
			if (!scrollElRef$.current) return

			const bounds = scrollElRef$.current.getBoundingClientRect()
			setContainerWidth(bounds.width)
		})

		useImperativeHandle(ref, () => ({
			isTierVisible(tier: PremTier) {
				const width = scrollElRef$.current.getBoundingClientRect().width
				const scrollWidth = scrollElRef$.current.scrollWidth
				// If scroll
				if (tier === 'premium' && scrollWidth === width) {
					return true
				} else if (tier === 'premium_plus' && scrollWidth === 0) {
					return true
				}

				return false
			},
			scrollTo,
		}))

		useWindowEvent('resize', measureScrollContainer)

		// Figure out the width of the container and scroll to selected tier
		useEffect(() => {
			measureScrollContainer()
			scrollTo(selected)
		}, [])

		return (
			<div
				ref={scrollElRef$}
				className={cls(
					'flex gap-x-8 lg:gap-x-16 overflow-x-scroll pl-3 sm:px-4 lg:px-0 lg:overflow-visible',
					hidePrem ? 'justify-center' : 'lg:justify-around',
				)}
			>
				<ChooseSubSelectBox
					className="p-3 pb-2"
					selected={isPremPlus}
					onSelect={() => handleSelect('premium_plus')}
				>
					<div className="flex items-center justify-between mb-2">
						<ChooseSubSelectCheck
							className={cls(hidePrem && 'invisible')}
							selected={hidePrem ? false : isPremPlus}
						/>

						<p className="font-bold uppercase text-sm">{t('subscription_features.most_popular')}</p>

						<ChooseSubSelectCheck className="invisible" selected={false} />
					</div>

					<TierBanner selected={isPremPlus} text={PREM_PLUS_NAME} />

					<TierFeature
						color="text-purple"
						icon="vew-now"
						subtitle={t('subscription_features.monitor_activity_desc')}
						title={t('subscription_features.monitor_activity')}
					/>

					<TierFeature
						color="text-blue-dk"
						icon="places"
						subtitle={t('subscription_features.locator_prem_plus_desc')}
						title={t('subscription_features.family_locator')}
					/>

					<TierFeature
						color="text-aqua"
						icon="clock"
						subtitle={t('subscription_features.screentime_desc')}
						title={t('subscription_features.screentime_management')}
					/>

					<TierFeature
						color="text-green-dk"
						icon="add-user"
						subtitle={t('subscription_features.supervision_desc')}
						title={t('subscription_features.supervision')}
					/>

					<SeeMoreBtn onClick={handleSeeMoreClick} />
				</ChooseSubSelectBox>

				{!hidePrem && (
					<ChooseSubSelectBox
						onSelect={() => handleSelect('premium')}
						selected={isPrem}
						className="p-3 self-center"
					>
						<ChooseSubSelectCheck selected={isPrem} />

						<TierBanner selected={isPrem} text={PREM_NAME} />

						<TierFeature
							color="text-blue-dk"
							icon="places"
							subtitle={t('subscription_features.locator_prem_desc')}
							title={t('subscription_features.family_locator')}
						/>

						<TierFeature
							color="text-aqua"
							icon="clock"
							subtitle={t('subscription_features.screentime_desc')}
							title={t('subscription_features.screentime_management')}
						/>

						<TierFeature
							color="text-green-dk"
							icon="add-user"
							subtitle={t('subscription_features.supervision_desc')}
							title={t('subscription_features.supervision')}
						/>

						<SeeMoreBtn onClick={handleSeeMoreClick} />
					</ChooseSubSelectBox>
				)}
			</div>
		)
	},
)

SelectTier.displayName = 'SelectTier'

type SeeMoreBtnProps = {
	onClick: MouseEventHandler<HTMLButtonElement>
}

const SeeMoreBtn = ({ onClick }: SeeMoreBtnProps) => {
	const [t] = useT()

	return (
		<Btn
			className="!normal-case mx-auto !min-h-4 !p-1 sm:!p-2 md:!p-4"
			invert
			onClick={onClick}
			size="sm"
		>
			{t('actions.see_more')}
		</Btn>
	)
}

type TierBannerProps = {
	readonly selected: boolean
	readonly text: string
}

const TierBanner = ({ selected, text }: TierBannerProps) => (
	<h2
		className={cls('flex-center mb-3 md:mb-4 -mx-6 my-2 p-3 md:p-4', selected && 'bg-teal')}
		style={selected ? undefined : { backgroundColor: '#D2E5E8' }}
	>
		<p className="font-bold text-white text-xl md:text-2xl uppercase">{text}</p>
	</h2>
)

type TierFeatureProps = {
	readonly color: string
	readonly icon: CssGlyphName
	readonly subtitle: string
	readonly title: string
}

const TierFeature = ({ color, icon, subtitle, title }: TierFeatureProps) => {
	const isLgScreen = useIsScreenLG()

	return (
		<div className="flex mb-2 lg:px-6 lg:mb-3">
			<div className="flex-center mr-2 lg:mr-5">
				<Glyph className={color} name={icon} size={isLgScreen ? 'lg' : 'md'} />
			</div>

			<div className="flex-shrink">
				<p className="font-bold uppercase text-xs sm:text-sm md:text-base">{title}</p>
				<p className="leading-tight text-xs md:text-sm">{subtitle}</p>
			</div>
		</div>
	)
}
