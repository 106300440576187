import { microtask } from '@eturi/util'
import { generateKeyId as generateKeyIdSync, keyPairFromPrivateKeyPem } from '@op/crypto'
import { registerCryptoWorker } from '@op/crypto-worker'
import type {
	AccountAuthArgs,
	AccountGenArgs,
	DecryptPrivateKeyFn,
	DecryptSampleImgFn,
	DecryptTempKeysFn,
	EncryptPrivateKeyFn,
	GenerateKeyIdFn,
	GenerateKeyPairFn,
	GeneratePasswordHashFn,
	GetKeyIdFn,
} from '@op/services'

export const decryptSampleImg: DecryptSampleImgFn = async (
	encryptedBuffer,
	decryptedKey,
	b64IV,
) => {
	const decryptedBuffer = await registerCryptoWorker('decrypt_buf', {
		b64IV,
		decryptedKey,
		encryptedBuffer,
	})

	return URL.createObjectURL(new Blob([decryptedBuffer], { type: 'image/jpeg' }))
}

export const decryptTempKeys: DecryptTempKeysFn = (encryptedKeys, privateKeyPem) =>
	registerCryptoWorker('decrypt_tmp', {
		encryptedKeys,
		privateKeyPem,
	})

export const decryptPrivateKey: DecryptPrivateKeyFn = (encryptedPrivateKeyPem, password) =>
	registerCryptoWorker('decrypt_pk', {
		encryptedPrivateKeyPem,
		password,
	})

export const encryptPrivateKey: EncryptPrivateKeyFn = (privateKeyPem, password) =>
	registerCryptoWorker('enc_pk', {
		privateKeyPem,
		password,
	})

export const generateKeyId: GenerateKeyIdFn = (publicKeyPem) =>
	microtask(() => generateKeyIdSync(publicKeyPem))

export const getKeyId: GetKeyIdFn = (() => {
	const publicKeyCacheMap = new Map<string, Promise<string>>()
	const nullPromise = Promise.resolve(null)

	return (publicKeyPem: Maybe<string>) => {
		if (!publicKeyPem) return nullPromise

		let keyIdPromise = publicKeyCacheMap.get(publicKeyPem)

		publicKeyCacheMap.set(publicKeyPem, (keyIdPromise ||= generateKeyId(publicKeyPem)))

		return keyIdPromise
	}
})()

export const generateKeyPair: GenerateKeyPairFn = async () => {
	const privateKeyPem = await registerCryptoWorker('gen_pk')
	const keyPair = keyPairFromPrivateKeyPem(privateKeyPem)

	if (!keyPair) {
		throw new Error(`Failed to generate key pair`)
	}

	return keyPair
}

export const generatePasswordHash: GeneratePasswordHashFn = (password, salt) =>
	registerCryptoWorker('gen_pw_hash', { password, salt })

export const accountGenArgs = (): AccountGenArgs => ({
	decryptPrivateKey,
	encryptPrivateKey,
	generateKeyId,
	generateKeyPair,
	generatePasswordHash,
})

export const accountAuthArgs = (): AccountAuthArgs => ({
	decryptPrivateKey,
	generatePasswordHash,
})
