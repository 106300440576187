import { createContext } from 'react'

export type OverlayCtx = {
	hide(): void
	show(): void
	toggle(visible?: boolean): void
}

export const OverlayCtx = createContext<OverlayCtx>(null as any)
export const OverlayVisCtx = createContext<boolean>(false)
