import { sentryError } from '@eturi/sentry'
import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'
import { Redirect } from 'react-router-dom'

type LoadErrorBoundaryState = {
	readonly hasError: boolean
}

export class LoadErrorBoundary extends Component<
	{ readonly children?: ReactNode },
	LoadErrorBoundaryState
> {
	state: LoadErrorBoundaryState = { hasError: false }

	static getDerivedStateFromError(): LoadErrorBoundaryState {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		sentryError(error, { ErrBoundaryStack: errorInfo.componentStack.trim() })
	}

	render() {
		return this.state.hasError ? <Redirect to="/error" /> : this.props.children
	}
}
