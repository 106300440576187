import { useKeyboardClick } from '@op/react-web'
import cls from 'classnames'
import type { ReactNode } from 'react'

type ChooseSubSelectBoxProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly selected: boolean
	onSelect(): void
}

export const ChooseSubSelectBox = ({
	children,
	className,
	selected,
	onSelect,
}: ChooseSubSelectBoxProps) => {
	const handleClick = useKeyboardClick(() => {
		if (selected) return

		onSelect()
	})

	return (
		<div
			{...handleClick}
			className={cls(
				'border-2 cursor-pointer flex-1 rounded-3xl min-w-[84%] sm:min-w-[300px] md:max-w-md lg:min-w-[420px] xl:max-w-xl',
				selected && 'border-teal',
				className,
			)}
		>
			{children}
		</div>
	)
}
