import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { logoutAction } from '../thunks/logout'
import { useAppDispatch } from '../types'
import { isAndroidParent } from '../util'

export const LogoutPage = () => {
	const d = useAppDispatch()
	const history = useHistory()

	// On load we run the logout action then re-route to login page
	useEffect(() => {
		d(logoutAction())

		if (isAndroidParent && history.length > 1) {
			// When logging out of the android parent we want to clear our history so
			// the back button functionality isn't influenced by the records we had
			// prior to logout
			history.go(-(history.length - 1))
		}
	}, [])

	return null
}
