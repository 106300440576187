import { useConstant, useStateGetter } from '@eturi/react'
import type { ReactNode } from 'react'
import { OverlayCtx, OverlayVisCtx } from './OverlayContext'

export const OverlayProvider = ({ children }: { readonly children?: ReactNode }) => {
	const [isVisible, setVisible] = useStateGetter(false)

	const ctx = useConstant(
		(): OverlayCtx => ({
			hide() {
				setVisible(false)
			},

			show() {
				setVisible(true)
			},

			toggle(visible?: boolean) {
				setVisible(visible ?? !isVisible())
			},
		}),
	)

	return (
		<OverlayCtx.Provider value={ctx}>
			<OverlayVisCtx.Provider value={isVisible()}>{children}</OverlayVisCtx.Provider>
		</OverlayCtx.Provider>
	)
}
