import { createContext } from 'react'

export type ScrollListener = (scrollTop: number, offsetMod: number) => void
export type StickyScrollListener = (isSticky: boolean) => void

export type StickyState = {
	// Getter for the base offset of the sticky element from its offsetParent.
	// Note that this may not be the scrollElement. If it isn't we'll need to use
	// a different algorithm to figure out the offsetTop relative to the
	// scrollElement. This would be a pretty simple algorithm, though.
	baseOffsetTop(): number

	// Getter measuring the offsetHeight of the sticky element so we can create
	// a placeholder that matches the size
	height(): number

	// Method that triggers the Sticky to recalculate its height and offsetTop.
	// Used when the viewport changes size.
	refresh(scrollElement?: HTMLElement): void

	// Sets the sticky state on the child
	setSticky(isSticky: boolean): void

	// Sets the top position on the child
	setTop(top: number): void
}

export type StickyCtx = {
	// Add a scroll listener from a child of stickies
	addScrollListener(listener: ScrollListener): void

	// Adds a new sticky state and returns an id for that state which is used
	// to later remove the state.
	addSticky(state: StickyState): string

	// Remove a scroll listener from a child of stickies
	removeScrollListener(listener: ScrollListener): void

	// Removes the sticky based on its id
	removeSticky(id: string): void
}

export const StickyContext = createContext<StickyCtx>(null as any)
