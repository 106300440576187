import { duration } from '@eturi/date-util'
import { assertNotNullish, omit, pick, setIfNotEqual } from '@eturi/util'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import map from 'lodash/map'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	InitState,
	RawVewInfoWordList,
	SThunkState,
	VewConfigUpdate,
	VewConfigUserRes,
	VewDeviceConfig,
	VewInfo,
	VewRequestBody,
	VewUserConfig,
} from '../types'
import type { WithAccessState } from './access.slice'
import { isVewEnabled$ } from './access.slice'

export type VewDeviceConfigMap = {
	readonly [deviceId: string]: Maybe<VewDeviceConfig>
}

export type VewUserConfigMap = {
	readonly [userId: string]: Maybe<VewUserConfig>
}

export type VewConfigState = InitState & {
	readonly deviceConfigMap: VewDeviceConfigMap
	readonly userConfigMap: VewUserConfigMap
	readonly vewAccountInfo: Maybe<VewInfo>
	readonly wordList: Maybe<RawVewInfoWordList>
}

export type WithVewConfigState = {
	readonly vewConfig: VewConfigState
}

const initialState: VewConfigState = {
	deviceConfigMap: {},
	isInit: false,
	userConfigMap: {},
	vewAccountInfo: null,
	wordList: null,
}

export const vewConfigSlice = /*@__PURE__*/ createSlice({
	name: 'vewConfig',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchVewInfo.fulfilled, (s, { payload: [vewInfo, wordList] }) => {
				s.isInit = true
				setIfNotEqual(s, 'vewAccountInfo', vewInfo)
				setIfNotEqual(s, 'wordList', wordList)
			})
			.addMatcher(
				isAnyOf(fetchVewUserConfig.fulfilled, updateVewUserConfig.fulfilled),
				(s, { payload: vewUserConfig }) => {
					setIfNotEqual(s.userConfigMap, vewUserConfig.user_id, vewUserConfig)
				},
			),
})

export const vewConfigSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	vewConfigSlice,
	(s) => ({
		// NOTE: Currently not using device config
		// deviceConfigMap: map(s.deviceConfigMap, (vdc: VewDeviceConfig) =>
		//   vdc
		//     ? ({...vdc, ocr: vdc.ocr && omit(vdc.ocr, ['categories'])})
		//     : null,
		// ),
		isInit: s.isInit,
		userConfigMap: map(s.userConfigMap, (ucm) =>
			ucm ? { ...ucm, ocr: ucm.ocr && omit(ucm.ocr, ['categories']) } : null,
		),
	}),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type VewConfigThunkState = SThunkState & WithAccessState & WithVewConfigState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<VewConfigThunkState>()

const VEW_CONFIG_UPDATE_DEBOUNCE = /*@__PURE__*/ duration(3, 's')

// Ghetto debounce so we don't fetch Vew config just after we've updated
// ourselves locally. There's certainly a much smarter way of doing this that
// would allow us to determine if an update through Ably came from our client.
// TODO: Talk to John / Dr M to see if we can get some identifier passed from
//  Ably to we can filter "own" updates from "other" updates.
const shouldDebounceConfigFactory = (
	debounceTime: number,
): [
	shouldDebounceConfig: (id: string) => boolean,
	setConfigId: (id: string) => void,
	setConfigTs: (ts?: number) => void,
] => {
	let lastConfigId = ''
	let lastConfigTs = -1

	const shouldDebounceConfig = (id: string) => {
		let now = -1

		if (lastConfigId === id && (now = Date.now()) - lastConfigTs < debounceTime) return true

		lastConfigId = id
		lastConfigTs = now

		return false
	}
	const setConfigId = (id: string) => {
		lastConfigId = id
	}
	const setConfigTs = (ts: number = Date.now()) => {
		lastConfigTs = ts
	}

	return [shouldDebounceConfig, setConfigId, setConfigTs]
}

const [shouldDebounceUserConfig, setLastUserConfigId, setLastUserConfigTs] =
	/*@__PURE__*/ shouldDebounceConfigFactory(VEW_CONFIG_UPDATE_DEBOUNCE)

export const fetchVewInfo = /*@__PURE__*/ createAsyncThunk(
	'vewConfig/info/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const data: VewRequestBody = { request_type: 'get_vew_info' }

		const vewInfo = await dispatch(http.post<Maybe<VewInfo>>('/vew_request', { ...extra, data }))

		assertNotNullish(vewInfo, 'VewInfo')

		const wordListRes = await fetch(vewInfo.word_file.get_url, { method: 'GET' })
		const wordList: Maybe<RawVewInfoWordList> = await wordListRes.json()

		assertNotNullish(wordList, 'RawVewInfoWordList')

		return [vewInfo, wordList] as const
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isVewConfigInit$(api.getState())) return false
		},
	},
)

type FetchVewUserConfigArgs = HttpExtra & {
	readonly userId: string
}

// TODO: It would be nice if we could retrieve a partial of the config, just to
//  get whether Vew is enabled for the user. Otherwise, we have to poll on
//  everything, now that we need this value from
export const fetchVewUserConfig = /*@__PURE__*/ createAsyncThunk(
	'vewConfig/userConfig/fetch',
	async ({ userId, ...extra }: FetchVewUserConfigArgs, { dispatch, extra: { http } }) => {
		const data: VewRequestBody = {
			request_type: 'get_user_config',
			user_id: userId,
		}

		const res = await dispatch(
			http.post<Maybe<VewConfigUserRes>>('/vew_request', { ...extra, data }),
		)

		const userConfig = res?.user_config

		assertNotNullish(userConfig, 'VewConfigUserRes')

		return userConfig
	},

	{
		condition: ({ force, userId }, api) => {
			const state = api.getState()

			// NOTE: This call fails for accounts without Vew access
			if (
				!isVewEnabled$(state) ||
				shouldDebounceUserConfig(userId) ||
				(!force && vewUserConfigMap$(state)[userId])
			)
				return false
		},
	},
)

type UpdateVewUserConfigArg = {
	readonly accountDeviceId: string
	readonly update: VewConfigUpdate
}

export const updateVewUserConfig = /*@__PURE__*/ createAsyncThunk(
	'vewConfig/update/user',
	async (
		{ accountDeviceId, update }: UpdateVewUserConfigArg,
		{ dispatch, getState, extra: { http } },
	) => {
		const { user_id } = update
		const currentUserConfig = vewUserConfigMap$(getState())[user_id]

		assertNotNullish(currentUserConfig, 'current VewUserConfig')

		const VEW_CONFIG_UPDATE_KEYS = ['args', 'automated', 'ocr', 'user_enabled'] as const

		const data: VewRequestBody = {
			request_args: {
				requested_by: accountDeviceId,
				config_updates: pick({ ...currentUserConfig, ...update }, VEW_CONFIG_UPDATE_KEYS),
			},
			request_type: 'update_user_config',
			user_id,
		}

		const res = await dispatch(http.post<Maybe<VewConfigUserRes>>('/vew_request', { data }))

		const userConfig = res?.user_config

		assertNotNullish(userConfig, 'VewUserConfig')

		setLastUserConfigId(user_id)
		setLastUserConfigTs()

		return userConfig
	},
)

// const [shouldDebounceDeviceConfig, setLastDeviceConfigId, setLastDeviceConfigTs] =
// 	shouldDebounceConfigFactory(VEW_CONFIG_UPDATE_DEBOUNCE)
//
// type FetchVewDeviceConfigArgs = HttpExtra & {
// 	readonly deviceId: string
// }
//
// export const fetchVewDeviceConfig = /*@__PURE__*/ createAsyncThunk(
// 	'vewConfig/fetch/device',
// 	async ({ deviceId, ...extra }: FetchVewDeviceConfigArgs, { dispatch, extra: { http } }) => {
// 		const data: VewReqBody = {
// 			device_id: deviceId,
// 			request_type: VewReqType.GET_DEVICE_CONFIG,
// 		}
//
// 		const res = await dispatch(
// 			http.post<Maybe<VewDeviceConfigRes>>('/vew_request', { ...extra, data }),
// 		)
//
// 		const deviceConfig = res?.device_config
//
// 		assertNotNullish(deviceConfig, 'VewDeviceConfig')
//
// 		return deviceConfig
// 	},
//
// 	{
// 		condition: ({ deviceId, force }, api) => {
// 			if (
// 				shouldDebounceDeviceConfig(deviceId) ||
// 				(!force && vewDeviceConfigMap$(api.getState())[deviceId])
// 			)
// 				return false
// 		},
// 	},
// )
// type UpdateVewDeviceConfigArg = {
// 	readonly accountDeviceId: string
// 	readonly update: VewDeviceConfigUpdate
// }
//
// export const updateVewDeviceConfig = /*@__PURE__*/ createAsyncThunk(
// 	'vewConfig/update/device',
// 	async (
// 		{ accountDeviceId, update }: UpdateVewDeviceConfigArg,
// 		{ dispatch, getState, extra: { http } },
// 	) => {
// 		const { automated, device_id } = update
// 		const state = getState()
// 		const currentDeviceConfig = vewDeviceConfigMap$(state)[device_id]
//
// 		const data: UpdateVewDeviceConfigBody = {
// 			device_id,
// 			request_type: VewReqType.UPDATE_DEVICE_CONFIG,
// 			request_args: {
// 				requested_by: accountDeviceId,
// 				config_updates: {
// 					automated: {
// 						...currentDeviceConfig?.automated,
// 						...automated,
// 					},
// 				},
// 			},
// 		}
//
// 		setLastDeviceConfigId(device_id)
// 		setLastDeviceConfigTs()
//
// 		const res = await dispatch(http.post<Maybe<VewDeviceConfigRes>>('/vew_request', { data }))
//
// 		const deviceConfig = res?.device_config
//
// 		assertNotNullish(deviceConfig, 'VewDeviceConfig')
//
// 		return deviceConfig
// 	},
// )

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithVewConfigState>(s: T) => s.vewConfig

export const isVewConfigInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const rawWordList$ = /*@__PURE__*/ createSelector(state$, (s) => s.wordList)
export const vewAccountInfo$ = /*@__PURE__*/ createSelector(state$, (s) => s.vewAccountInfo)
export const vewDeviceConfigMap$ = /*@__PURE__*/ createSelector(state$, (s) => s.deviceConfigMap)
export const vewUserConfigMap$ = /*@__PURE__*/ createSelector(state$, (s) => s.userConfigMap)
