import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import { keysToBool, size } from '@op/util'
import type { Draft } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import forOwn from 'lodash/forOwn'
import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	DeviceApp,
	GranDirectives,
	GranType,
	IdPayloadAction,
	InitState,
	NormalizedRawGran,
	OmnibusKey,
	RawGran,
	RawOmnibusDirectives,
	SThunkState,
} from '../types'
import {
	createIdPayloadPrepare,
	DEFAULT_OMNIBUS,
	isDefaultOmnibusType,
	mapGranDirectivesToServerPut,
	mapRawToServerPutOmnibus,
	normalizeRawGran,
	pickIdPayload,
	SpecialApp,
} from '../types'

export type UserGranState = InitState & {
	readonly granularity: NormalizedRawGran
}

export const createUserGranState = (): UserGranState => ({
	granularity: /*@__PURE__*/ normalizeRawGran({}),
	isInit: false,
})

export type GranState = {
	readonly [userId: string]: UserGranState
}

export type WithGranState = {
	readonly granularity: GranState
}

const initialState: GranState = {}

const ensureUserState = (s: Draft<GranState>, userId: string) =>
	castDraft((s[userId] ||= createUserGranState()))

export const granularitySlice = /*@__PURE__*/ createSlice({
	name: 'granularity',
	initialState,
	reducers: {
		updateAppDirectives: {
			prepare: createIdPayloadPrepare<GranDirectives>(),
			reducer(s, a: IdPayloadAction<GranDirectives>) {
				const [id, updatedAppDirectives] = pickIdPayload(a)
				const appDirectives = ensureUserState(s, id).granularity.app_directives

				// Delete any directives that are default 'S', otherwise, update value
				forOwn(updatedAppDirectives, (updatedDirective, bundleId) => {
					if (updatedDirective === 'S') {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete appDirectives[bundleId]
					} else {
						appDirectives[bundleId] = updatedDirective
					}
				})
			},
		},

		updateOmnibus: {
			prepare: createIdPayloadPrepare<RawOmnibusDirectives>(),
			reducer(s, a: IdPayloadAction<RawOmnibusDirectives>) {
				const [id, updatedOmnibus] = pickIdPayload(a)
				const omnibus = ensureUserState(s, id).granularity.omnibus_directives

				// Delete any default omnibus directives, otherwise, update value
				forOwn(updatedOmnibus, (updatedGranType: GranType, directiveName: OmnibusKey) => {
					if (isDefaultOmnibusType(directiveName, updatedGranType)) {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete omnibus[directiveName]
					} else {
						;(omnibus as any)[directiveName] = updatedGranType
					}
				})
			},
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchUserGran.fulfilled, (s, { meta, payload: granularity }) => {
				setIfNotEqual(s, meta.arg.userId, { granularity, isInit: true })
			}),
})

export const { updateAppDirectives, updateOmnibus } = granularitySlice.actions

const isGranAction = /*@__PURE__*/ isAnyOf(updateAppDirectives, updateOmnibus)

export const granularitySliceTransformer = /*@__PURE__*/ createSliceTransformer(
	granularitySlice,
	(s) =>
		mapValues(s, ({ isInit, granularity }) => ({
			isInit,
			granularity: {
				app_directives: size(granularity.app_directives),
				omnibus_directives: {
					...granularity.omnibus_directives,
					...keysToBool(granularity.omnibus_directives, [
						'webContentFilterBlacklist',
						'webContentFilterWhitelist',
					]),
				},
			},
		})),
	(a) => (isGranAction(a) ? null : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type GranThunkState = SThunkState & WithGranState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<GranThunkState>()

type FetchUserGranArg = HttpExtra & {
	readonly userId: string
}

export const fetchUserGran = /*@__PURE__*/ createAsyncThunk(
	'gran/user/fetch',
	async ({ userId, ...extra }: FetchUserGranArg, { dispatch, extra: { http } }) => {
		const rawGran = await dispatch(
			http.get<Maybe<RawGran>>(`/granularity_by_user?user_id=${userId}`, extra),
		)

		assertNotNullish(rawGran, 'RawGran')

		return normalizeRawGran(rawGran)
	},
	{
		condition: (arg, api) => {
			if (!arg.force && granularity$(api.getState())[arg.userId]?.isInit) return false
		},
	},
)

type UpdateUserAppDirectiveArg = {
	readonly app: DeviceApp
	readonly userId: string
}

// FIXME: try / catch client impl
export const updateUserAppDirective = /*@__PURE__*/ createAsyncThunk(
	'gran/user/appDirective/update',
	async ({ app, userId }: UpdateUserAppDirectiveArg, { dispatch, getState, extra: { http } }) => {
		const { bundleId, granularity } = app

		// Pull apart combo app
		const directives =
			bundleId === SpecialApp.APPLE_COMBO
				? { [SpecialApp.APPLE_CAMERA]: granularity, [SpecialApp.APPLE_FACETIME]: granularity }
				: { [bundleId]: granularity }

		const rawAppDirectives = granularity$(getState())[userId]?.granularity.app_directives || {}

		// Create a state holding the current values of the directives being updated
		// for the purpose of rollback on fail. For anything not found, we set to
		// schedule by default, to make sure we overwrite everything.
		const rollbackDirectives = reduce(
			directives,
			(dirs: Writable<GranDirectives>, _, bundleId) => {
				dirs[bundleId] = rawAppDirectives[bundleId] || 'S'
				return dirs
			},
			{},
		)

		dispatch(updateAppDirectives(userId, directives))

		try {
			await dispatch(
				http.put('/granularity_by_user', {
					data: [mapGranDirectivesToServerPut(directives), {}, userId],
				}),
			)
		} catch (e) {
			dispatch(updateAppDirectives(userId, rollbackDirectives))
			throw e
		}
	},
)

type UpdateUserOmnibusArg = {
	readonly omnibus: RawOmnibusDirectives
	readonly userId: string
}

// FIXME: try / catch client impl
export const updateUserOmnibus = /*@__PURE__*/ createAsyncThunk(
	'gran/user/omnibus/update',
	async ({ omnibus, userId }: UpdateUserOmnibusArg, { dispatch, getState, extra: { http } }) => {
		const currentOmnibus = granularity$(getState())[userId]?.granularity.omnibus_directives || {}

		// We get the current value, so we can roll back, if needed. We don't get
		// the raw value b/c we need to make sure the store will overwrite the
		// previous value, and this may be undefined.
		const rollbackOmnibus = reduce(
			omnibus,
			(dirs: RawOmnibusDirectives, _, key: OmnibusKey) => {
				;(dirs as any)[key] = currentOmnibus[key] || DEFAULT_OMNIBUS[key]
				return dirs
			},
			{},
		)

		// Update optimistically
		dispatch(updateOmnibus(userId, omnibus))

		try {
			await dispatch(
				http.put('/granularity_by_user', {
					data: [{}, mapRawToServerPutOmnibus(omnibus), userId],
				}),
			)
		} catch (e) {
			dispatch(updateOmnibus(userId, rollbackOmnibus))
			throw e
		}
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

export const granularity$ = <T extends WithGranState>(s: T) => s.granularity
