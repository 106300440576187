import { duration } from '@eturi/date-util'

const REQ_TIMEOUT = /*@__PURE__*/ duration(30, 's')
const REQ_WATCH_INTERVAL = /*@__PURE__*/ duration(5, 's')

type GetReqId<T, E = void> = (entity: T, extraArg: E) => string

type ThunkReqState<T, R> = {
	readonly entity: T
	readonly id: string
	readonly req?: Promise<R>
	readonly ts: number
}

/**
 * FIXME: This algorithm is very inefficient with memory. It would be much,
 *  much better if, when an entity state doesn't exist, we create a single
 *  state that all filtered entities map to. We can still set this shared
 *  object for each entity id. We'd have to change the type of ThunkReqState
 *  and refactor a bunch of stuff. We probably want to make state.req mutable
 *  as well. Something like:
 *
 *  ```ts
 *  let newState: ThunkReqState
 *  const id = getId(entity, extraArg)
 *  const state = _idToStateMap.get(id) || (newState ||= {
 *    entities: new Set(),
 *    ids: new Set(),
 *    ts: Date.now()
 *  })
 *  state.entities.add(entity)
 *  state.ids.add(id)
 *  if (state.req) {
 *    cached.add(state.req)
 *  } else {
 *    _idToStateMap.set(id, state)
 *    filteredStates.add(state)
 *    filteredEntities.add(entity)
 *  }
 *
 *  try {
 *    const req = Promise.all([doReq(...filteredEntities), ...cached]).then(([r]) => r)
 *
 *    if (newState) {
 *      newState.req = req
 *    }
 *  }
 *  ```
 *  We can also consider short-circuiting if filtered are empty (or there's no
 *  "newState"), by just resolving cached. Probably not though, due to return
 *  types.
 */
export const thunkReqFactory = <T, R = void, E = void>(getId: GetReqId<T, E>) => {
	// Keep track of each id with its associated request promise and the
	// timestamp of when it was initiated (so we can clear it if it times out).
	const _idToStateMap = new Map<string, ThunkReqState<T, R>>()

	// Whether we're on an interval watching to see if requests have timed out.
	let _isWatching = false

	// The watch interval id, so we can clear watching when there are no more reqs
	let _intervalId = -1

	// Watch requests for timeout
	const _watch = () => {
		// There should only be one watch interval per manager instance.
		if (_isWatching) return

		_isWatching = true
		_intervalId = setInterval(() => {
			const now = Date.now()

			// For any req state that has timed out, delete it.
			_idToStateMap.forEach((s, id, m) => {
				if (now - s.ts > REQ_TIMEOUT) m.delete(id)
			})

			// If we no longer have any req states, stop watching.
			if (!_idToStateMap.size) {
				_isWatching = false
				clearInterval(_intervalId)
			}
		}, REQ_WATCH_INTERVAL)
	}

	const _getState = (entity: T, id: string): ThunkReqState<T, R> => {
		const state = _idToStateMap.get(id) || { entity, id, ts: Date.now() }

		_idToStateMap.set(id, state)

		return state
	}

	return async (
		entities: T[],
		doReq: (filtered: readonly T[]) => Promise<R>,
		extraArg: E,
	): Promise<R> => {
		// The previous requests associated with an id (if any).
		const cached = new Set<Promise<R>>()

		// List of entity states that don't currently have requests.
		const filteredStates = new Set<ThunkReqState<T, R>>()

		// A companion set of filtered entities. These will be passed to `doReq`.
		// These are the same as filteredState.entity
		const filteredEntities = new Set<T>()

		for (const entity of entities) {
			const state = _getState(entity, getId(entity, extraArg))

			if (state.req) {
				cached.add(state.req)
			} else {
				filteredStates.add(state)
				filteredEntities.add(state.entity)
			}
		}

		try {
			const req = Promise.all([doReq([...filteredEntities]), ...cached]).then(([r]) => r)

			// Add all filtered states
			filteredStates.forEach((s) => _idToStateMap.set(s.id, { ...s, req }))

			_watch()

			return await req
		} finally {
			// Clear states when complete
			filteredStates.forEach((s) => _idToStateMap.delete(s.id))
		}
	}
}
