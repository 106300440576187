import cls from 'classnames'
import { memo } from 'react'
import { ZOverlay } from '../../constants/z-index'
import { useAppOverlayVis } from './useOverlay'

type AppOverlayProps = {
	readonly className?: string
	readonly fixed?: boolean
	readonly zIndex?: number
}

const Overlay = ({ className, fixed = true, zIndex = ZOverlay }: AppOverlayProps) => {
	const isVisible = useAppOverlayVis()

	return (
		<div
			className={cls(
				'bg-overlay fill',
				!isVisible && 'hidden',
				fixed ? 'fixed' : 'absolute',
				className,
			)}
			style={{ zIndex }}
		/>
	)
}

export default /*@__PURE__*/ memo(Overlay)
