import { useConstant } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import ETURI_LOGO from '@op/assets/logos/eturi.svg'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { hasPairPageStyles$ } from '../../compound-selectors/app-misc'

export const Footer = memo(() => {
	const [t] = useT('terms')
	const year = useConstant(() => new Date().getFullYear())
	const isPair = useSelector(hasPairPageStyles$)

	const linkBorder = isPair ? 'border-white' : 'border-gray-67'

	return (
		<footer
			className={cls(
				'app-footer flex-center flex-col py-3 relative text-[10px] lg:text-xs',
				isPair && 'text-white',
			)}
		>
			<figure className="flex-center flex-col mb-2 w-32">
				<figcaption style={{ fontSize: '10px', marginBottom: '-4px' }}>
					{t('powered_by')}
				</figcaption>

				<img src={ETURI_LOGO} style={{ width: '76px' }} alt="Eturi Logo" />
			</figure>

			<ul className="flex mb-1">
				<FooterLink border={linkBorder} href="https://ourpact.com/privacy">
					{t('privacy_policy')}
				</FooterLink>

				<FooterLink border={linkBorder} href="https://ourpact.com/california-privacy">
					{t('childrens_privacy_policy')}
				</FooterLink>

				<FooterLink border={linkBorder} href="https://ourpact.com/terms">
					{t('terms_and_conditions')}
				</FooterLink>
			</ul>

			<p className="app-footer__copy mt-0.5">
				&copy; {year} {t('copyright')}
			</p>
		</footer>
	)
})

Footer.displayName = 'Footer'

type FooterLinkProps = {
	readonly border: string
	readonly children?: ReactNode
	readonly href: string
}

const FooterLink = ({ border, children, href }: FooterLinkProps) => (
	<li
		className={`app-footer__link border-r ${border} flex items-center px-2 text-center last-of-type:border-r-0`}
		style={{ lineHeight: 1 }}
	>
		<a className="hyphenate !text-inherit" href={href} target="_blank" rel="noreferrer">
			{children}
		</a>
	</li>
)
