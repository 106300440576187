import { durationAs } from '@eturi/date-util'
import { curry } from '@eturi/util'
import type { PurchasePlatform, SKUDef, SubCycle, TierDef } from '@op/services'
import {
	accountTz$,
	availableBenefit$,
	intToFloatPrice,
	isExtendedTrialBenefit,
	isStripeBenefitDetails,
	isStripeCouponBenefit,
	mapTierToFullName,
	mapTierToName,
	nextSKU$,
	nextSKUSource$,
	skuDefs$,
	stripeSKUDefs$,
	stripeSKUPrem$,
	toPriceStr,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import {
	hasSubscribingTier$,
	isUISelectedAnnual$,
	subscribingCycle$,
	subscribingTier$,
} from '../reducers/product.slice'
import { isAndroidParent } from '../util'

const _daysFromNow = (m: Maybe<Moment>) => (m ? Math.ceil(m.diff(Date.now(), 'days', true)) : 0)

export const subscribingPlatform$ = /*@__PURE__*/ createSelector(
	nextSKUSource$,
	subscribingTier$,
	// NOTE: This is a function for unit testing, since this value can be mocked
	() => isAndroidParent,
	(nextSource, subscribingTier, isAndroidParent): Maybe<PurchasePlatform> => {
		if (
			// Tier needs to be set, obviously
			!subscribingTier ||
			// Apple is not managed in app
			nextSource === 'apple' ||
			// User not on Android needs to manage through Google Play
			(!isAndroidParent && nextSource === 'google')
		)
			return null

		switch (nextSource) {
			case 'stripe':
			case 'google':
				return nextSource
		}

		return isAndroidParent ? 'google' : 'stripe'
	},
)

export const subscribingSKU$ = /*@__PURE__*/ createSelector(
	skuDefs$,
	subscribingPlatform$,
	subscribingTier$,
	subscribingCycle$,
	(defs, platform, tierDef, cycle): Maybe<SKUDef> => {
		if (!platform) return null

		return defs.find(
			(def) =>
				def.billing_cycle === cycle &&
				def.tier_def === tierDef &&
				def.purchase_platforms.includes(platform) &&
				def.purchasable_with.includes(platform),
		)
	},
)

// NOTE: We normalize this to a string b/c we set the tier for downgrade and
//  purchase states, so this should always exist there. If we need to use this
//  otherwise, we can do a boolean cast on it.
export const subscribingId$ = /*@__PURE__*/ createSelector(
	subscribingSKU$,
	(s): string => s?.sku || '',
)

export const offerExpiryMoment$ = /*@__PURE__*/ createSelector(
	accountTz$,
	availableBenefit$,
	(accountTz, availableBenefit): Maybe<Moment> => {
		// There's no offer to expire so return current moment
		if (!(isExtendedTrialBenefit(availableBenefit) || isStripeCouponBenefit(availableBenefit)))
			return moment()

		const {
			account: { applied_ts },
			details,
		} = availableBenefit
		let availableSecs

		// If we don't have a truthy applied_ts, and available_secs is not truthy,
		// we can't derive a meaningful expiry.
		if (!(applied_ts && (availableSecs = details.available_secs))) return

		// The amount of time elapsed since the benefit was applied
		const elapsedSecs = durationAs(Date.now() - applied_ts, 'ms', 's')
		const remainingSecs = availableSecs - elapsedSecs

		return moment.utc().add(remainingSecs, 'seconds').tz(accountTz)
	},
)

export const stripeCouponPercent$ = /*@__PURE__*/ createSelector(availableBenefit$, (benefits) => {
	const details = benefits?.details
	return isStripeBenefitDetails(details) ? details.stripe_percent_off || 0 : 0
})

const DEFAULT_TRIAL_DURATION = 14

// NOTE: It was agreed upon in #dev channel on 4/1 at 16:31 that the trial
//  duration can be derived from the Stripe Premium SKU because, for now, all
//  SKUs will have the same trial duration.
const trialDurationInDaysRaw$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	subscribingSKU$,
	stripeSKUPrem$,
	(benefit, subscribingSKU, stripeSKUPrem): number =>
		isExtendedTrialBenefit(benefit)
			? moment.duration(benefit.details.trial_secs, 'seconds').asDays()
			: subscribingSKU?.trial_days || stripeSKUPrem?.trial_days || DEFAULT_TRIAL_DURATION,
)

const trialExpiryMoment$ = /*@__PURE__*/ createSelector(
	accountTz$,
	trialDurationInDaysRaw$,
	(tz, trialDurationInDays) => moment.utc().add(trialDurationInDays, 'days').tz(tz),
)

const toCyclePriceStr = (def: SKUDef) => toPriceStr(intToFloatPrice(def.price_usd))
const toMonthlyPriceStr = (def: SKUDef) =>
	toPriceStr(intToFloatPrice(def.price_usd / (def.billing_cycle === 'annual' ? 12 : 1)))

export const isPromoTrialDuration$ = /*@__PURE__*/ createSelector(
	trialDurationInDaysRaw$,
	(t) => t > DEFAULT_TRIAL_DURATION,
)
export const offerExpiryInDays$ = /*@__PURE__*/ createSelector(offerExpiryMoment$, _daysFromNow)
export const trialDurationInDays$ = /*@__PURE__*/ createSelector(trialExpiryMoment$, _daysFromNow)
export const trialExpiryDate$ = /*@__PURE__*/ createSelector(trialExpiryMoment$, (m) =>
	m.format('L'),
)

// NOTE: hasSubscribingSKUTier is set when upgrading or downgrading, so having
//  this value means we are attempting to do one or the other
export const shouldHandleWithGoogleIAP$ = /*@__PURE__*/ createSelector(
	subscribingPlatform$,
	subscribingSKU$,
	hasSubscribingTier$,
	(subPlatform, subSKU, isModifyingSKU) =>
		Boolean(isModifyingSKU && subSKU && subPlatform === 'google'),
)

const _stripeSKUForTier = curry(
	(tierDef: TierDef, stripeSKUDefs: SKUDef[], isUISelectedAnnual: boolean) => {
		const billingCycle: SubCycle = isUISelectedAnnual ? 'annual' : 'monthly'

		return stripeSKUDefs.find((d) => d.tier_def === tierDef && d.billing_cycle === billingCycle)!
	},
)

const _stripeSKUPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	isUISelectedAnnual$,
	_stripeSKUForTier('premium'),
)
const _stripeSKUPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	isUISelectedAnnual$,
	_stripeSKUForTier('premium_plus'),
)

// The following selectors get the annual price regardless of ui state
const _stripeSKUAnnualPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => true,
	_stripeSKUForTier('premium'),
)
const _stripeSKUAnnualPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => true,
	_stripeSKUForTier('premium_plus'),
)

const _stripeSKUMonthPrem$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => false,
	_stripeSKUForTier('premium'),
)
const _stripeSKUMonthPremPlus$ = /*@__PURE__*/ createSelector(
	stripeSKUDefs$,
	() => false,
	_stripeSKUForTier('premium_plus'),
)

export const cyclePriceStrPremMonth$ = /*@__PURE__*/ createSelector(
	_stripeSKUMonthPrem$,
	toCyclePriceStr,
)
export const cyclePriceStrPremPlusMonth$ = /*@__PURE__*/ createSelector(
	_stripeSKUMonthPremPlus$,
	toCyclePriceStr,
)

export const cyclePriceStrPremAnnual$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPrem$,
	toCyclePriceStr,
)
export const cyclePriceStrPremPlusAnnual$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPremPlus$,
	toCyclePriceStr,
)
export const cyclePriceStrPremAnnualMonthly$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPrem$,
	toMonthlyPriceStr,
)
export const cyclePriceStrPremPlusAnnualMonthly$ = /*@__PURE__*/ createSelector(
	_stripeSKUAnnualPremPlus$,
	toMonthlyPriceStr,
)

export const cyclePriceStrPrem$ = /*@__PURE__*/ createSelector(_stripeSKUPrem$, toCyclePriceStr)
export const cyclePriceStrPremPlus$ = /*@__PURE__*/ createSelector(
	_stripeSKUPremPlus$,
	toCyclePriceStr,
)
export const monthlyPriceStrPrem$ = /*@__PURE__*/ createSelector(_stripeSKUPrem$, toMonthlyPriceStr)
export const monthlyPriceStrPremPlus$ = /*@__PURE__*/ createSelector(
	_stripeSKUPremPlus$,
	toMonthlyPriceStr,
)

export const isSubscribingStripe$ = /*@__PURE__*/ createSelector(
	subscribingPlatform$,
	(p) => p === 'stripe',
)
export const subscribingName$ = /*@__PURE__*/ createSelector(subscribingTier$, mapTierToName)
export const subscribingFullName$ = /*@__PURE__*/ createSelector(
	subscribingTier$,
	mapTierToFullName,
)

export const isChangingCycle$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	subscribingSKU$,
	(nS, sS) => nS.billing_cycle !== sS?.billing_cycle,
)

export const isChangingTier$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	subscribingSKU$,
	(nS, sS) => nS.tier_def !== sS?.tier_def,
)

export const isChangingCycleOnly$ = /*@__PURE__*/ createSelector(
	isChangingCycle$,
	isChangingTier$,
	(isChangingCycle, isChangingTier) => isChangingCycle && !isChangingTier,
)
