import values from 'lodash/values'
import v from './styles/exports.module.scss'

const toInt = (v: string): number => /*@__PURE__*/ Number.parseInt(v, 10)

export const AppMediaBrkPt = {
	XS: /*@__PURE__*/ toInt(v.xs),
	SM: /*@__PURE__*/ toInt(v.sm),
	MD: /*@__PURE__*/ toInt(v.md),
	LG: /*@__PURE__*/ toInt(v.lg),
	XL: /*@__PURE__*/ toInt(v.xl),
	XXL: /*@__PURE__*/ toInt(v.xxl),
}

// Reverse sort breakpoints, so we can match from highest to lowest
export const AppMediaBrkPts = /*@__PURE__*/ values(AppMediaBrkPt).sort((a, b) =>
	a > b ? -1 : b > a ? 1 : 0,
)

export const desktopMenuWidthMin = /*@__PURE__*/ toInt(v.desktopMenuWidthMin)
export const desktopMenuWidthLG = /*@__PURE__*/ toInt(v.desktopMenuWidthLG)
export const desktopMenuWidthXL = /*@__PURE__*/ toInt(v.desktopMenuWidthXL)

export const profileHeightSM = /*@__PURE__*/ toInt(v.profileHeightSM)
export const profileHeightLG = /*@__PURE__*/ toInt(v.profileHeightLG)
export const profileHeightXL = /*@__PURE__*/ toInt(v.profileHeightXL)
export const profileHeightXXL = /*@__PURE__*/ toInt(v.profileHeightXXL)

export const profileAvatarSM = /*@__PURE__*/ toInt(v.profileAvatarSM)
export const profileAvatarLG = /*@__PURE__*/ toInt(v.profileAvatarLG)
export const profileAvatarXL = /*@__PURE__*/ toInt(v.profileAvatarXL)
export const profileAvatarXXL = /*@__PURE__*/ toInt(v.profileAvatarXXL)

export const ctrlHeightSM = /*@__PURE__*/ toInt(v.ctrlHeightSM)
export const ctrlHeightMD = /*@__PURE__*/ toInt(v.ctrlHeightMD)
export const ctrlHeightLG = /*@__PURE__*/ toInt(v.ctrlHeightLG)

export const rulesHeight = /*@__PURE__*/ toInt(v.rulesHeight)
export const rulesHeightXL = /*@__PURE__*/ toInt(v.rulesHeightXL)
export const rulesHeightXXL = /*@__PURE__*/ toInt(v.rulesHeightXXL)
export const rulesBtnHeight = /*@__PURE__*/ toInt(v.rulesBtnHeight)
export const rulesBtnHeightXL = /*@__PURE__*/ toInt(v.rulesBtnHeightXL)

export const topNavSM = /*@__PURE__*/ toInt(v.topNavSM)
export const topNavLG = /*@__PURE__*/ toInt(v.topNavLG)
