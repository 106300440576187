/**
 * @fileOverview Main Application Entry Point
 */

import { Router } from 'react-router-dom'
import AppMain from './AppMain'
import AuthPollingEffects from './AuthPollingEffects'
import {
	AppScrollHelper,
	AuthRedirect,
	BrowserInfo,
	ChangeSub,
	DesktopNav,
	MobileNav,
	NavBar,
	NavProvider,
	ToastContainer,
	ToastManager,
} from './components'
import ConnectivityHandler from './components/ConnectivityHandler'
import CookieNotice from './components/CookieNotice'
import LocaleWatcher from './components/LocaleWatcher'
import NavBarOutlet from './components/Navigation/NavBarOutlet'
import { OverlayProvider } from './components/Overlay'
import RouteWatcher from './components/RouteWatcher'
import { AppHistory } from './history'

export const App = () => (
	<main className="app">
		<Router history={AppHistory}>
			<OverlayProvider>
				<AppScrollHelper>
					<NavProvider>
						<ToastManager>
							<ChangeSub>
								<NavBarOutlet />
								<DesktopNav />
								<MobileNav />
								<AppMain />
								<CookieNotice />
								<ToastContainer />

								<AuthPollingEffects />
								<AuthRedirect />
								<BrowserInfo />
								<ConnectivityHandler />
								<LocaleWatcher />
								<RouteWatcher />
							</ChangeSub>
						</ToastManager>
					</NavProvider>
				</AppScrollHelper>
			</OverlayProvider>
		</Router>
	</main>
)
