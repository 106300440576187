import type { Lang } from '@op/services'

const LAST_LANG_KEY = '__OURPACT_LAST_LANG__'

/**
 * Because persistenceEnhancer rehydrates async, we need to store the last user
 * Lang so that we can initialize i18n LangDetector, which happens
 * synchronously. Otherwise, we'll have to re-render the app every time the user
 * reloads the page.
 */
export const LastLang = (() => {
	let lastLang: Lang

	return {
		get() {
			return (lastLang ||= (sessionStorage.getItem(LAST_LANG_KEY) as Maybe<Lang>) || 'en')
		},

		set(lang: Lang) {
			lastLang = lang
			sessionStorage.setItem(LAST_LANG_KEY, lang)
		},
	}
})()
