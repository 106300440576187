import { utcDate } from '@eturi/date-util'
import { crypto, encodeUtf8 } from '@op/crypto'
import type { HttpReqMethod } from './http'

export const DEFAULT_HASH = 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'

export const signPayload = (
	accountId: string,
	parentSecret: string,
	parentSecretId: Maybe<string>,
	url: string,
	method: HttpReqMethod = 'GET',
	body = '',
): string => {
	const urlObject = new URL(url)
	const params = urlObject.searchParams
	params.sort()

	const canonicalPkg = [method, urlObject.pathname, params.toString(), sha256HashHex(body)].join(
		'\n',
	)

	const signedPayload = sign(parentSecret, signPackage(canonicalPkg))

	// NOTE This check exists to support legacy clients in the Android
	//  OurPactWrap. Old parent apps will not have a `parentSecretId`, so in order
	//  to support their state we need to sign the old way. Once these clients
	//  have the `parentSecretId` (a login action occurs) they will be able to
	//  sign the new way.
	return parentSecretId
		? `${accountId}:1.${signedPayload}.${parentSecretId}`
		: `${accountId}:${signedPayload}`
}

export const sign = (secretKey: string, data: string): string => {
	const h = crypto.createHmac()

	h.start('sha256', secretKey)
	h.update(encodeUtf8(data))

	return h.digest().toHex()
}

export const sha256HashHex = (data = ''): string => {
	if (data === '') {
		// There is no data, so skip calculating the hash. SHA256 will always
		// calculate this value for an empty string.
		return DEFAULT_HASH
	}

	return crypto.sha256.create().update(encodeUtf8(data)).digest().toHex()
}

export const signPackage = (data: string) => `PW1-HMAC-SHA256\n${utcDate()}\n${sha256HashHex(data)}`
