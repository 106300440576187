import { resetAction } from '@op/services'
import type { OPThunk } from '../types'
import { isAndroidParent } from '../util'

export const logoutAction = (): OPThunk => (dispatch) => {
	if (isAndroidParent) {
		// We call the android wrap logout function. The wrap uses this to handle
		// lifecycle stuff, such as cleaning up FCM token.
		window.OURPACT.onLogout()
	}

	dispatch(resetAction())
}
