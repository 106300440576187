import { assertNotNullish, pick, setIfNotEqual } from '@eturi/util'
import { toParamsURL } from '@op/util'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import mapValues from 'lodash/mapValues'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { BlockStatusMap, InitState, RawBlockStatus, SThunkState } from '../types'
import { mapRawToBlockStatus } from '../types'

export type BlockStatusStoreState = InitState & {
	readonly statuses: BlockStatusMap
}

export type WithBlockStatusState = {
	readonly blockStatus: BlockStatusStoreState
}

const initialState: BlockStatusStoreState = {
	isInit: false,
	statuses: {},
}

export const blockStatusSlice = /*@__PURE__*/ createSlice({
	name: 'blockStatus',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchBlockStatuses.fulfilled, (s, { payload: blockStatuses }) => {
				s.isInit = true
				// Create a set of all block status user ids while we're adding them,
				// so we can make remove any in the state that aren't in the new list.
				const newBlockStatusUserIds = new Set<string>()

				// Set each block status if it's not equal
				blockStatuses.forEach((status) => {
					const userId = status.user_id
					newBlockStatusUserIds.add(userId)
					setIfNotEqual(s.statuses, userId, status)
				})

				// Remove any block statuses that aren't in the new list (e.g. user deleted).
				for (const userId in s.statuses) {
					if (!newBlockStatusUserIds.has(userId)) {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete s.statuses[userId]
					}
				}
			})
			.addCase(fetchUserBlockStatus.fulfilled, (s, { payload: blockStatus }) => {
				setIfNotEqual(s.statuses, blockStatus.user_id, blockStatus)
			}),
})

export const blockStatusSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	blockStatusSlice,
	(s) => ({
		...s,
		statuses: mapValues(s.statuses, (status) => ({
			...status,
			devices: status.devices.map((d) => pick(d, ['vewStatus', 'viewState'])),
		})),
	}),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type BlockStatusThunkState = SThunkState & WithBlockStatusState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<BlockStatusThunkState>()

export const fetchBlockStatuses = /*@__PURE__*/ createAsyncThunk(
	'blockStatus/fetchAll',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const rawBlockStatuses = await dispatch(
			http.get<Maybe<RawBlockStatus[]>>(
				toParamsURL('/block_status', {
					dev_info: true,
					rule_info: true,
					usage: true,
				}),
				extra,
			),
		)

		assertNotNullish(rawBlockStatuses, 'RawBlockStatus[]')

		return rawBlockStatuses.map(mapRawToBlockStatus)
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isBlockStatusInit$(api.getState())) return false
		},
	},
)

type FetchUserBlockStatusArg = HttpExtra & {
	readonly userId: string
}

export const fetchUserBlockStatus = /*@__PURE__*/ createAsyncThunk(
	'blockStatus/user/fetch',
	async ({ userId, ...extra }: FetchUserBlockStatusArg, { dispatch, extra: { http } }) => {
		const res = await dispatch(
			http.get<Maybe<RawBlockStatus[]>>(
				toParamsURL('/block_status', {
					dev_info: true,
					rule_info: true,
					usage: true,
					user_id: userId,
				}),
				extra,
			),
		)

		const userBlockStatus = res?.[0]

		assertNotNullish(userBlockStatus, 'RawBlockStatus')

		return mapRawToBlockStatus(userBlockStatus)
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithBlockStatusState>(s: T) => s.blockStatus

export const blockStatusMap$ = /*@__PURE__*/ createSelector(state$, (s) => s.statuses)
export const isBlockStatusInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)

export const blockStatusForUserSelector = () =>
	createSelector(
		blockStatusMap$,
		(_: any, userId: string) => userId,
		(blockStatusMap, userId) => blockStatusMap[userId],
	)
