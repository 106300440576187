import { useRefGetter, useWindowEvent } from '@eturi/react'
import { accountId$, isAuthenticated$ } from '@op/services'
import { setTag, setUser } from '@sentry/react'
import { memo, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppPages } from './AppPages'
import { Footer, LoadEmpty } from './components'
import {
	Ably,
	AndroidNotifs,
	Chat,
	EmailVerificationModal,
	GuardModal,
	ProductModal,
} from './components/lazy'
import { NavSlideBox } from './components/Navigation/NavSlideBox'
import { Overlay } from './components/Overlay'
import { hasPairPageStyles$, isMenuView$ } from './compound-selectors/app-misc'
import { currentLang$ } from './compound-selectors/language'
import { ZAppContent } from './constants/z-index'
import { useBodyClass, useIsScreenMD } from './hooks'
import { isAndroidParent } from './util'

export const AppMain = () => {
	const accountId = useSelector(accountId$)
	const currentLang = useSelector(currentLang$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const [getAppHeight, setAppHeight] = useRefGetter(0)
	const isScreenMd = useIsScreenMD()

	// Footer should only be shown for Web desktop view
	const canShowFooter = !isAndroidParent && isScreenMd

	// NOTE: If we need to add an iOS discriminator class this is what we'll use.
	//  That code was removed due to refactoring the layouts.
	// const isSupportedIOS = useSelector(isSupportedIOS$);

	const setViewHeight = () => {
		const appHeight = window.innerHeight

		if (appHeight === getAppHeight()) return

		document.documentElement.style.setProperty('--vh', `${appHeight / 100}px`)
		setAppHeight(appHeight)
	}

	useWindowEvent('resize', setViewHeight)
	useWindowEvent('orientationchange', setViewHeight)

	// Global affecting classes are placed on the body. This isn't ideal, but
	// it makes life easier since the cascade affects portal layers as well.
	useBodyClass('is-menu-view', useSelector(isMenuView$))
	useBodyClass('is-pair', useSelector(hasPairPageStyles$))

	// Set the view height for our ghetto layout
	useLayoutEffect(setViewHeight, [])

	useEffect(() => {
		window.OURPACT.version = process.env.APP_VERSION
	}, [])

	// Set or clear Sentry user based on account id.
	useEffect(() => {
		setUser(accountId ? { id: accountId } : null)
	}, [accountId])

	// Set a tag for the current language
	useEffect(() => {
		setTag('lang', currentLang)
	}, [currentLang])

	return (
		<>
			<NavSlideBox className="op-app-content" style={{ zIndex: ZAppContent }}>
				<AppPages />

				{isAndroidParent && (
					<LoadEmpty>
						<AndroidNotifs />
					</LoadEmpty>
				)}

				{isAuthenticated && (
					<LoadEmpty>
						<Ably />
					</LoadEmpty>
				)}
				{isAuthenticated && (
					<LoadEmpty>
						<GuardModal />
					</LoadEmpty>
				)}
				{isAuthenticated && (
					<LoadEmpty>
						<ProductModal />
					</LoadEmpty>
				)}
				{isAuthenticated && (
					<LoadEmpty>
						<EmailVerificationModal />
					</LoadEmpty>
				)}

				{canShowFooter && <Footer />}

				<Overlay />
			</NavSlideBox>

			<LoadEmpty>
				<Chat />
			</LoadEmpty>
		</>
	)
}

export default /*@__PURE__*/ memo(AppMain)
