import {
	activeChildId$,
	fetchAccessState,
	fetchBlockStatuses,
	fetchDevices,
	fetchEncryptedPrivate,
	fetchSKUDefs,
	fetchUserBlockStatus,
	fetchUserRules,
	fetchUsers,
	isAuthenticated$,
	setPurchaseSummary,
	shouldSetNotTrialEligible$,
} from '@op/services'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { usePolling } from './hooks'
import type { UsersPollingState } from './reducers/fast-poll.slice'
import {
	addChildFastPoll$,
	blockStatusFastPoll$,
	devicesFastPoll$,
} from './reducers/fast-poll.slice'
import { useAppDispatch } from './types'
import { accountGenArgs } from './util/crypto'

type BlockStatusPollingData = [
	pollDuration: number | null,
	userPollDuration: number | null,
	fastPollUserIds: string[],
]

/**
 * Calculates the block status polling data. When a user performs an action that
 * can affect block status, components will trigger to speed up polling for
 * the user who changed. To strike a balance between the increase in requests
 * and the complexity of the requests on the server, we only do individual user
 * fast polling if there are fewer than 3 fast separate users in a fast poll
 * state.
 *
 * If there are 3 or more users in a fast poll state at the same time, we
 * increase the polling on the block status fetch for all users, and stop
 * polling for the individual users.
 */
const getBlockStatusPollingDurations = (
	pollingState: UsersPollingState,
	now: number,
): BlockStatusPollingData => {
	const pollingStateIds: string[] = []

	for (const userId of Object.keys(pollingState)) {
		const fastPollTs = pollingState[userId]

		if (now < fastPollTs) {
			pollingStateIds.push(userId)
		}
	}

	let pollingMs = usePolling.LONG
	let userPollingMs = null
	const size = pollingStateIds.length

	if (size && size <= 2) {
		userPollingMs = usePolling.FAST
	} else if (size > 2) {
		pollingMs = usePolling.FAST
	}

	return [pollingMs, userPollingMs, pollingStateIds]
}

export const AuthPollingImpl = () => {
	const d = useAppDispatch()
	const userId = useSelector(activeChildId$)
	const addChildFastPoll = useSelector(addChildFastPoll$)
	const blockStatusFastPoll = useSelector(blockStatusFastPoll$)
	const devicesFastPoll = useSelector(devicesFastPoll$)
	const shouldSetNotTrialEligible = useSelector(shouldSetNotTrialEligible$)
	const now = Date.now()

	// Fast poll on block statuses in various cases
	const [blockStatusPollingMs, userBlockStatusPollingMs, blockStatusFastPollIds] =
		getBlockStatusPollingDurations(blockStatusFastPoll, now)

	usePolling(
		[
			(extra) => d(fetchEncryptedPrivate({ ...accountGenArgs(), ...extra })),
			(extra) => d(fetchSKUDefs(extra)),
		],
		usePolling.EXTENDED,
	)

	usePolling([(extra) => d(fetchAccessState(extra))], usePolling.LONG)

	// NOTE: Block status and rules are too coupled to separate calls. Although,
	//  even like this, we can be out of sync until both calls are resolved.
	//  Should look into using BlockStatus.rule as the source of truth for
	//  ChildStatus
	usePolling(
		[
			(extra) => {
				d(fetchBlockStatuses(extra))
				userId && d(fetchUserRules({ ...extra, userId }))
			},
		],
		blockStatusPollingMs,
	)

	usePolling(
		[(extra) => d(fetchDevices(extra))],
		now < devicesFastPoll ? usePolling.FAST : usePolling.LONG,
	)

	usePolling(
		[(extra) => d(fetchUsers(extra))],
		now < addChildFastPoll ? usePolling.FAST : usePolling.LONG,
	)

	// NOTE: See BlockStatus comment above
	usePolling(
		[
			(extra) =>
				blockStatusFastPollIds.map((userId) => {
					const args = { ...extra, userId }
					d(fetchUserBlockStatus(args))
					d(fetchUserRules(args))
				}),
		],
		userBlockStatusPollingMs,
	)

	useEffect(() => {
		if (shouldSetNotTrialEligible) {
			d(setPurchaseSummary({ is_trial_eligible: false }))
		}
	}, [shouldSetNotTrialEligible])

	return null
}

/**
 * Combines standard polling models that we need throughout app so they run
 * when authenticated. This doesn't do _all_ polling, just the models we need
 * everywhere.
 */
const AuthPollingEffects = () => {
	const isAuthenticated = useSelector(isAuthenticated$)

	if (!isAuthenticated) return null

	return <AuthPollingImpl />
}

export default /*@__PURE__*/ memo(AuthPollingEffects)
