import { create as createHmac } from 'node-forge/hmac'
import MD from 'node-forge/md'
import type { KeyPair, PrivateKey, PublicKey } from 'node-forge/rsa'
import { ByteBuffer, decode64, decodeUtf8, encode64, encodeUtf8 } from 'node-forge/util'

export type { KeyPair, PrivateKey, PublicKey }
export { ByteBuffer, createHmac, decode64, decodeUtf8, encode64, encodeUtf8 }

export const crypto = {
	get createHmac() {
		return createHmac
	},

	get sha1() {
		return MD.sha1
	},

	get sha256() {
		return MD.sha256
	},
}
