import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import type { AccountSubs, HttpForce, PurchasePlatform } from '@op/services'
import { activeAccountSubs$, setAccountMismatch, setTempTier } from '@op/services'
import find from 'lodash/find'
import some from 'lodash/some'
import {
	clearSubscribingState,
	closeProductModal,
	hasCheckedIAP$,
	setIAPChecked,
	subscribingTier$,
} from '../reducers/product.slice'
import type { GooglePurchase, OPThunkP } from '../types'
import { createGoogleIAP, GoogleBillingCode } from '../types'
import { isAndroidParent } from '../util'

const ANDROID_PKG = 'com.ourpact.androidparent'
let _iapFetchPromise: Maybe<Promise<Maybe<GooglePurchase[]>>>
let _iapPurchasePromise: Maybe<Promise<Maybe<GooglePurchase>>>

/** Fetches Google purchases from the Android wrapper if applicable */
export const fetchGooglePurchasesAction =
	(force: HttpForce = false): OPThunkP =>
	async (dispatch, getState) => {
		const state = getState()
		const iapChecked = hasCheckedIAP$(getState())

		if (!isAndroidParent) {
			// If we're not on android parent, IAP is checked (b/c it doesn't matter)
			!iapChecked && dispatch(setIAPChecked(true))
			return
		}

		if (!force && iapChecked) return

		if (_iapFetchPromise) {
			await _iapFetchPromise
			return
		}

		sentryBreadcrumb('Fetch Google Purchases')

		try {
			_iapFetchPromise = createGoogleIAP(
				window.OURPACT,
				'onPurchasesReceived',
				window.OURPACT.getPurchases,
			)
			const purchases = await _iapFetchPromise

			if (!purchases) throw GoogleBillingCode.ERROR

			const activeAccountSubs = activeAccountSubs$(state)
			const activePurchases = purchases.filter((p) => !p.cancelled)
			const hasAccountMismatch = _hasAccountSubNotInPurchases(activeAccountSubs, activePurchases)

			// Make sure to set mismatch state each time, so we don't get stuck in the mismatch state
			dispatch(setAccountMismatch(hasAccountMismatch))

			if (hasAccountMismatch) return

			// If we find a purchase from Google not in account subs, make sure to let
			// the server know about it.
			const purchaseMismatch = _findPurchaseNotInAccountSubs(activeAccountSubs, activePurchases)

			if (purchaseMismatch) await dispatch(_postReceiptAction(purchaseMismatch))
		} catch (e) {
			// If checking for google purchases throws an exception and it is not
			// caught it will interrupt the resolver and causing it to not redirect
			sentryError(e)
		} finally {
			_iapFetchPromise = null
			dispatch(setIAPChecked(true))
		}
	}

/** Initiate a Google purchase through the Android wrap */
export const purchaseGoogleSKUAction =
	(newSKU: string, oldSKU: Maybe<string> = null): OPThunkP =>
	async (dispatch, getState) => {
		// NOTE: This shouldn't happen because IAP is app blocking
		if (_iapPurchasePromise) {
			await _iapPurchasePromise
			return
		}

		sentryBreadcrumb('Purchase Google SKU')

		_iapPurchasePromise = createGoogleIAP(
			window.OURPACT,
			'onPurchaseResult',
			window.OURPACT.purchaseSku.bind(null, oldSKU, newSKU),
		)

		try {
			const purchase = await _iapPurchasePromise

			sentryError(new Error('No Google Purchase Returned'))

			if (!purchase) throw GoogleBillingCode.ERROR

			// Add SKU as temp tier until we get in sync w/ the server
			const sku = subscribingTier$(getState())

			if (sku) dispatch(setTempTier(sku))

			await dispatch(_postReceiptAction(purchase))
		} finally {
			// If fail or cancel occurs, close purchase modal so that user can retry
			dispatch(closeProductModal())

			// NOTE: We clear the subscribing tier after fail/cancel/success. We clear
			//  the subscribing tier here rather then when the modal closes as this is
			//  often running after the modal closes. That will cause a race condition
			//  and we might end up with a null temp tier so the user won't see an
			//  upgrade until the next poll cycle. Clearing it here will make sure
			//  that doesn't happen
			dispatch(clearSubscribingState())
			_iapPurchasePromise = null
		}
	}

const _postReceiptAction =
	({ sku, token }: GooglePurchase): OPThunkP =>
	async (dispatch, _, { http }) => {
		const receipt_type: PurchasePlatform = 'google'
		const receipt = {
			package: ANDROID_PKG,
			product_id: sku,
			purchase_token: token,
			receipt_type,
		}

		await dispatch(http.post('/receipt', { data: receipt }))
	}

/**
 * Find if any account subs are:
 * 1. Google subs and
 * 2. Do not have a corresponding sku that exists in purchases. If this is the
 *   case, it's likely that the user is not on the correct Google Account
 */
const _hasAccountSubNotInPurchases = (subs: AccountSubs, purchases: GooglePurchase[]) =>
	some(subs, ({ source }, sku) => source === 'google' && !some(purchases, { sku }))

/**
 * Finds any mismatches between known account subscriptions retrieved from the
 * backend and the list of Google purchases retrieved from the AndroidParent
 * wrapper, where Google says we have purchases that the server doesn't reflect.
 */
const _findPurchaseNotInAccountSubs = (
	subs: AccountSubs,
	purchases: GooglePurchase[],
): Maybe<GooglePurchase> => find(purchases, ({ sku }) => !some(subs, (_, s) => s === sku))
