import { isAuthenticated$ } from '@op/services'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setRedirect } from '../../reducers/app-misc.slice'
import { useAppDispatch } from '../../types'

export const AuthRedirect = () => {
	const d = useAppDispatch()
	const isAuthenticated = useSelector(isAuthenticated$)

	// We clear the redirect once we've gone from unauthenticated to authenticated
	useEffect(() => {
		if (isAuthenticated) d(setRedirect(null))
	}, [isAuthenticated])

	return null
}
