import { lazy, memo } from 'react'
import { LoadPage } from '../../components'
import { ManageNavBar } from './ManageNavBar'

const ManagePageContent = lazy(() => import('./ManagePageContent'))

const ManagePage = () => (
	<>
		<ManageNavBar />

		<LoadPage>
			<ManagePageContent />
		</LoadPage>
	</>
)

export default /*@__PURE__*/ memo(ManagePage)
