import cls from 'classnames'
import type { CSSProperties } from 'react'

type ChooseSubSelectCheckProps = {
	readonly className?: string
	readonly selected: boolean
	readonly style?: CSSProperties
}

export const ChooseSubSelectCheck = ({ className, selected, style }: ChooseSubSelectCheckProps) => {
	return (
		<div
			className={cls(
				'flex flex-center rounded-full border-2 w-5 h-5 sm:w-6 sm:h-6 md:w-8 md:h-8',
				selected && 'bg-teal border-teal',
				className,
			)}
			style={style}
		>
			{selected && <i className="pakicon-check text-white text-base md:text-2xl" />}
		</div>
	)
}
