import { arrayAddOrUpdate, assertNotNullish, pick, setIfNotEqual } from '@eturi/util'
import { size } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { removeDeviceFromAllStores, resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	InitState,
	NewAccountDevice,
	RawDevice,
	RawManagedDevice,
	SThunkState,
} from '../types'
import { isManagedDevice } from '../types'
import { toBoolAction } from '../util'

export type DeviceState = InitState & {
	readonly androidFcmToken: Maybe<string>
	readonly devices: RawDevice[]
}

export type WithDeviceState = {
	readonly device: DeviceState
}

const initialState: DeviceState = {
	androidFcmToken: null,
	devices: [],
	isInit: false,
}

export const deviceSlice = /*@__PURE__*/ createSlice({
	name: 'device',
	initialState,
	reducers: {
		setAndroidFcmToken(s, a: PayloadAction<Maybe<string>>) {
			s.androidFcmToken = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchDevices.fulfilled, (s, a) => {
				s.isInit = true
				setIfNotEqual(s, 'devices', a.payload)
			})
			.addCase(removeDeviceFromAllStores, (s, a) => {
				const devices = reject(s.devices, { device_id: a.payload })

				if (devices.length !== s.devices.length) s.devices = devices
			})
			.addMatcher(
				isAnyOf(createDevice.fulfilled, fetchDevice.fulfilled, updateDevice.fulfilled),
				(s, a) => {
					s.devices = arrayAddOrUpdate(s.devices, a.payload, 'device_id')
				},
			),
})

export const { setAndroidFcmToken } = deviceSlice.actions

export const deviceSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	deviceSlice,
	(s) => ({
		isInit: s.isInit,
		devices: s.devices
			.filter((d) => !d.retired)
			.map((d) =>
				pick(d, [
					'app',
					'app_version',
					'command_state',
					'created',
					'device_id',
					'device_location_active',
					'device_location_ts',
					'display_name',
					'display_state',
					'last_activity',
					'last_seen',
					'make',
					'management_level',
					'management_removed',
					'model',
					'model_name',
					'os',
					'os_version',
					'pair_id',
					'schedule_ts',
					'suspended',
					'system_components',
					'users',
					'version',
				]),
			),
	}),
	(a) => (setAndroidFcmToken.match(a) ? toBoolAction(a) : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type DeviceThunkState = SThunkState & WithDeviceState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<DeviceThunkState>()

// FIXME: Handle errors since this used to try / catch
export const createDevice = /*@__PURE__*/ createAsyncThunk(
	'device/create',
	async (device: NewAccountDevice, { dispatch, extra: { http } }) => {
		const newDevice = await dispatch(http.post<Maybe<RawDevice>>('/device', { data: [device] }))

		assertNotNullish(newDevice, 'Create RawDevice')

		return newDevice
	},
)

// FIXME: try / catch client impl
export const fetchDevice = /*@__PURE__*/ createAsyncThunk(
	'device/fetch',
	async (deviceId: string, { dispatch, extra: { http } }) => {
		const res = await dispatch(http.get<Maybe<[RawDevice]>>(`/device?device_id=${deviceId}`))
		const device = res?.[0]

		assertNotNullish(device, 'RawDevice')

		return device
	},
)

export const fetchDevices = /*@__PURE__*/ createAsyncThunk(
	'device/fetchAll',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const devices = await dispatch(http.get<Maybe<RawDevice[]>>('/device', extra))

		assertNotNullish(devices, 'RawDevice[]')

		return devices
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isDevicesInit$(api.getState())) return false
		},
	},
)

export const removeDevice = /*@__PURE__*/ createAsyncThunk(
	'device/delete',
	async (device_id: string, { dispatch, extra: { http } }) => {
		const res = await dispatch(
			http.delete<Maybe<{ objects_deleted: number }>>('/device', { data: { device_id } }),
		)

		const nDeleted = res?.objects_deleted || 0

		if (nDeleted) {
			dispatch(removeDeviceFromAllStores(device_id))

			await dispatch(fetchDevices({ force: true })).unwrap()

			return true
		}

		return false
	},
)

type UpdateDevicePayload = WithRequired<Partial<RawDevice>, 'device_id'>

// FIXME: Try/catch client impl
export const updateDevice = /*@__PURE__*/ createAsyncThunk(
	'device/update',
	async (update: UpdateDevicePayload, { dispatch, extra: { http } }) => {
		const device = await dispatch(http.put<Maybe<RawDevice>>('/device', { data: [update] }))

		assertNotNullish(device, 'RawDevice')

		return device
	},
)

type RequestDeviceInfoArg = {
	readonly userId?: string
}
export const requestDeviceInfo = /*@__PURE__*/ createAsyncThunk(
	'device/requestDeviceInfo',
	async ({ userId }: RequestDeviceInfoArg = {}, { dispatch, extra: { http } }) => {
		await dispatch(http.post('/command?command=device_info', { userId }))
	},
)

// NOTE: Not implemented in OurPact yet
// export const reassignDeviceAction;

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithDeviceState>(s: T) => s.device

export const androidFcmToken$ = /*@__PURE__*/ createSelector(state$, (d) => d.androidFcmToken)
export const rawDevices$ = /*@__PURE__*/ createSelector(state$, (s) => s.devices)
export const rawManagedDevices$ = /*@__PURE__*/ createSelector(
	rawDevices$,
	(d): RawManagedDevice[] => d.filter(isManagedDevice),
)
export const totalManagedDevices$ = /*@__PURE__*/ createSelector(rawManagedDevices$, size)
export const isDevicesInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
