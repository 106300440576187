import './Toast.scss'

import { a, config, useTransition } from '@react-spring/web'
import { memo, useContext } from 'react'
import { ToastsContext } from './ToastManager'

const ToastContainer = () => {
	const toasts = useContext(ToastsContext)

	const transitions = useTransition(toasts, {
		config: config.gentle,
		key: (t: { id: string }) => t.id,
		from: {
			opacity: 0,
			transform: 'translate3d(0%, 150%, 0)',
		},
		enter: {
			opacity: 1,
			transform: 'translate3d(0%, 0%, 0)',
		},
		leave: {
			opacity: 0,
			transform: 'translate3d(-150%, 0%, 0)',
		},
	})

	return (
		<section className="toasts">
			{transitions((style, item) => (
				<a.div className="toast" style={style}>
					{item.toast}
				</a.div>
			))}
		</section>
	)
}

export default /*@__PURE__*/ memo(ToastContainer)
