import { lazy } from 'react'

export const GuardModal = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'guard-modal' */
			/* webpackPrefetch: 0 */
			'./GuardModal'
		),
)
