import type { ReactNode } from 'react'
import { createContext } from 'react'

export type NavCtx = {
	readonly isDesktop: boolean
	readonly isMobile: boolean
	readonly isOpen: boolean
	setNavBar(navBar: ReactNode): void
	toggle(): void
}

export const NavCtx = createContext<NavCtx>(null as any)
