import './ChildAppInstallBtn.scss'

import { duration } from '@eturi/date-util'
import { useBoolState, useFn, useMounted, useTimeout } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import INSTALL_JR from '@op/assets/logos/ourpact-jr.svg'
import { installChildApp } from '@op/services'
import { disabledProps } from '../../common'
import { useAppDispatch } from '../../types'
import { Btn } from '../Buttons'
import { Modal } from '../Modal'
import { useToasts } from '../Toast'

type ChildAppInstallBtnProps = {
	readonly childId: string
	readonly className?: string
	readonly deviceId: string
	readonly disabled?: boolean
}

export const ChildAppInstallBtn = ({
	childId,
	deviceId,
	disabled = false,
}: ChildAppInstallBtnProps) => {
	const d = useAppDispatch()
	const [t] = useT()
	const { addErrorToast } = useToasts()
	const isMounted = useMounted()
	const [isChildAppInstallModalVisible, showChildAppInstallModal, hideChildAppInstallModal] =
		useBoolState(false)
	const [isInstallingChildApp, setInstallingChildApp, setNotInstallingChildApp] =
		useBoolState(false)
	const [setInstallingTimeout, clearInstallingTimeout] = useTimeout()

	const isInstallChildAppDisabled = disabled || isInstallingChildApp

	const handleInstallChildAppClick = useFn(async () => {
		const CHILD_APP_INSTALL_TIMEOUT = duration(45, 's')

		setInstallingChildApp()
		setInstallingTimeout(setNotInstallingChildApp, CHILD_APP_INSTALL_TIMEOUT)

		try {
			await d(installChildApp({ deviceId, userId: childId })).unwrap()
			isMounted() && showChildAppInstallModal()
		} catch {
			addErrorToast(t('error_strings.install_child_app_error'))

			if (!isMounted()) return

			clearInstallingTimeout()
			setNotInstallingChildApp()
		}
	})

	return (
		<>
			<Btn
				{...disabledProps(isInstallChildAppDisabled, 'install-child-app-btn has-img font-bold')}
				onClick={handleInstallChildAppClick}
			>
				<span>
					{t('actions.install_child_app')}
					<img src={INSTALL_JR} alt="OurPact Jr." title="OurPact Jr." />
				</span>
			</Btn>

			{isChildAppInstallModalVisible && (
				<Modal
					header={t('devices.child_app_installed_modal.header')}
					onClose={hideChildAppInstallModal}
					size="info"
				>
					<p>{t('devices.child_app_installed_modal.description')}</p>

					<div className="modal__bottom">
						<Btn onClick={hideChildAppInstallModal}>{t('actions.ok')}</Btn>
					</div>
				</Modal>
			)}
		</>
	)
}
