import { now, subtract } from '@eturi/date-util'
import { assertNotNullish, pick } from '@eturi/util'
import { keysToBool } from '@op/util'
import type { Draft } from '@reduxjs/toolkit'
import { createAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import forOwn from 'lodash/forOwn'
import identity from 'lodash/identity'
import isEmpty from 'lodash/isEmpty'
import isMatch from 'lodash/isMatch'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	AnyDecoratedSample,
	AnyDecoratedSampleMap,
	DecoratedSample,
	DecoratedSparseSampleMap,
	DecryptSampleImgFn,
	DecryptTempKeysFn,
	DownloadUrls,
	GetKeyIdFn,
	SampleAnalysisMap,
	SampleAnalysisRes,
	SampleImage,
	SampleMetadataRes,
	SampleType,
	SparseSampleMetadataRes,
	SThunkState,
	UpdateDownloadUrls,
	VewNowRes,
	VewRequestBody,
} from '../types'
import {
	createIdPayloadPrepare,
	decorateSamples,
	decorateSparseSamples,
	SPARSE_SAMPLE_FILTER,
	toImageId,
} from '../types'
import type { SamplesUpdateMap, SampleUpdate, SampleUpdatePayload } from '../types/SamplePayloads'
import { thunkReqFactory } from '../util'
import type { WithAccessState } from './access.slice'
import { isVewEnabled$ } from './access.slice'
import { privateKeyPem$, publicKeyPem$, setTempKeys, tempKeys$ } from './account.slice'

export type UserSamplesState = {
	readonly lastFetchedTs: number
	// NOTE: Previously this was done via selector, by finding the latest ts in a
	//  list of samples. However, this is only used to determine the sparse
	//  samples request, and there was also a minor issue where, if the last
	//  sample updated was a deletion, it wouldn't update to that ts, because
	//  deleted samples are filtered out.
	readonly lastUpdatedTs: number
	readonly samples: AnyDecoratedSampleMap
}

export type SamplesState = {
	readonly currentDayTs: number
	// NOTE: This is a value that must be manually updated periodically as it's
	//  used to determine whether samples are expired. For convenience, I also
	//  put the `currentDayTs` in state, as this is needed for the display state
	//  and `sampleExpiryTs` is derived from it. This makes sure the ts used for
	//  current day and expiry are in sync.
	readonly sampleExpiryTs: number
	readonly user: { readonly [userId: string]: UserSamplesState }
}

export type WithSamplesState = {
	readonly samples: SamplesState
}

const createUserSamplesState = (): UserSamplesState => ({
	lastFetchedTs: 1,
	lastUpdatedTs: -1,
	samples: {},
})

// Timestamps are set to the start of day to avoid the unnecessary
// recalculations of using a Date.now(). All we care about is the day.
export const getSamplesStateTs = () => {
	const currentDay = now.startOf('d')

	return {
		currentDayTs: +currentDay,
		sampleExpiryTs: +subtract(currentDay, 2, 'w'),
	}
}

const initialState: SamplesState = {
	...getSamplesStateTs(),
	user: {},
}

export const getUserSamplesState = (s: SamplesState['user'], userId: string) =>
	s[userId] || createUserSamplesState()

const ensureUserState = (s: Draft<SamplesState>, id: string) =>
	(s.user[id] ||= castDraft(createUserSamplesState()))

type DisposeSampleImage = (img: SampleImage) => void

export const deleteSampleFromStore = /*@__PURE__*/ createAction(
	'samplesReducer/deleteSample',
	createIdPayloadPrepare<string>(),
)

export const revokeFsRefs = /*@__PURE__*/ createAction(
	'samplesReducer/revokeFsRefs',
	createIdPayloadPrepare<string[]>(),
)

export const setSamples = /*@__PURE__*/ createAction(
	'samplesReducer/set',
	createIdPayloadPrepare<DecoratedSparseSampleMap>(),
)

const updateLastFetchedTs = /*@__PURE__*/ createAction(
	'samplesReducer/updateLastFetchedTs',
	createIdPayloadPrepare(),
)

const updateLastUpdatedTs = /*@__PURE__*/ createAction(
	'samplesReducer/updateLastUpdatedTs',
	createIdPayloadPrepare<number>(),
)

export const updateSamples = /*@__PURE__*/ createAction(
	'samplesReducer/updateSamples',
	createIdPayloadPrepare<SampleUpdatePayload>(),
)

export const updateSampleStateCurrentDayTs = /*@__PURE__*/ createAction(
	'samplesReducer/updateCurrentDayTs',
)

export const updateAnalysis = /*@__PURE__*/ createAction(
	'samplesReducer/updateAnalysis',
	createIdPayloadPrepare<SampleAnalysisMap>(),
)

export const updateSampleUrls = /*@__PURE__*/ createAction(
	'samplesReducer/updateUrls',
	createIdPayloadPrepare<UpdateDownloadUrls>(),
)

export const createSamplesSlice = (disposeSampleImage: DisposeSampleImage) => {
	const sampleTypes: SampleType[] = ['screen', 'thumb']

	const _disposeSampleImage = (
		sample: Draft<AnyDecoratedSample>,
		type: SampleType,
		shouldDelete?: boolean,
	) => {
		const img = sample[type]

		if (!img) return

		disposeSampleImage(img)

		if (shouldDelete) delete img.src
	}

	const _deleteAndDisposeSample = (s: Draft<AnyDecoratedSampleMap>, imageId: string) => {
		const sample = s[imageId]

		if (!sample) return

		// NOTE: If we're deleting the images, we don't need to delete the src,
		//  since the sample ref will get garbage collected as a whole
		_disposeSampleImage(sample, 'screen')
		_disposeSampleImage(sample, 'thumb')

		// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
		delete s[imageId]
	}

	return createSlice({
		name: 'samples',
		initialState,
		reducers: {},
		extraReducers: (builder) =>
			builder
				.addCase(resetAction, (s) => {
					const usersState = s.user

					for (const [userId, userState] of Object.entries(usersState)) {
						const samples = userState.samples

						for (const imageId of Object.keys(samples)) {
							_deleteAndDisposeSample(samples, imageId)
						}

						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete usersState[userId]
					}
				})

				.addCase(deleteSampleFromStore, (s, { payload: { id, value: imageId } }) => {
					_deleteAndDisposeSample(ensureUserState(s, id).samples, imageId)
				})

				.addCase(revokeFsRefs, (s, { payload: { id, value: imageIds } }) => {
					const { samples } = ensureUserState(s, id)

					for (const imageId of imageIds) {
						const sample = samples[imageId]

						if (!sample) continue

						// Specifically delete the src field when disposing
						_disposeSampleImage(sample, 'screen', true)
					}
				})

				.addCase(setSamples, (s, { payload: { id, value } }) => {
					// NOTE: Setting samples only happens on init, so we don't need to
					//  worry about checking equality.
					ensureUserState(s, id).samples = value
				})

				.addCase(updateLastFetchedTs, (s, { payload: { id } }) => {
					ensureUserState(s, id).lastFetchedTs = Date.now()
				})

				.addCase(updateLastUpdatedTs, (s, { payload: { id, value } }) => {
					ensureUserState(s, id).lastUpdatedTs = value
				})

				.addCase(updateAnalysis, (s, { payload: { id, value: analysisMap } }) => {
					const { samples } = ensureUserState(s, id)

					forOwn(analysisMap, (analysis, sampleId) => {
						const hits = analysis.ocr_hits
						if (!hits) return

						hits.forEach((hits, idx) => {
							const imageId = toImageId(sampleId, idx)
							const sample = samples[imageId]

							if (!sample || sample.analysis?.updated_ts === hits.updated_ts) return

							sample.analysis = hits
						})
					})
				})

				.addCase(updateSamples, (s, { payload: { id, value } }) => {
					const { samples } = ensureUserState(s, id)
					const { deleted, samples: sampleUpdates } = value
					// FIXME: Rewrite comments
					// Merges a sample update into a current sample, taking into account nested objects
					// NOTE: There are several type castings here. What we're saying is:
					//  1. If there isn't a current sample, the samplesUpdate is an AnyDecoratedSample
					//  2. If the sample update contains `screen` or `thumb`, it means that either:
					//     1. `thumb`/`screen` exists on sample update (iv + url), or if it doesn't
					//         (like when we're updating only `objectUrl`)
					//     2. The iv + url at least exists on `currentSample`
					//     This is because we can't obtain a valid `objectUrl` without the iv + url.

					forOwn(sampleUpdates, (updateSample, imageId) => {
						const currentSample = samples[imageId]

						if (!currentSample) {
							samples[imageId] = updateSample as AnyDecoratedSample
							return
						}

						// If all updates are equivalent in currentSample, then we don't
						// need to continue merging
						if (isMatch(currentSample, updateSample)) return

						// Merge the two into a new sample and then merge their images if
						// available
						const mergedSample = {
							...currentSample,
							...updateSample,
						} as Writable<AnyDecoratedSample>

						// Merge in properties of sample type objects
						for (const sampleType of sampleTypes) {
							const sampleImg = updateSample[sampleType]

							if (sampleImg) {
								mergedSample[sampleType] = {
									...currentSample[sampleType],
									...sampleImg,
								} as SampleImage
							}
						}

						samples[imageId] = mergedSample
					})

					// Remove any samples that are expired or deleted
					forOwn(samples, (sample, imageId) => {
						if (sample.sample_ts < s.sampleExpiryTs || deleted?.includes(sample.sample_id)) {
							_deleteAndDisposeSample(samples, imageId)
						}
					})
				})

				.addCase(updateSampleStateCurrentDayTs, (s) => {
					const { currentDayTs, sampleExpiryTs } = getSamplesStateTs()

					// Since expiry TS is derived from current ts, we only need to check
					// if one has changed. If there's no change, we just return.
					if (s.currentDayTs === currentDayTs) return

					// If there's a new state, we assign it, and then see if we need to
					// remove any samples.
					s.currentDayTs = currentDayTs
					s.sampleExpiryTs = sampleExpiryTs

					// When date changes, remove any expired samples from all users
					forOwn(s.user, (userState) => {
						const userSamples = userState.samples

						forOwn(userSamples, (sample, imageId) => {
							if (sample.sample_ts < sampleExpiryTs) {
								_deleteAndDisposeSample(userSamples, imageId)
							}
						})
					})
				})

				.addCase(updateSampleUrls, (s, { payload: { id, value } }) => {
					const { samples } = ensureUserState(s, id)
					const dlExpiry = value.get_expiry

					for (const sampleId in value.get_urls) {
						const updatedUrls = value.get_urls[sampleId]
						const totalSampleEntries = Math.max(
							updatedUrls.screen?.length || 0,
							updatedUrls.thumb?.length || 0,
						)

						for (let i = 0; i < totalSampleEntries; i++) {
							const imageId = toImageId(sampleId, i)
							const currentSample = samples[imageId]

							if (!currentSample) continue

							currentSample.dlExpiry = dlExpiry

							for (const sampleType of sampleTypes) {
								const url = updatedUrls[sampleType]?.[i]?.url

								if (url) {
									// FIXME: We should probably address having to cast like this
									currentSample[sampleType] = { ...currentSample[sampleType], url } as SampleImage
								}
							}
						}
					}
				}),
	})
}

const isSampleAction = /*@__PURE__*/ isAnyOf(
	deleteSampleFromStore,
	revokeFsRefs,
	setSamples,
	updateLastFetchedTs,
	updateLastUpdatedTs,
	updateSamples,
	updateSampleUrls,
)

export const createSamplesSliceTransformer = (s: ReturnType<typeof createSamplesSlice>) =>
	createSliceTransformer(
		s,
		(ss) => ({
			sampleExpiryTs: ss.sampleExpiryTs,
			user: mapValues(ss.user, (u) =>
				map(u.samples, (s) => ({
					...pick(s, ['dlExpiry', 'hits', 'imageId']),
					...keysToBool(s, ['thumb', 'screen']),
				})),
			),
		}),
		(a) => (isSampleAction(a) ? null : a),
	)

////////// Thunks //////////////////////////////////////////////////////////////

export type SamplesThunkState = SThunkState & WithAccessState & WithSamplesState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<SamplesThunkState>()

type FetchSampleAnalysisArg = {
	// FIXME: Currently "samples requiring analysis". But this is done based on
	//  "full screen" and "samplesRequiringCiphertext"
	readonly samples: DecoratedSample[]
	readonly userId: string
}

const fetchSampleAnalysisReqMgr = /*@__PURE__*/ thunkReqFactory<DecoratedSample>((s) => s.sample_id)

/** Fetches sample analysis for given sample ids of a given user */
export const fetchSampleAnalysis = /*@__PURE__*/ createAsyncThunk(
	'samples/analysis/fetch',
	({ samples, userId }: FetchSampleAnalysisArg, { dispatch, extra: { http } }) =>
		fetchSampleAnalysisReqMgr(samples, async (filteredSamples) => {
			if (isEmpty(filteredSamples)) return

			// FIXME: Predicate on samples without analysis
			const data: VewRequestBody = {
				request_args: { sample_ids: map(filteredSamples, 'sample_id') },
				request_type: 'get_analysis',
				user_id: userId,
			}

			try {
				const { sample_analysis } = await dispatch(
					http.post<SampleAnalysisRes>('/vew_request', { data }),
				)

				assertNotNullish(sample_analysis, 'SampleAnalysisMap')

				dispatch(updateAnalysis(userId, sample_analysis))
			} catch (e) {
				console.error(e)
			}
		}),
)

const _dlCiphertext = async (url: string): Promise<ArrayBuffer> => {
	const res = await fetch(url, { method: 'GET', credentials: 'omit' })

	if (!res?.ok) {
		throw new Error('Invalid ciphertext response')
	}

	return res.arrayBuffer()
}

const fetchSampleCipherReqMgr = /*@__PURE__*/ thunkReqFactory<DecoratedSample, void, SampleType>(
	(sample, type) => sample.imageId + type,
)

type FetchSampleCiphertextArg = {
	readonly decrypt: DecryptSampleImgFn
	readonly samples: DecoratedSample[]
	readonly type: SampleType
	readonly userId: string
}

/** Thunk action that downloads ciphertext for given ids of a given user */
export const fetchSampleCiphertext = /*@__PURE__*/ createAsyncThunk(
	'sample/ciphertext/fetch',
	({ decrypt, samples, type, userId }: FetchSampleCiphertextArg, { dispatch, getState }) =>
		fetchSampleCipherReqMgr(
			samples,
			async (filteredSamples) => {
				const tempKeyMap = tempKeys$(getState())
				const sampleUpdatesList = await Promise.all(
					filteredSamples.map(async (s): Promise<Maybe<SampleUpdate>> => {
						const update: Writable<SampleUpdate> = { imageId: s.imageId, [type]: null }

						try {
							const sampleImg = s[type]
							const tempKey = tempKeyMap[s.encKey]

							assertNotNullish(tempKey, 'Sample temp key')

							const encBuffer = await _dlCiphertext(sampleImg.url)
							assertNotNullish(encBuffer, 'Ciphertext ArrayBuffer')

							const src = await decrypt(encBuffer, tempKey, sampleImg.iv)
							assertNotNullish(src, 'Decrypted image src')

							update[type] = { src }
						} catch (e) {
							console.log('Fetch ciphertext error', e)
							// If there's an error do not modify the sample. Without this,
							// we'd have `image[type] = null` but any `dlExpiry` and other
							// stuff would remain, which would screw up our ability to fetch
							// again on error.
							return null
						}

						return update
					}),
				)

				// Reduce the list into a map and update
				dispatch(
					updateSamples(userId, {
						samples: sampleUpdatesList.reduce((updates: Writable<SamplesUpdateMap>, update) => {
							if (update) updates[update.imageId] = update

							return updates
						}, {}),
					}),
				)
			},
			type,
		),
)

const fetchSampleUrlsReqManager = /*@__PURE__*/ thunkReqFactory<string, void>(identity)

type FetchSampleUrlsArg = {
	readonly sampleIds: string[]
	readonly userId: string
}

/** Thunk action that fetches download urls for given sample ids of a given user */
export const fetchSampleUrls = /*@__PURE__*/ createAsyncThunk(
	'samples/fetchUrls',
	({ sampleIds, userId }: FetchSampleUrlsArg, { dispatch, extra: { http } }) =>
		fetchSampleUrlsReqManager(sampleIds, async (filteredIds) => {
			if (!filteredIds.length) return

			const data: VewRequestBody = {
				request_args: { sample_ids: filteredIds },
				request_type: 'get_download_urls',
				user_id: userId,
			}

			const updatedDownloadUrls = await dispatch(http.post<DownloadUrls>('/vew_request', { data }))

			assertNotNullish(updatedDownloadUrls, 'DownloadUrls')

			dispatch(updateSampleUrls(userId, updatedDownloadUrls))
		}),
)

const fetchDecoratedSamplesReqMgr = /*@__PURE__*/ thunkReqFactory<string, void>(identity)

// FIXME: Better comments
type FetchDecoratedSamplesArg = {
	readonly decryptKeys: DecryptTempKeysFn
	readonly getKeyId: GetKeyIdFn
	/** @field Sparse sample ids */
	readonly sampleIds: string[]
	readonly userId: string
}

/** Thunk action that fetches full samples and decorates them for a given user slice. */
export const fetchDecoratedSamples = /*@__PURE__*/ createAsyncThunk(
	'samples/decorated/fetch',
	async (
		{ decryptKeys, getKeyId, sampleIds, userId }: FetchDecoratedSamplesArg,
		{ dispatch, getState, extra: { http } },
	) => {
		const state = getState()
		const privateKeyPem = privateKeyPem$(state)
		const publicKeyPem = publicKeyPem$(state)
		const tempKeys = tempKeys$(state)
		const keyId = await getKeyId(publicKeyPem)

		if (!(keyId && privateKeyPem)) return

		return fetchDecoratedSamplesReqMgr(sampleIds, async (filteredSampleIds) => {
			if (!filteredSampleIds.length) return

			const data: VewRequestBody = {
				request_args: { sample_ids: filteredSampleIds, get_download_urls: true },
				request_type: 'get_sample_meta',
				user_id: userId,
			}

			const samplesRes = await dispatch(http.post<SampleMetadataRes>('/vew_request', { data }))

			assertNotNullish(samplesRes, 'SampleMetadataRes')

			const [updatedSamples, tempKeysToDecrypt] = decorateSamples(samplesRes, keyId, tempKeys)

			// TODO: I'd like to remove the requirement of decorated samples having
			//  to have a temp key already decrypted. If we could do it on-demand it
			//  would be better and would make updates less confusing, especially in
			//  `updateSamples()`. Same with iv. Make it optional. We then only fetch
			//  and decrypt ciphertext when we have iv, decrypted temp key, and url.
			if (tempKeysToDecrypt.size) {
				const newTempKeys = await decryptKeys([...tempKeysToDecrypt], privateKeyPem)
				dispatch(setTempKeys(newTempKeys))
			}

			dispatch(updateSamples(userId, updatedSamples))
		})
	},
)

const shouldFetchSparseSamples = (s: SamplesThunkState, userId: string) =>
	+now.subtract(2, 'm') <= getUserSamplesState(userSamples$(s), userId).lastFetchedTs

const shouldInitSparseSamples = (lastUpdatedTs: number) => +now.subtract(2, 'w') > lastUpdatedTs

type FetchSparseSamplesArg = HttpExtra & {
	readonly userId: string
}

/**
 * NOTE: The iteration of `sample_meta` should never be called, but I'm keeping
 *  it here for testing for now. `start_after` calls should return
 *  `max_sample_ts` and `updated_after` calls should return `max_updated_ts`.
 *  Based on current usage, that should be all we need for getting the ts.
 */
const getLastUpdatedTsFromSparseRes = (
	lastUpdatedTs: number,
	{ max_sample_ts, max_updated_ts, sample_meta }: SparseSampleMetadataRes,
) => {
	const tsFromRes = max_sample_ts || max_updated_ts

	if (Number.isFinite(tsFromRes)) return Math.max(lastUpdatedTs, tsFromRes)

	for (const sampleId in sample_meta) {
		lastUpdatedTs = Math.max(lastUpdatedTs, sample_meta[sampleId].updated_ts)
	}

	return lastUpdatedTs
}

/**
 * Thunk action to fetch any sparse samples for the active slice. Will determine
 * whether an update is * required and what type of update (if any), unless
 * `force` param is passed.
 *
 * NOTE: This has the sole responsibility of updating the `lastFetchTs` and
 *  `lastUpdatedTs`.
 */
export const fetchSparseSamples = /*@__PURE__*/ createAsyncThunk(
	'samples/sparse/fetch',
	async ({ userId, ...extra }: FetchSparseSamplesArg, { dispatch, getState, extra: { http } }) => {
		const { lastUpdatedTs } = getUserSamplesState(userSamples$(getState()), userId)
		const isInit = shouldInitSparseSamples(lastUpdatedTs)

		const data: VewRequestBody = {
			request_args: {
				...(isInit ? { start_after: 1 } : { updated_after: lastUpdatedTs }),
				filter: SPARSE_SAMPLE_FILTER,
				limit: 10000,
			},
			request_type: 'get_sample_meta',
			user_id: userId,
		}

		const samplesRes = await dispatch(
			http.post<Maybe<SparseSampleMetadataRes>>('/vew_request', { ...extra, data }),
		)

		assertNotNullish(samplesRes, 'SparseSampleMetadataRes')

		dispatch(updateLastFetchedTs(userId))
		dispatch(updateLastUpdatedTs(userId, getLastUpdatedTsFromSparseRes(lastUpdatedTs, samplesRes)))

		// When we are initializing, we replace the samples, so there's no need to
		// check anything else (e.g. remove deleted).
		if (isInit) {
			dispatch(setSamples(userId, decorateSparseSamples(samplesRes, true)))
			return
		}

		// If not initializing, we'll update samples, and we need to also check for
		// and remove and deleted samples from the store.
		dispatch(updateSamples(userId, decorateSparseSamples(samplesRes)))
	},
	{
		condition: (arg, api) => {
			if (
				!isVewEnabled$(api.getState()) ||
				(!arg.force && shouldFetchSparseSamples(api.getState(), arg.userId))
			)
				return false
		},
	},
)

type DoOnDemandArg = {
	readonly sessionDeviceId: string
	readonly userId: string
}

/** Thunk action that performs an "on demand" request for a screen sample */
export const doOnDemand = /*@__PURE__*/ createAsyncThunk(
	'samples/onDemand',
	async ({ sessionDeviceId, userId }: DoOnDemandArg, { dispatch, extra: { http } }) => {
		const data: VewRequestBody = {
			request_args: { requested_by: sessionDeviceId },
			request_type: 'on_demand',
			user_id: userId,
		}

		const res = await dispatch(http.post<Maybe<VewNowRes>>('/vew_request', { data }))

		return res?.request_ts
	},
)

export const removeSampleScreen = /*@__PURE__*/ createAsyncThunk(
	'sample/screen/delete',
	async (
		{ imageId, sample_id, screenIdx, user_id }: AnyDecoratedSample,
		{ dispatch, extra: { http } },
	) => {
		const data: VewRequestBody = {
			request_args: { sample_id, screens: [screenIdx] },
			request_type: 'delete_sample',
			user_id,
		}

		await dispatch(http.post('/vew_request', { data }))

		dispatch(deleteSampleFromStore(user_id, imageId))
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithSamplesState>(s: T) => s.samples

export const samplesCurrentDayTs$ = /*@__PURE__*/ createSelector(state$, (s) => s.currentDayTs)
export const samplesExpiryTs$ = /*@__PURE__*/ createSelector(state$, (s) => s.sampleExpiryTs)
export const userSamples$ = /*@__PURE__*/ createSelector(state$, (s) => s.user)
