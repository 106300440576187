import { privateKeyFromPem } from './privateKeyFromPem'
import { publicFromPrivateKey } from './publicFromPrivateKey'
import type { KeyPair } from './re-exports'

export const keyPairFromPrivateKeyPem = (privateKeyPem: string): Maybe<KeyPair> => {
	const privateKey = privateKeyFromPem(privateKeyPem)

	if (privateKey) {
		const publicKey = publicFromPrivateKey(privateKey)

		return { privateKey, publicKey }
	}

	return null
}
