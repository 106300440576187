export type Coords = {
	readonly x: number
	readonly y: number
}

/** Gets scroll position in the x / y coordinates */
export const getScrollPos = (
	$el: HTMLElement,
	$scrollParent: HTMLElement | null = null,
): Coords => {
	let $parent: HTMLElement | null = $el
	let offsetTop = $el.offsetTop
	let offsetLeft = $el.offsetLeft

	while (($parent = $parent.offsetParent as HTMLElement | null) && $parent !== $scrollParent) {
		offsetLeft += $parent.offsetLeft
		offsetTop += $parent.offsetTop
	}

	return { x: offsetLeft, y: offsetTop }
}
