import mapValues from 'lodash/mapValues'
import type { LatLng, RawGeofenceLatLon } from './LatLng'
import { toLatLng } from './LatLng'

export type GeofenceTrigger = 'entry' | 'exit'
export type GeofenceType = 'circle'

/**
 * Raw geofences info object that we get from the server, mapping id -> RawGeofence
 * This is what is returned by calling GET /geofences
 *
 * @example
 * Below is an example of the model
 *
 * {
 *   "257a3be9-534c-4d34-b23e-a7cb2c4f6f00": {
 *     "active": false,
 *     "name": 'School',
 *     "lat": 22.34,
 *     "lon": -27.211,
 *     "radius": 100,
 *     "type": "circle",
 *     "ts": 1513210536992,
 *     "triggers": ['entry'],
 *     "users": ['ca6a2889-f138-47ba-a5c1-2001438e8551']
 *   }
 * }
 *
 * @see RawGeofence
 */
export type RawGeofenceInfo = {
	readonly [geofenceId: string]: RawGeofence
}

/**
 * Base Geofence object from which raw and decorated inherit
 * @see Geofence
 * @see RawGeofence
 */
export type GeofenceBase = {
	readonly active: boolean
	readonly address: Maybe<string>
	readonly name: Maybe<string> // e.g. 'School'
	readonly radius: number // in meters
	readonly type: GeofenceType
	readonly ts: Maybe<number>
	readonly triggers: GeofenceTrigger[]
	readonly users: string[]
}

/**
 * Raw geofence object we get from the server
 * @see GeofenceBase
 */
export type RawGeofence = GeofenceBase & {
	readonly lat: number
	readonly lon: number
}

////////////////////////////////
// DECORATED Geofence Objects //
////////////////////////////////

/**
 * Map of geofence id -> geofence
 * @see Geofence
 */
export type GeofenceInfo = {
	readonly [geofenceId: string]: Geofence
}

/**
 * Decorated geofence object that we use. Radius normalized to number
 * and lat / lng instead of RawLatLon
 * @see GeofenceBase
 */
export type Geofence = MOmit<GeofenceBase & LatLng, 'address' | 'name'> & {
	readonly address: string
	readonly name: string // e.g. 'School'
}

export const toGeofenceLatLonRaw = ({ lat, lng }: LatLng): RawGeofenceLatLon => ({
	lat,
	lon: lng,
})

/** Changes decorated back to raw geofences */
export const mapGeofenceInfoToRaw = (geofencesInfo: GeofenceInfo): RawGeofenceInfo =>
	mapValues(geofencesInfo, (geofence: Geofence) => {
		const { lat, lon } = toGeofenceLatLonRaw(geofence)
		const rawGeofence = { ...geofence, lat, lon }
		delete (rawGeofence as any).lng

		return rawGeofence
	})

/** Maps raw geofences to decorated */
export const mapRawToGeofenceInfo = (rawGeofencesInfo: RawGeofenceInfo): GeofenceInfo =>
	mapValues(rawGeofencesInfo, (rawGeofence: RawGeofence) => ({
		...rawGeofence,
		...toLatLng(rawGeofence),
		address: rawGeofence.address || '',
		name: rawGeofence.name || '',
	}))

export const MAX_ACCOUNT_GEOFENCES = 100
export const MAX_USER_GEOFENCES = 15
