import { setPublicKey } from 'node-forge/rsa'
import type { PrivateKey } from './re-exports'

/**
 * Wrapped `setPublicKey` for abstraction. Returns the public key from the
 * private key
 *
 * @param privateKey The private key used to derive the public key
 */
export const publicFromPrivateKey = (privateKey: PrivateKey) =>
	setPublicKey(privateKey.n, privateKey.e)
