import { useFn } from '@eturi/react'
import { activeChildId$, children$ } from '@op/services'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { getManageLink } from '../../compound-selectors/app-misc'
import { manageFeature$ } from '../../reducers/app-misc.slice'

/**
 * This component renders the child list, and manages the links based on the
 * active feature / route.
 */
export const NavChildList = () => {
	const activeChildId = useSelector(activeChildId$)
	const feature = useSelector(manageFeature$)
	const users = useSelector(children$)
	const { pathname } = useLocation()
	const history = useHistory()

	const isGalleryLink = pathname.includes('gallery')

	const handleSelect = useFn((userId: string) => {
		history.push(isGalleryLink ? `/gallery/${userId}` : getManageLink(feature, userId))
	})

	return (
		<select onChange={(ev) => handleSelect(ev.target.value)} value={activeChildId || ''}>
			{users.map((u) => (
				<option key={u.user_id} value={u.user_id}>
					{u.user_name}
				</option>
			))}
		</select>
	)
}
