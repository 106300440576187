import { usePrevious } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { sentryError } from '@eturi/sentry'
import type { Lang } from '@op/services'
import moment from 'moment-timezone'
import { memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { currentLang$ } from '../compound-selectors/language'
import { LastLang } from '../i18n/LastLang'
import { momentLocale$, setMomentLocale } from '../reducers/app-misc.slice'
import { useAppDispatch } from '../types'

const LocaleWatcher = () => {
	const d = useAppDispatch()
	const [, i18n] = useT()
	const currentLang = useSelector(currentLang$)
	const momentLocale = useSelector(momentLocale$)
	const prevLang = usePrevious(currentLang) || LastLang.get()

	const shouldChangeI18nLang = currentLang !== prevLang
	const shouldChangeMomentLocale = currentLang !== momentLocale

	// Make sure to update language when it changes
	useEffect(() => {
		if (shouldChangeI18nLang) {
			LastLang.set(currentLang)
			i18n.changeLanguage(currentLang)
		}
	}, [shouldChangeI18nLang])

	useEffect(() => {
		// Moment and current lang are the same, so we don't need to update
		if (!shouldChangeMomentLocale) return

		// Try to set the locale.
		moment.locale(currentLang)

		// If the locale has been set, we can just return, but if we haven't loaded
		// that locale yet, then trying to set it will just revert to the another
		// locale that we have set, and we'll need to fetch it.
		if (currentLang === moment.locale()) {
			d(setMomentLocale(currentLang))
			return
		}

		// Closure reference to the locale we're fetching, so we can check for a
		// race condition. This might occur if we change locales again before the
		// original request is finished, and the first request takes longer than
		// the next request.
		const fetchedLocale = currentLang

		importLocale(currentLang)
			?.then(() => {
				if (fetchedLocale !== currentLang) return
				d(setMomentLocale(moment.locale(currentLang) as Lang))
			})
			.catch((e) => {
				sentryError(e, `Error fetching ${currentLang}`)
			})
	}, [shouldChangeMomentLocale])

	return null
}

export default /*@__PURE__*/ memo(LocaleWatcher)

//eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const importLocale = (lng: Lang | string) => {
	switch (lng) {
		case 'de':
			return import('moment/locale/de')

		case 'es':
			return import('moment/locale/es')

		case 'fr':
			return import('moment/locale/fr')

		case 'nl':
			return import('moment/locale/nl')

		case 'pt-br':
			return import('moment/locale/pt-br')

		case 'zh-cn':
			return import('moment/locale/zh-cn')

		case 'zh-tw':
			return import('moment/locale/zh-tw')
	}
}
