/**
 */

const DEFAULT_MIN_VERSION = 4.2

export const FeatureMinAppVersion = {
	ALLOWANCE: DEFAULT_MIN_VERSION,
	APP_RULES: DEFAULT_MIN_VERSION,
	GEO: DEFAULT_MIN_VERSION,
	RESTRIX: DEFAULT_MIN_VERSION,
	RESTRIX_ANDROID: 6.1,
	RESTRIX_NETWORK_LOCK: 6.0,
	VEW_ANDROID: 6.0,
	VEW_IOS: 8.1,
}

export const FeatureMinOSVersion = {
	ANDROID_AD_TRACKING: 10.0,
	ANDROID_DISABLE_AIRPLANE: 9.0,
	ANDROID_NETWORK_LOCK: 7.0,
	VEW_ANDROID: 7.0,
	VEW_IOS: 12.0,
}
