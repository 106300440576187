import { lazy } from 'react'

export const AndroidNotifs = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'android-notifs' */
			/* webpackPrefetch: 100 */
			'./AndroidNotifs'
		),
)
