import { float } from '@eturi/util'

/** This is the equivalent of Google Maps LatLngLiteral  */
export type LatLng = {
	readonly lat: number
	readonly lng: number
}

/**
 * Raw lat / lon sent from the server
 * Gets converted to LatLngLiteral
 */
export type RawLatLon = {
	readonly lat: number | string
	readonly lon: number | string
}

export type RawGeofenceLatLon = {
	readonly lat: number
	readonly lon: number
}

/** Normalizes the lat / lng to fix floating point precision issues */
export const normalizeLatLng = (v: LatLng): LatLng => ({
	lat: float(v.lat),
	lng: float(v.lng),
})

export const toRawLatLon = (v: LatLng): RawLatLon => ({
	lat: v.lat,
	lon: v.lng,
})

/**
 * Takes any type satisfying RawLatLon and converts it to a LatLngLiteral
 */
export const toLatLng = (v: RawLatLon): LatLng => {
	const lat = Number.parseFloat(v.lat)
	const lng = Number.parseFloat(v.lon)

	if (Number.isNaN(lat + lng)) {
		throw new TypeError(`Expected float values, instead got ${lat}, ${lng}`)
	}

	return { lat, lng }
}
