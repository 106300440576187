import { selectUnaryEvery } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import { isTrialEligible$, purchaseInfo$ } from '../reducers'
import { intToFloatPrice, toPriceStr } from '../types'
import { isPaidSub$, nextSKU$ } from './access'

export const nextSKUPriceStr$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	purchaseInfo$,
	({ price_usd }, purchaseInfo) =>
		toPriceStr(intToFloatPrice(purchaseInfo?.price_next_uscent || price_usd)),
)

export const shouldSetNotTrialEligible$ = /*@__PURE__*/ createSelector(
	isPaidSub$,
	isTrialEligible$,
	selectUnaryEvery,
)
