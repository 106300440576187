import { selectUnaryEvery } from '@eturi/util'
import type { PaidTier, SubCycle } from '@op/services'
import { canCancelSub$, canDowngradeSub$, resetAction } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { createSliceTransformer, stripPayload } from 'rtk-slice-transformer'
import { createAsyncThunk } from '../createAsyncThunk'
import type { CycleAndTier, ProductModalType } from '../types'

// export const sanitizeProductActions = (action: Actions) =>
// 	action.type === SET_GOOGLE_FETCH_TS ? stripPayload(action) : action

export type ProductState = {
	readonly googleFetchTS: number
	readonly hasCheckedIAP: boolean
	readonly isUISelectedAnnual: boolean
	readonly modalType: Maybe<ProductModalType>
	readonly subscribingCycle: Maybe<SubCycle>
	readonly subscribingTier: Maybe<PaidTier>
}

export type WithProductState = {
	readonly product: ProductState
}

const initialState: ProductState = {
	googleFetchTS: 0,
	hasCheckedIAP: false,
	isUISelectedAnnual: false,
	modalType: null,
	subscribingCycle: null,
	subscribingTier: null,
}

export const productSlice = /*@__PURE__*/ createSlice({
	name: 'product',
	initialState,
	reducers: {
		// NOTE: Used by thunks, but not directly by consumers
		_setModalType(s, a: PayloadAction<Maybe<ProductModalType>>) {
			s.modalType = a.payload
		},

		clearSubscribingState(s) {
			s.subscribingCycle = s.subscribingTier = null
		},

		setGoogleFetchTs(s) {
			s.googleFetchTS = Date.now()
		},

		setIAPChecked(s, a: PayloadAction<boolean>) {
			s.hasCheckedIAP = a.payload
		},

		setSubscribingState(s, { payload: { cycle, tier } }: PayloadAction<CycleAndTier>) {
			s.subscribingCycle = cycle
			s.subscribingTier = tier
		},

		setUISelectedAnnual(s, a: PayloadAction<boolean>) {
			s.isUISelectedAnnual = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(showCancellationModal.fulfilled, (s) => {
				s.modalType = 'cancel'
			})
			.addCase(showDowngradeModal.fulfilled, (s, a) => {
				const { cycle, tier } = a.meta.arg
				s.modalType = 'downgrade'
				s.subscribingCycle = cycle
				s.subscribingTier = tier
			})
			.addMatcher(
				isAnyOf(
					closeProductModal.fulfilled,
					showExternalPaymentModal.fulfilled,
					showUpdatePaymentModal.fulfilled,
				),
				(s, a) => {
					s.modalType = a.payload
				},
			),
})

export const {
	clearSubscribingState,
	setGoogleFetchTs,
	setIAPChecked,
	setSubscribingState,
	setUISelectedAnnual,
} = productSlice.actions

export const productSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	productSlice,
	(s: ProductState) => ({
		...s,
		googleFetchTS: Boolean(s.googleFetchTS),
	}),
	(a) => (setGoogleFetchTs.match(a) ? stripPayload(a) : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

const _showProductModalActionCreator = (type: Maybe<ProductModalType>) =>
	createAsyncThunk(`product/showModalType/${type || 'hide'}`, (_: void) => type)

export const closeProductModal = /*@__PURE__*/ _showProductModalActionCreator(null)

const voidFn = (_: void) => {
	/**/
}

export const showCancellationModal = /*@__PURE__*/ createAsyncThunk(
	'product/showCancellationModal',
	voidFn,
	{
		condition: (_, api) => {
			if (!canCancelSub$(api.getState())) return false
		},
	},
)

export const showDowngradeModal = /*@__PURE__*/ createAsyncThunk(
	'product/showDowngradeModal',
	(_: CycleAndTier) => {
		/**/
	},
	{
		condition: (_, api) => {
			if (!canDowngradeSub$(api.getState())) return false
		},
	},
)

export const showExternalPaymentModal = /*@__PURE__*/ _showProductModalActionCreator('external')
export const showUpdatePaymentModal = /*@__PURE__*/ _showProductModalActionCreator('update_pmt')

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithProductState>(s: T) => s.product
const productModalType$ = /*@__PURE__*/ createSelector(state$, (s) => s.modalType)

export const googleFetchTS$ = /*@__PURE__*/ createSelector(state$, (s) => s.googleFetchTS)
export const hasCheckedIAP$ = /*@__PURE__*/ createSelector(state$, (s) => s.hasCheckedIAP)
export const isUISelectedAnnual$ = /*@__PURE__*/ createSelector(state$, (s) => s.isUISelectedAnnual)
export const subscribingCycle$ = /*@__PURE__*/ createSelector(state$, (s) => s.subscribingCycle)
export const subscribingTier$ = /*@__PURE__*/ createSelector(state$, (s) => s.subscribingTier)
export const hasSubscribingTier$ = /*@__PURE__*/ createSelector(subscribingTier$, Boolean)
export const isSubscribingCycleAnnual$ = /*@__PURE__*/ createSelector(
	subscribingCycle$,
	(s) => s === 'annual',
)

const _isProductModalTypeSelector = (type: ProductModalType) =>
	createSelector(productModalType$, (t) => t === type)

const _isSubscribingTierSelector = (tier: PaidTier) =>
	createSelector(subscribingTier$, (t) => t === tier)

export const isCancelling$ = /*@__PURE__*/ _isProductModalTypeSelector('cancel')

export const isDowngrading$ = /*@__PURE__*/ createSelector(
	/*@__PURE__*/ _isSubscribingTierSelector('premium'),
	/*@__PURE__*/ _isProductModalTypeSelector('downgrade'),
	selectUnaryEvery,
)

export const isExternalVisible$ = /*@__PURE__*/ _isProductModalTypeSelector('external')
export const isGoogleIAPVisible$ = /*@__PURE__*/ _isProductModalTypeSelector('google_iap')

export const isPurchasing$ = /*@__PURE__*/ createSelector(
	hasSubscribingTier$,
	/*@__PURE__*/ _isProductModalTypeSelector('purchase'),
	selectUnaryEvery,
)

export const isUpdatingPayment$ = /*@__PURE__*/ _isProductModalTypeSelector('update_pmt')
export const isProductModalVisible$ = /*@__PURE__*/ createSelector(productModalType$, Boolean)
