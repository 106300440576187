import { useFn } from '@eturi/react'
import type { FieldProps, UseFieldConfig } from 'react-final-form'
import { Field } from 'react-final-form'
import type { RadioCheckType } from '../../types'

// This is a complicated type made even more complicated by the fact that
// `Omit` isn't working as a generic intersection here. So instead of just
// omitting the `RenderableProps` from `FieldProps<T, HTMLInputElement>` and
// then picking the required prop `value`, I have to instead union with
// `UseFieldConfig<T>`. Both then define the render function which is the same
// as the render function of `FieldProps`, but is used as `children`.
// `render` is used as `children` because `render` doesn't work correctly in
// <Field/>
type RadioCheckFieldProps<T> = UseFieldConfig<T> &
	Required<Pick<UseFieldConfig<T>, 'value'>> &
	Pick<FieldProps<T, any, HTMLInputElement>, 'name'> & { readonly render: RenderRadioCheck<T> }

type RenderRadioCheck<T> = Required<Pick<FieldProps<T, any, HTMLInputElement>, 'render'>>['render']

const radioCheckFieldFactory = (type: RadioCheckType, displayName: string) => {
	const RadioCheckField = <T,>({ render, ...p }: RadioCheckFieldProps<T>) => (
		<Field {...p} type={type}>
			{useFn(render)}
		</Field>
	)

	RadioCheckField.displayName = displayName

	return RadioCheckField
}

export const CheckField = radioCheckFieldFactory('checkbox', 'CheckField')
export const RadioField = radioCheckFieldFactory('radio', 'RadioField')
