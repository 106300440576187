import type { PartialRawRule, RawRule, Rule, WithRuleType } from './Rule'

export type OverrideActionType = 'add_time' | 'until_midnight'

export type OverrideType = 'allow' | 'block'
export type OverrideRuleType<T extends PartialRawRule> = T & WithRuleType<'override'>

export type Override = Rule & {
	readonly override: OverrideType
	readonly time_duration: string
}

export const isOverrideAllow = <T extends PartialRawRule>(r: T): r is OverrideRuleType<T> =>
	r.type === 'override' &&
	(r.system_name === 'allow' ||
		r.rule_name === 'allow' ||
		(r.system_name === 'restrict' && r.rule_name === 'default'))

export const isOverrideBlock = <T extends PartialRawRule>(r: T): r is OverrideRuleType<T> =>
	r.type === 'override' &&
	(r.system_name === 'block' ||
		r.rule_name === 'block' ||
		(r.system_name === 'restrict' && r.rule_name === 'schedule'))

export const isOverrideRule = <T extends PartialRawRule>(r: Maybe<T>): r is OverrideRuleType<T> =>
	!!r && (isOverrideAllow(r) || isOverrideBlock(r))

export const isOverrideTypeAllow = (t: Maybe<OverrideType>): t is 'allow' => t === 'allow'
export const isOverrideTypeBlock = (t: Maybe<OverrideType>): t is 'block' => t === 'block'

export const mapRawToOverride = (r: OverrideRuleType<RawRule>): Override => ({
	...r,
	override: isOverrideAllow(r) ? 'allow' : 'block',
	suspended: !!r.suspended,
	time_duration: r.time_duration || 'PT0M',
})

/** Special override durations */
export type OverrideDuration = ValueUnion<typeof OverrideDuration>
export const OverrideDuration = {
	MIDNIGHT: -1,
	UNTIL_I_SAY_SO: 0,
} as const
