import { useConstant, useFn, useRefGetter, useWindowEvent } from '@eturi/react'
import { mod } from '@eturi/util'
import { isArrowDown, isArrowLeft, isArrowRight, isArrowUp } from '@op/react-web'
import { nullFn } from '@op/util'
import cls from 'classnames'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import noop from 'lodash/noop'
import sortBy from 'lodash/sortBy'
import without from 'lodash/without'
import { createContext, useEffect } from 'react'
import { Field } from 'react-final-form'
import type { RadioCheckGroupProps } from '../../types'

export type RadioGroupCtx = {
	// Passing `tabIdx` allows custom ordering. All actual element `tabIndex`
	// values are managed as either 0 or -1 as per HTML recommendation.
	readonly add: ($el: HTMLElement, tabIdx?: number) => void
	readonly remove: ($el: HTMLElement) => void
}

export const RadioGroupContext = createContext<RadioGroupCtx>({
	add: noop,
	remove: noop,
})

type RadioGroupState = {
	readonly $el: HTMLElement
	readonly dispose: () => void
	tabIdx: number
}

const NO_PASSIVE = { passive: false }

/**
 * Creates a radio group that provides accessibility keyboard control similar to
 * native input radio controls.
 *
 * NOTE: This currently doesn't handle disabled inputs (which remain tab
 *  focusable). If we want to do so we'll have to get that info from the
 *  `Radio`. We could then check this when focus occurs and also set tabIndex
 *  to -1.
 */
export const RadioGroup = <T,>({
	children,
	className,
	name,
	isStacked = false,
	labelledBy,
	validate,
}: RadioCheckGroupProps<T>) => {
	const [getFocusedElement, setFocusedElement] = useRefGetter<Maybe<HTMLElement>>(null)
	const [getRadioGroupStates, setRadioGroupStates] = useRefGetter<RadioGroupState[]>([])
	const totalStates = () => getRadioGroupStates().length

	const add = useFn(($el: HTMLElement, tabIdx = 0) => {
		const groupElementStates = getRadioGroupStates()
		const prevState = find(groupElementStates, { $el })

		if (prevState) {
			if (prevState.tabIdx !== tabIdx) {
				prevState.tabIdx = tabIdx
				setRadioGroupStates(sortBy(groupElementStates, 'tabIdx'))
			}

			return
		}

		const onBlur = () => $el === getFocusedElement() && setFocusedElement(null)
		const onFocus = () => setFocusedElement($el)

		$el.addEventListener('blur', onBlur, NO_PASSIVE)
		$el.addEventListener('focus', onFocus, NO_PASSIVE)

		const dispose = () => {
			$el.removeEventListener('blur', onBlur)
			$el.removeEventListener('focus', onFocus)
		}

		// Add new state and sort by tabIdx
		setRadioGroupStates(sortBy([...groupElementStates, { $el, dispose, tabIdx }], 'tabIdx'))
	})

	const remove = useFn(($el: HTMLElement) => {
		const groupElementStates = getRadioGroupStates()
		const state = find(groupElementStates, { $el })

		if (!state) return

		state.dispose()
		setRadioGroupStates(without(groupElementStates, state))
	})

	useEffect(
		() => () => {
			getRadioGroupStates().forEach((s) => s.dispose())
			setRadioGroupStates([])
		},
		[],
	)

	useEffect(() => {
		const states = getRadioGroupStates()

		// If we have states, make sure at least one of them can be focused. This
		//  can happen if no radios are selected by default, or if the selected
		//  radio is removed.
		if (totalStates() && !states.some((s) => s.$el.tabIndex > -1)) {
			states[0].$el.tabIndex = 0
		}
	})

	const shiftFocus = (shift: number) => {
		const focusedEl = getFocusedElement()!
		const groupElementStates = getRadioGroupStates()
		const focusedIdx = findIndex(groupElementStates, { $el: focusedEl })
		const nextFocusedIdx = mod(focusedIdx + shift, totalStates())
		const nextFocusedEl = groupElementStates[nextFocusedIdx]?.$el

		if (nextFocusedEl) {
			nextFocusedEl.click()
			nextFocusedEl.focus()
		}
	}

	useWindowEvent(
		'keydown',
		(ev) => {
			if (!(getFocusedElement() && totalStates() > 1)) return

			if (isArrowDown(ev) || isArrowRight(ev)) {
				shiftFocus(1)
			} else if (isArrowUp(ev) || isArrowLeft(ev)) {
				shiftFocus(-1)
			} else {
				return
			}

			ev.preventDefault()
		},
		NO_PASSIVE,
	)

	const value = useConstant(() => ({ add, remove }))

	return (
		<RadioGroupContext.Provider value={value}>
			<div
				aria-labelledby={labelledBy}
				className={cls(
					'radio-group radio-check-group',
					className,
					isStacked && 'radio-check-group--is-stacked',
				)}
				role="radiogroup"
			>
				{children}
				{name && validate && <Field name={name} render={nullFn} validate={validate} />}
			</div>
		</RadioGroupContext.Provider>
	)
}
