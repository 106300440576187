import { unaryTrim } from '@op/util'
import type { Argument } from 'classnames'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { HTMLAttributes } from 'react'

export type DisabledProps = Pick<
	HTMLAttributes<any>,
	'aria-disabled' | 'className' | 'onClick' | 'tabIndex'
>
export const disabledProps = (disabled: boolean, ...classNames: Argument[]): DisabledProps => {
	const p: DisabledProps = { className: cls(classNames, disabled && 'disabled') }

	if (disabled) {
		p['aria-disabled'] = disabled
		p.tabIndex = -1
		p.onClick = noop
	}

	return p
}

// We format on Blur because otherwise it will format on every letter input this
// will not allow the user to add a space which might be desired if two words
// are being entered.
export const TRIM_INPUT = {
	format: unaryTrim,
	formatOnBlur: true,
}

const condense = (value: Maybe<string>): string => unaryTrim(value || '').replace(/\s+/g, '')

export const CONDENSE_INPUT = {
	format: condense,
	formatOnBlur: true,
}
