import { float } from '@eturi/util'
import clamp from 'lodash/clamp'
import { memo } from 'react'
import Colors from '../../styles/colors.module.scss'

type DeviceBatteryProps = {
	// between 0 and 1 (0.2 and below will result in red battery indicator)
	readonly battery: number
	readonly className?: string
}

const FULL_BATTERY_X2 = 33.992
const BATTERY_Y2 = 30.12

const DeviceBattery = ({ battery, className }: DeviceBatteryProps) => {
	// We normalize the lower end to 0.2, because that's the lowest res where rendering
	// the inner battery fill actually still looks okay.
	const normalizedBatteryLife = clamp(battery, 0.2, 1)
	// This is the radius of the top and bottom right of the inner batter fill. At full
	// width, we use the same radius on all corners, otherwise we use a smaller one.
	const radius = normalizedBatteryLife === 1 ? 4 : 1
	// The SVG is rendered assuming a radius of 4. If we use a lower radius we need to
	// add the difference to the path drawing, so it outputs the correct dimensions.
	const radiusCorrection = 4 - radius
	// Float normalization b/c of addition causing float imprecision
	const x2 = float(FULL_BATTERY_X2 * normalizedBatteryLife + radiusCorrection)
	const y2 = float(BATTERY_Y2 + radiusCorrection)

	return (
		<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
			<path d="M35.07 13.44c2.85.01 5.15 2.31 5.15 5.15v12.82a5.15 5.15 0 0 1-5.15 5.15H9.45a5.16 5.16 0 0 1-5.16-5.15V18.59a5.16 5.16 0 0 1 5.16-5.15Zm0-4H9.45c-5.06 0-9.16 4.1-9.16 9.15v12.82a9.16 9.16 0 0 0 9.16 9.15h25.62c5.05 0 9.15-4.1 9.15-9.15V18.59c0-5.05-4.1-9.15-9.15-9.15Zm10.98 10.07a3.66 3.66 0 0 1 3.66 3.66v3.66a3.66 3.66 0 0 1-3.66 3.66V19.51Z" />
			<path
				fill={battery <= 0.2 ? Colors.RED : Colors.BLACK}
				d={`
    M 10.535 15.88
    H ${x2}
    q ${radius} 0 ${radius} ${radius}
    V ${y2}
    q 0 ${radius} -${radius} ${radius}
    H 10.535
    q -4 0 -4 -4
    V 19.88
    q 0 -4 4 -4
    Z`}
			/>
		</svg>
	)
}

export default /*@__PURE__*/ memo(DeviceBattery)
