import type { CssGlyphName } from '@op/icons'
import cls from 'classnames'

type GlyphSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

type GlyphProps = {
	readonly className?: string
	readonly name: CssGlyphName
	readonly size?: GlyphSize
}

const SizeMap = {
	sm: 'icon-sm',
	md: 'icon-md',
	lg: 'icon-lg',
	xl: 'icon-xl',
	'2xl': 'icon-2xl',
} satisfies { [P in GlyphSize]: string }

export const Glyph = ({ className, name, size = 'md' }: GlyphProps) => (
	<i className={cls(`pakicon-${name}`, SizeMap[size], className)} />
)
