import type { ViewBlockState } from '@op/services'
import Colors from '../../styles/colors.module.scss'

export const useStatusTextColor = (viewState: ViewBlockState) => {
	switch (viewState) {
		case 'blocked':
			return Colors.RED

		case 'allowed':
			return Colors.GREEN

		default:
			return Colors.TEXT
	}
}
