export const ZModal = 1000
export const ZAppContent = 2
export const ZTopNav = 100

// Derived
export const ZAboveOverlay = ZModal - 1
export const ZOverlay = ZAboveOverlay - 1
export const ZOverlayAppSibling = ZAppContent - 1
export const ZStickies = ZModal + 1
export const ZMobileMenu = ZStickies + 1
