export type KeysToBool<T, K extends keyof T> = { [P in K]: boolean }

export const keysToBool = <T, K extends keyof T>(
	obj: Maybe<T>,
	keys: readonly K[],
): KeysToBool<T, K> => {
	const newObj: any = {}

	for (const key of keys) {
		newObj[key] = Boolean(obj?.[key])
	}

	return newObj
}
