import { CAPTURE, useWindowEvent } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { sentryError } from '@eturi/sentry'
import { memo, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setRedirect } from '../reducers/app-misc.slice'
import { isNetworkOnline$ } from '../reducers/browser-info.slice'
import { hasHttpRetryError$, isHttpRetrying$, resetHttpRetries } from '../reducers/http-retry.slice'
import { useToasts } from './Toast'

// FIXME: Use http onError to set retry failure. As far as "preventing interaction".
//  We'll need to hook into the retry process.
const ConnectivityHandler = () => {
	const [t] = useT()
	const d = useDispatch()
	const h = useHistory()
	const l = useLocation()
	const isOnline = useSelector(isNetworkOnline$)
	const isHttpRetrying = useSelector(isHttpRetrying$)
	const hasHttpRetryError = useSelector(hasHttpRetryError$)
	const { addErrorToast, removeToast } = useToasts()
	const offlineToastId = useRef('')
	const retryingToastId = useRef('')
	const shouldPreventInteraction = !isOnline || isHttpRetrying

	// Prevent app interactions when we're offline or are retrying a failed
	// request. This prevents additional retry requests to build up, and also
	// prevents the app from getting into odd states caused by failed requests.
	const preventInteraction = (ev: Event) => {
		ev.preventDefault()
		ev.stopImmediatePropagation()
	}

	useWindowEvent('click', preventInteraction, CAPTURE, shouldPreventInteraction)
	useWindowEvent('pointerdown', preventInteraction, CAPTURE, shouldPreventInteraction)

	// If we're offline, we show a specific error toast
	useEffect(() => {
		if (isOnline) return removeToast(offlineToastId.current)

		offlineToastId.current = addErrorToast({
			msg: t('error_strings.network_offline_error'),
			persistent: true,
		})
	}, [isOnline])

	// If we're retrying a failed request, we show a specific error toast
	useEffect(() => {
		if (!isHttpRetrying) return removeToast(retryingToastId.current)

		retryingToastId.current = addErrorToast({
			msg: t('error_strings.retry_error'),
			persistent: true,
		})
	}, [isHttpRetrying])

	// If we've reached a retry failure state on any request (which occurs when
	// the maximum number of retries have been attempted), we clear the retry
	// state and redirect to the error page. Clearing the retry state will cause
	// any outstanding HTTP requests to be cancelled (they will throw), and will
	// empty any HTTP debounce cache so new requests can come in.
	useEffect(() => {
		if (!hasHttpRetryError) return

		d(resetHttpRetries())

		// We set the redirect so that we know what page we are coming from once
		// connection is restored
		d(setRedirect(l.pathname))

		sentryError(new Error('Http retry failed'))

		h.push('/error')
	}, [hasHttpRetryError])

	return null
}

export default /*@__PURE__*/ memo(ConnectivityHandler)
