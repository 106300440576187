import {
	activeUserHasAllowanceCapableDevice$,
	activeUserHasAppRulesCapableDevice$,
	activeUserHasRestrixCapableDevice$,
	activeUserHasSupervisedVewCapableIOSDevice$,
	activeUserHasVewCapableDevices$,
	fetchAccessState,
	fetchBlockStatuses,
	fetchDevices,
	fetchUsers,
	hasChildren$,
	hasMaxDevices$,
	isActiveUserManaged$,
	isMinPremAccess$,
	unwrapThunks,
} from '@op/services'
import type { RootState } from '../reducers'
import { setGuardModalType } from '../reducers/app-misc.slice'
import type { OPThunk, OPThunkP } from '../types'
import type { GuardModalType } from '../types/GuardModalType'

export const canActivateAddChildAction = (): OPThunkP<boolean> => async (dispatch, getState) => {
	// We need to make sure we have initialized devices
	await Promise.all(
		unwrapThunks([
			dispatch(fetchAccessState()),
			dispatch(fetchBlockStatuses()),
			dispatch(fetchDevices()),
			dispatch(fetchUsers()),
		]),
	)

	const state = getState()

	// If we have children and have max devices
	if (hasChildren$(state) && hasMaxDevices$(state)) {
		dispatch(setGuardModalType(isMinPremAccess$(state) ? 'max_devices_contact' : 'max_devices'))

		return false
	}

	return true
}

// Takes in a selector for whether we have a supported device for the feature,
// and the guard modal to display if we don't, and returns a thunk that handles
// the canActivate for the feature. Displays the modal if we don't have a
// capable device but the active user is managed. Returns whether we can activate.
const canActivateFeatureFactory =
	(hasCapableDeviceSelector$: (s: RootState) => boolean, guardModalType: GuardModalType) =>
	(): OPThunk<boolean> =>
	(dispatch, getState) => {
		const state = getState()

		if (!isMinPremAccess$(state)) return true

		const hasCapableDevice = hasCapableDeviceSelector$(state)
		const isManagedChild = isActiveUserManaged$(state)

		// If user is managed then we show modal but doesn't have capable device
		if (!hasCapableDevice && isManagedChild) {
			dispatch(setGuardModalType(guardModalType))

			return false
		}

		return hasCapableDevice && isManagedChild
	}

export const canActivateAllowanceAction = /*@__PURE__*/ canActivateFeatureFactory(
	activeUserHasAllowanceCapableDevice$,
	'can_activate_allowance',
)

export const canActivateAppRulesAction = /*@__PURE__*/ canActivateFeatureFactory(
	activeUserHasAppRulesCapableDevice$,
	'can_activate_app_rules',
)

export const canActivateRestrixAction = /*@__PURE__*/ canActivateFeatureFactory(
	activeUserHasRestrixCapableDevice$,
	'can_activate_restrix',
)

export const canActivateVewNowAction = (): OPThunk<boolean> => (dispatch, getState) => {
	const state = getState()

	// If the user has at least one iOS device with supported OS version, that is
	// supervised, we show the overlay, regardless of whether they have the correct
	// min app version. This is b/c, if they are supervised and have the correct
	// OS version to support Vew, we can just trigger an app install, that will
	// push down the latest version of the child app, and this is done from the
	// overlay button.
	if (activeUserHasSupervisedVewCapableIOSDevice$(state)) return true

	const hasVewCapableDevices = activeUserHasVewCapableDevices$(state)

	if (!hasVewCapableDevices) dispatch(setGuardModalType('can_activate_vew'))

	return hasVewCapableDevices
}
