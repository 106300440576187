import { useT } from '@eturi/react-i18next'
import LOGO from '@op/assets/logos/logo.svg'
import type { CssGlyphName } from '@op/icons'
import { useHandleSynPrevent } from '@op/react-web'
import { isAuthenticated$ } from '@op/services'
import cls from 'classnames'
import type { MouseEventHandler, ReactNode } from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { baseLink$ } from '../../compound-selectors/app-misc'
import { SUPPORT_LINK } from '../../constants/links'
import { ZTopNav } from '../../constants/z-index'
import { desktopMenuWidthLG, desktopMenuWidthMin, desktopMenuWidthXL } from '../../css-variables'
import { useIsScreenXL } from '../../hooks'
import { createStyles } from '../../styles/createStyles'
import { canActivateAddChildAction } from '../../thunks/can-activate'
import { useAppDispatch } from '../../types'
import { Glyph } from '../../widgets/Glyph'
import { ActionBtn } from '../Buttons'
import { OPLink } from '../OPLink'
import { Overlay } from '../Overlay'
import { NAV_TRANSITION_MS } from './constants'
import { useNav } from './useNav'

const DesktopNav = () => {
	const [t] = useT()
	const d = useAppDispatch()
	const history = useHistory()
	const baseLink = useSelector(baseLink$)
	const isScreenXL = useIsScreenXL()
	const nav = useNav()
	const isAuthenticated = useSelector(isAuthenticated$)

	// We use this as a guard for the route, if the user has reached max devices
	// we don't want to route to that page, but we want to show a modal. We use a
	// OPLink as this allows us to keep the activeLink styles without additional
	// logic for styles
	const handleAddChildClick = useHandleSynPrevent(async () => {
		const canActivate = await d(canActivateAddChildAction())

		if (canActivate) {
			history.push('/connect')
		}
	})

	if (!(isAuthenticated && nav.isDesktop)) return null

	const width = nav.isOpen
		? isScreenXL
			? desktopMenuWidthXL
			: desktopMenuWidthLG
		: desktopMenuWidthMin

	return (
		<menu
			className="bg-teal-2hl fixed bottom-0 pt-3 left-0 top-0 overflow-x-hidden"
			style={{ ...styles.menu, width: `${width}px` }}
		>
			<DesktopMenuItem
				activeBg={false}
				icon={(active) => (
					<span className={cls('flex-center pt-0.5 h-14 w-14 rounded-xl', active && 'bg-white')}>
						<img alt="OurPact Logo" className="h-13 w-13" src={LOGO} />
					</span>
				)}
				link={baseLink}
			>
				<span className="text-4xl text-teal" title="OurPact">
					OurPact
				</span>
			</DesktopMenuItem>

			<DesktopMenuItem icon="add-device" link="/connect" onClick={handleAddChildClick}>
				{t('menu_items.add_device')}
			</DesktopMenuItem>

			<DesktopMenuItem icon="people-outline" link="/my-family">
				{t('menu_items.my_family')}
			</DesktopMenuItem>

			<DesktopMenuItem icon="user" link="/account">
				{t('menu_items.account')}
			</DesktopMenuItem>

			<DesktopMenuItem icon="cog" link="/account">
				{t('menu_items.settings')}
			</DesktopMenuItem>

			<DesktopMenuItem icon="help" link={SUPPORT_LINK}>
				{t('menu_items.help')}
			</DesktopMenuItem>

			<DesktopMenuItem icon="power" link="/logout">
				{t('menu_items.logout')}
			</DesktopMenuItem>

			<ActionBtn
				className={cls('absolute bottom-0 right-0', !nav.isOpen && 'rotate-180')}
				invert
				icon="sidemenu"
				onClick={nav.toggle}
				size="lg"
			/>

			<Overlay fixed={false} />
		</menu>
	)
}

export default /*@__PURE__*/ memo(DesktopNav)

type DesktopMenuItemProps = {
	// Whether to handle adding active state / bg. This is true for all items,
	// except for the logo item at the top.
	readonly activeBg?: boolean
	readonly children?: ReactNode
	readonly icon: CssGlyphName | ((active: boolean) => ReactNode)
	readonly link: string
	readonly onClick?: MouseEventHandler<HTMLAnchorElement>
}

const DesktopMenuItem = ({
	activeBg = true,
	children,
	icon,
	link,
	onClick,
}: DesktopMenuItemProps) => (
	<OPLink className="flex h-16 items-center relative" onClick={onClick} to={link}>
		{(isActive) => {
			const applyActive = isActive && activeBg

			return (
				<>
					<span className="flex-center relative p-3 z-10" style={styles.icon}>
						{typeof icon === 'string' ? <Glyph name={icon} size="lg" /> : icon(isActive)}
					</span>

					<span
						className={cls(
							'flex-1 px-0.5 py-2 whitespace-nowrap relative text-lg text-text z-10',
							applyActive && 'font-bold',
						)}
						title={typeof children === 'string' ? children : undefined}
					>
						{children}
					</span>

					{applyActive && (
						<span className="absolute block h-full px-3 py-0.5 w-full">
							<span className="block bg-white h-full rounded-xl w-full" />
						</span>
					)}
				</>
			)
		}}
	</OPLink>
)

const styles = createStyles({
	icon: {
		minWidth: desktopMenuWidthMin,
		width: desktopMenuWidthMin,
	},

	menu: {
		transition: `width ${NAV_TRANSITION_MS}ms linear`,
		zIndex: ZTopNav,
	},
})
