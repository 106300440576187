import { useT } from '@eturi/react-i18next'
import { useHandleSynPrevent } from '@op/react-web'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { NavBar, OPLink } from '../../components'
import { canActivateAddChildAction } from '../../thunks/can-activate'
import { useAppDispatch } from '../../types'
import { Glyph } from '../../widgets/Glyph'

export const MyFamilyNavBar = () => {
	const [t] = useT()
	const d = useAppDispatch()
	const history = useHistory()

	const handleAddChildClick = useHandleSynPrevent(async () => {
		const canActivate = await d(canActivateAddChildAction())
		if (canActivate) {
			history.push('/connect')
		}
	})

	return useMemo(
		() => (
			<NavBar>
				<NavBar.BackBtn />

				<div className="flex-auto lg:hidden" />

				<NavBar.Title>{t('menu_items.my_family')}</NavBar.Title>

				<div className="flex-auto" />

				<OPLink className="action-btn btn-teal-invert" to="/connect" onClick={handleAddChildClick}>
					<Glyph name="plus" />
				</OPLink>
			</NavBar>
		),
		[t],
	)
}
