import { useBoolState, useFn } from '@eturi/react'
import { Trans, useT } from '@eturi/react-i18next'
import type {
	BlockState,
	DeviceBlockStatus,
	DeviceOS,
	ManagedDevice,
	RawManagedUser,
	ViewBlockState,
} from '@op/services'
import type { ChangeEvent } from 'react'
import { useMemo, useState } from 'react'
import { Btn } from '../components/Buttons'
import { DeviceDetailsView } from '../components/Devices'
import { FormLabel } from '../components/Forms'
import { HybridModal, Modal } from '../components/Modal'

export const DeviceTestPage = () => {
	const [t] = useT()
	const [blockState, setBlockState] = useState<BlockState>('allowed')
	const [deviceBlockStatus, setDeviceBlockStatus] = useState<Maybe<DeviceBlockStatus>>(null)
	const [deviceOS, setDeviceOS] = useState<DeviceOS>('iOS')
	const [deviceType, setDeviceType] = useState<string>('phone')
	const [isDetailsModalVisible, showDetailsModal, hideDetailsModal] = useBoolState(false)
	const [selectedDevice, setSelectedDevice] = useState<Maybe<ManagedDevice>>(null)
	const [viewBlockState, setViewBlockState] = useState<ViewBlockState>('allowed')
	const [
		isAndroidRemoveManagementModalVisible,
		showAndroidRemoveManagementModal,
		hideAndroidRemoveManagementModal,
	] = useBoolState(false)

	const mockUser = {
		account_id: 'test-account-id',
		allowance: [7200, 7200, 7200, 7200, 7200, 7200, 7200],
		allowance_enabled: false,
		allowance_state: 'pause',
		birthday: null,
		created: 1585835973552,
		devices: ['5f050a11-41f7-4953-bde4-cf8f04488310'],
		email: null,
		gender: null,
		img_modified: null,
		img_url: null,
		last_activity: 1585940374887,
		locale: null,
		retired: null,
		schedule_ts: 1585836063807,
		settings: {},
		time_zone: 'America/Los_Angeles',
		type: 10,
		user_id: 'test-user-id',
		user_location_active: null,
		user_name: 'Testname',
		view_enabled: true,
	} satisfies RawManagedUser

	const createMockDevice = (os: DeviceOS, make: string, model: string): ManagedDevice => {
		const nowTS = Date.now()

		return {
			account_id: mockUser.account_id,
			app: 'com.eturi.ourpactjr',
			app_version: '6.0.0',
			attributes: null,
			command_state: 'schedule_sync_complete',
			command_state_ts: nowTS,
			created: 1585835973974,
			current_command_id: '1585836063807',
			current_command_ts: 1585940374887,
			delivered_command_id: null,
			delivered_command_ts: null,
			device_id: 'mock-device-id',
			device_location_active: false,
			device_location_ts: 1585835975597,
			display_name: null,
			display_state: 'in-sync',
			displayName: 'Mock Device Model Name',
			error_count: 0,
			fcm_token: null,
			last_activity: 1585940374887,
			last_seen: 1585940374887,
			make,
			management_level: null,
			management_removed: false,
			model,
			model_name: 'Mock Device Model Name',
			notification_count: 0,
			notification_ts: 1585836064162,
			os,
			os_version: '9',
			pair_id: null,
			retired: null,
			schedule_ts: 1585836063807,
			suspended: false,
			system_components: null,
			time_zone: null,
			type: 10,
			udid: null,
			users: ['e0c00ce2-6c98-4928-a8ab-848eb5f4bafd'],
			version: '5875966',
		}
	}

	const createBlockStatus = (status: BlockState, viewState: ViewBlockState): DeviceBlockStatus => {
		return {
			device_id: 'mock-device-id',
			viewState,
			status,
			os: 'iOS',
			vewStatus: '',
		}
	}

	const handleBlockStateChange = useFn((ev: ChangeEvent<HTMLSelectElement>) =>
		setBlockState(ev.target.value as BlockState),
	)
	const handleDeviceTypeChange = useFn((ev: ChangeEvent<HTMLSelectElement>) =>
		setDeviceType(ev.target.value),
	)
	const handleViewBlockStateChange = useFn((ev: ChangeEvent<HTMLSelectElement>) =>
		setViewBlockState(ev.target.value as ViewBlockState),
	)
	const handleOSChange = useFn((ev: ChangeEvent<HTMLSelectElement>) =>
		setDeviceOS(ev.target.value as DeviceOS),
	)

	const handleSubmit = useFn(() => {
		let model = ''
		let make = ''
		if (deviceOS === 'android') {
			make = 'google'
			if (deviceType === 'tablet') {
				model = 'tablet'
			}
		} else if (deviceOS === 'iOS') {
			make = 'apple'
			if (deviceType === 'tablet') {
				model = 'ipad'
			}
		}
		const mockDevice = createMockDevice(deviceOS, make, model)
		const blockStatusObj = createBlockStatus(blockState, viewBlockState)

		setSelectedDevice(mockDevice)
		setDeviceBlockStatus(blockStatusObj)
		showDetailsModal()
	})

	const handleDetailsClose = useFn(() => {
		hideDetailsModal()
		setSelectedDevice(null)
		setDeviceBlockStatus(null)
	})

	const DeviceType = useMemo(
		() =>
			deviceOS !== 'Unknown' && (
				<FormLabel label="Device Form Factor">
					<select className="select" onChange={handleDeviceTypeChange} value={deviceType}>
						<option key="phone" value="phone">
							Phone
						</option>
						<option key="tablet" value="tablet">
							Tablet
						</option>
					</select>
				</FormLabel>
			),
		[deviceOS, deviceType],
	)

	const SelectedDeviceDetails = useMemo(
		() =>
			selectedDevice &&
			deviceBlockStatus && (
				<DeviceDetailsView
					deviceStatus={deviceBlockStatus}
					device={selectedDevice}
					onClose={handleDetailsClose}
					onAndroidRemoveManagement={showAndroidRemoveManagementModal}
					user={mockUser}
				/>
			),
		[selectedDevice, deviceBlockStatus],
	)

	return (
		<div className="is-page-pad-touch w-full">
			<div>
				<FormLabel label="Device Operating System">
					<select className="select" onChange={handleOSChange} value={deviceOS}>
						<DeviceOSOption os="iOS">iOS</DeviceOSOption>
						<DeviceOSOption os="android">Android</DeviceOSOption>
						<DeviceOSOption os="Unknown">Unknown</DeviceOSOption>
					</select>
				</FormLabel>

				{DeviceType}

				<FormLabel label="Block Status">
					<select className="select" onChange={handleViewBlockStateChange} value={viewBlockState}>
						<ViewBlockStateOption state="allowed">Allowed</ViewBlockStateOption>
						<ViewBlockStateOption state="blocked">Blocked</ViewBlockStateOption>
						<ViewBlockStateOption state="paused">Paused</ViewBlockStateOption>
						<ViewBlockStateOption state="pending">Pending</ViewBlockStateOption>
						<ViewBlockStateOption state="unmanaged">Unmanaged</ViewBlockStateOption>
					</select>
				</FormLabel>

				<FormLabel label="Device Access">
					<select className="select" onChange={handleBlockStateChange} value={blockState}>
						<BlockStateOption state="allowed">Allowed</BlockStateOption>
						<BlockStateOption state="allowing">Allowing</BlockStateOption>
						<BlockStateOption state="blocked">Blocked</BlockStateOption>
						<BlockStateOption state="blocking">Blocking</BlockStateOption>
						<BlockStateOption state="management removed">Unmanaged</BlockStateOption>
					</select>
				</FormLabel>

				<div className="modal__bottom">
					<Btn onClick={handleSubmit}>View Device Details</Btn>
				</div>
			</div>

			{isDetailsModalVisible && (
				<HybridModal
					className="device-details-view-modal"
					header={t('devices.device.device_modal_header', { userName: mockUser.user_name })}
					onClose={handleDetailsClose}
				>
					{SelectedDeviceDetails}
				</HybridModal>
			)}

			{isAndroidRemoveManagementModalVisible && (
				<Modal
					onClose={hideAndroidRemoveManagementModal}
					size="info"
					header={t('devices.remove_management.header')}
				>
					<p className="text-center">{t('devices.remove_management.steps')}</p>
					<ol className="list-decimal m-4 mb-0 ml-5 pl-2.5">
						<li>
							<Trans i18nKey="devices.remove_management.step_1" t={t} />
						</li>

						<li>
							<Trans i18nKey="devices.remove_management.step_2" t={t} />
						</li>

						<li>
							<Trans i18nKey="devices.remove_management.step_3" t={t} />
						</li>

						<li>
							<p>{t('devices.remove_management.step_4')}</p>
						</li>

						<div className="modal__bottom">
							<Btn onClick={hideAndroidRemoveManagementModal}>{t('actions.ok')}</Btn>
						</div>
					</ol>
				</Modal>
			)}
		</div>
	)
}

type DeviceOSOptionProps = {
	readonly children: string
	readonly os: DeviceOS
}

const DeviceOSOption = ({ children, os }: DeviceOSOptionProps) => (
	<option value={os}>{children}</option>
)

type BlockStateOptionProps = {
	readonly children: string
	readonly state: BlockState
}

const BlockStateOption = ({ children, state }: BlockStateOptionProps) => (
	<option value={state}>{children}</option>
)

type ViewBlockStateOptionProps = {
	readonly children: string
	readonly state: ViewBlockState
}

const ViewBlockStateOption = ({ children, state }: ViewBlockStateOptionProps) => (
	<option value={state}>{children}</option>
)
