import type { ReactNode } from 'react'
import { Suspense } from 'react'
import { LoadErrorBoundary } from './LoadErrorBoundary'
import { LoadingSpinner } from './LoadingSpinner'

export const LoadComponent = (p: { readonly children?: ReactNode }) => (
	<LoadErrorBoundary>
		<Suspense fallback={<LoadingSpinner className="is-sm" />}>{p.children}</Suspense>
	</LoadErrorBoundary>
)
