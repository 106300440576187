import { useConstant, useFn } from '@eturi/react'
import { v4 } from 'uuid'
import { appMiscSlice } from '../reducers/app-misc.slice'
import { useAppDispatch } from '../types'

export const useFreezeScroll = () => {
	const d = useAppDispatch()
	const id = useConstant(v4)

	return useFn((isFrozen: boolean) => {
		d(
			isFrozen
				? appMiscSlice.actions._addScrollFrozenId(id)
				: appMiscSlice.actions._removeScrollFrozenId(id),
		)
	})
}
