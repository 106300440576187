import { now } from '@eturi/date-util'
import { resetAction } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import identity from 'lodash/identity'
import { createSliceTransformer } from 'rtk-slice-transformer'

export type UsersPollingState = {
	readonly [userId: string]: number
}

export type FastPollState = {
	readonly addChild: number
	readonly blockStatus: UsersPollingState
	readonly devices: number
	readonly location: number
}

export type WithFastPollState = {
	readonly fastPoll: FastPollState
}

const initialState: FastPollState = {
	addChild: 0,
	blockStatus: {},
	devices: 0,
	location: 0,
}

export const fastPollSlice = /*@__PURE__*/ createSlice({
	name: 'fastPoll',
	initialState,
	reducers: {
		cancelFastPollAddChild(s) {
			s.addChild = 0
		},

		cancelFastPollDevices(s) {
			s.devices = 0
		},

		cancelFastPollLocation(s) {
			s.location = 0
		},

		fastPollAddChild(s) {
			s.addChild = +now.add(10, 'm')
		},

		fastPollDevices(s) {
			s.devices = +now.add(5, 'm')
		},

		fastPollLocation(s) {
			s.location = +now.add(12, 'm')
		},

		fastPollUserBlockStatus(s, a: PayloadAction<string>) {
			s.blockStatus[a.payload] = +now.add(2, 'm')
		},
	},
	extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
})

export const {
	cancelFastPollAddChild,
	cancelFastPollDevices,
	cancelFastPollLocation,
	fastPollAddChild,
	fastPollDevices,
	fastPollLocation,
	fastPollUserBlockStatus,
} = fastPollSlice.actions

const isPollingAction = /*@__PURE__*/ isAnyOf(...(Object.values(fastPollSlice.actions) as any))

export const pollingSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	fastPollSlice,
	identity,
	(a) => (isPollingAction(a) ? null : a),
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithFastPollState>(s: T) => s.fastPoll

export const addChildFastPoll$ = /*@__PURE__*/ createSelector(state$, (s) => s.addChild)
export const blockStatusFastPoll$ = /*@__PURE__*/ createSelector(state$, (s) => s.blockStatus)
export const devicesFastPoll$ = /*@__PURE__*/ createSelector(state$, (s) => s.devices)
export const locationFastPoll$ = /*@__PURE__*/ createSelector(state$, (s) => s.location)
