import { now } from '@eturi/date-util'
import type { SubSource } from './SubSource'

export type AccountSub = {
	readonly cancelled: boolean
	readonly expiration: number
	readonly source: SubSource
}

export type AccountSubs = {
	readonly [skuName: string]: AccountSub
}

export const DEFAULT_SUB = {
	cancelled: false,
	expiration: +now.add(2, 'y'),
	source: 'internal',
} satisfies AccountSub
