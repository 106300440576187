import { fetchCouponDetails, unwrapThunk } from '@op/services'
import { createResolverRedirect } from '../../common'
import { benefitSKUTier$ } from '../../compound-selectors/benefits'
import { setSubscribingState } from '../../reducers/product.slice'
import { resolveSerialRootAuth } from '../../route-resolvers'
import type { AppStore } from '../../types'
import { isAndroidParent } from '../../util'

const promoPageResolver = async (store: AppStore) => {
	if (isAndroidParent) return

	await unwrapThunk(store.dispatch(fetchCouponDetails()))

	const benefitSKUTier = benefitSKUTier$(store.getState())

	// If we don't have a promo benefit after fetching coupon details, exit,
	// as this means we don't have a promo available on this account.
	if (!benefitSKUTier) throw createResolverRedirect('/')

	// Going to promo page we also set the subscribing SKU tier since the Stripe
	// payment stuff is there and that relies on subscribingSKU not benefitSKU.
	// FIXME: Need to be able to get billing cycle somehow
	store.dispatch(setSubscribingState({ cycle: 'monthly', tier: benefitSKUTier }))
}

export const resolvePromoPage = /*@__PURE__*/ resolveSerialRootAuth(promoPageResolver)
