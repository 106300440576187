import { assertNotNullish } from '@eturi/util'
import { size, toParamsURL } from '@op/util'
import type { Draft } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import find from 'lodash/find'
import { createSliceTransformer, stripPayload } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { Avatar, IdPayloadAction, InitState, ManagedUser, SThunkState } from '../types'
import {
	createIdPayloadPrepare,
	isAvatar,
	isDefaultAvatar,
	normalizeDataURI,
	pickIdPayload,
} from '../types'
import type { WithUserState } from './user.slice'
import { rawChildren$, updateUser } from './user.slice'

// NOTE: This is an init state b/c we'll likely switch our Avatar storage
//  behavior to mirror Vew, so it'll be a separate endpoint.
export type UserAvatarState = InitState & {
	readonly avatar?: Avatar
}

export type AvatarState = {
	readonly [userId: string]: UserAvatarState
}

export type WithAvatarState = {
	readonly avatar: AvatarState
}

const initialState: AvatarState = {}

const ensureUserAvatarState = (
	s: Draft<AvatarState>,
	userId: string,
	userStateUpdate?: Partial<UserAvatarState>,
): Draft<UserAvatarState> =>
	castDraft(Object.assign((s[userId] ||= { isInit: false }), userStateUpdate))

export const avatarSlice = /*@__PURE__*/ createSlice({
	name: 'avatar',
	initialState,
	reducers: {
		removeAvatar: {
			prepare: createIdPayloadPrepare(),
			reducer(s, a: IdPayloadAction) {
				const [id] = pickIdPayload(a)
				delete ensureUserAvatarState(s, id).avatar
			},
		},

		setAvatar: {
			prepare: createIdPayloadPrepare<Avatar>(),
			reducer(s, a: IdPayloadAction<Avatar>) {
				const [id, avatar] = pickIdPayload(a)
				ensureUserAvatarState(s, id, { avatar, isInit: true })
			},
		},
	},
	extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
})

export const { removeAvatar, setAvatar } = avatarSlice.actions

const isAvatarAction = /*@__PURE__*/ isAnyOf(removeAvatar, setAvatar)

export const avatarSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	avatarSlice,
	size,
	(a) => (isAvatarAction(a) ? stripPayload(a) : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

type AvatarThunkState = SThunkState & WithAvatarState & WithUserState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<AvatarThunkState>()

type FetchAvatarRes = {
	readonly data: string
	readonly img_modified: number
}

type FetchUserAvatarArg = HttpExtra & {
	readonly userId: string
}

export const fetchUserAvatar = /*@__PURE__*/ createAsyncThunk(
	'avatar/user/fetch',
	async ({ userId, ...extra }: FetchUserAvatarArg, { dispatch, extra: { http } }) => {
		const { data } = await dispatch(
			http.get<FetchAvatarRes>(toParamsURL('/data', { key: 'avatar.png', user_id: userId }), extra),
		)

		if (!data) throw new Error('Invalid empty avatar')

		dispatch(setAvatar(userId, { image: normalizeDataURI(data) }))
	},
	{
		condition: ({ force, userId }, api) => {
			if (!force && avatarState$(api.getState())[userId]?.isInit) return false
		},
	},
)

type UpdateAvatarRes = {
	readonly img_modified: number
	readonly img_url: string
}

type UpdateAvatarArg = {
	readonly data: string
	readonly user: ManagedUser
}

export const updateAvatar = /*@__PURE__*/ createAsyncThunk(
	'avatar/user/update',
	async ({ data, user }: UpdateAvatarArg, { dispatch, getState, extra: { http } }) => {
		const { avatar: currentAvatar, user_id } = user
		const rawUser = find(rawChildren$(getState()), { user_id })

		assertNotNullish(rawUser, 'updateAvatar RawUser')

		// Optimistically set the avatar
		dispatch(setAvatar(user_id, { image: normalizeDataURI(data) }))

		try {
			const { img_modified, img_url } = await dispatch(
				http.post<UpdateAvatarRes>(toParamsURL('/data', { key: 'avatar.png', user_id }), {
					data: [data],
				}),
			)

			if (img_modified) {
				// FIXME: Change updateUser to be a partial update
				// Update the user w/ the avatar-related props
				dispatch(updateUser({ img_modified, img_url, user_id }))
			}
		} catch (e) {
			// Rollback avatar update if it fails
			if (isAvatar(currentAvatar)) {
				dispatch(setAvatar(user_id, currentAvatar))
			} else if (isDefaultAvatar(currentAvatar)) {
				dispatch(removeAvatar(user_id))
			}

			throw e
		}
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

export const avatarState$ = <T extends WithAvatarState>(s: T) => s.avatar
