import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import type { Integration } from '@sentry/types'
import { matchPath } from 'react-router-dom'
import { getUserAgent, isAndroidParent } from './util'

type Breadcrumb = Sentry.Breadcrumb

// FIXME: Figure out a way to tell if Android Parent is local
const isLocal = location.hostname.startsWith('local.ourpact')
const isNonLocalDev = !isLocal && process.env.APP_ENV === 'dev'
const client = isAndroidParent ? 'Android' : 'Web'
const environment = `${client}-${isLocal ? 'local' : process.env.APP_ENV}`

type ConsoleBreadcrumb = Breadcrumb & { category: 'console'; message: string }
type HttpBreadcrumb = Breadcrumb & { category: 'fetch' | 'xhr' }
type SecureAmzReqBreadcrumb = HttpBreadcrumb & { data: { url: string } }

type NavBreadcrumb = Breadcrumb & {
	category: 'navigation'
	data: { from: string; to: string }
}

const isConsoleBreadcrumb = (b: Breadcrumb): b is ConsoleBreadcrumb => b.category === 'console'

const isHttpBreadcrumb = (b: Breadcrumb): b is HttpBreadcrumb =>
	b.category === 'fetch' || b.category === 'xhr'

const isNavBreadcrumb = (b: Breadcrumb): b is NavBreadcrumb => b.category === 'navigation'

/** Whether the breadcrumb is represents a secure Amazon request */
const isSecureAmzReq = (b: Breadcrumb): b is SecureAmzReqBreadcrumb => {
	if (!isHttpBreadcrumb(b)) return false

	const url = b.data?.url?.toLowerCase()

	return Boolean(url && url.includes('amazonaws') && url.includes('credential'))
}

/** Redacts the securely generated Amazon urls and adds an endpoint data point */
const redactSecureAmzReq = (b: SecureAmzReqBreadcrumb) => {
	const url = b.data.url
	let endpoint

	if (url.includes('thumb_')) {
		endpoint = 'Vew Thumbnail'
	} else if (url.includes('screen_')) {
		endpoint = 'Vew Screenshot'
	}

	return { ...b, data: { ...b.data, url: 'SecureAmazonUrl', endpoint } }
}

const ID_ROUTES = ['/gallery/:userId', '/notifications/:selectedId?', '/signup/:groupId?']

const FEATURE_ROUTES = ['/manage/:userId/:feature']

const normalizeNavPath = (path: string): string => {
	let match

	for (const r of ID_ROUTES) {
		if ((match = matchPath(path, r))) return match.path
	}

	for (const r of FEATURE_ROUTES) {
		if ((match = matchPath(path, r)))
			return match.path.replace(':feature', (match.params as any).feature || '')
	}

	return path
}

const handleConsoleBreadcrumb = (b: ConsoleBreadcrumb) => {
	return b.message.startsWith('i18next') ? null : b
}

const handleNavBreadcrumb = (b: NavBreadcrumb) => {
	const {
		data: { from, to },
	} = b

	if (from === to) return null

	return {
		...b,
		data: { ...b.data, from: normalizeNavPath(from), to: normalizeNavPath(to) },
	}
}

const normalizeEvent = (ev: Sentry.Event) => {
	const req = ev.request
	const url = req?.url

	if (!url) return ev

	try {
		const parsed = new URL(url)
		parsed.pathname = normalizeNavPath(parsed.pathname)

		return { ...ev, request: { ...req, url: parsed.toString() } }
	} catch {
		return ev
	}
}

class OurPactUA implements Integration {
	static id = 'OurPactUA'
	name = OurPactUA.id

	setupOnce() {
		addGlobalEventProcessor((event) => {
			if (getCurrentHub().getIntegration(OurPactUA)) {
				const url = event.request?.url || window.location.href
				const { referrer } = document
				const userAgent = getUserAgent()

				const headers = {
					...event.request?.headers,
					...(referrer && { Referer: referrer }),
					...(userAgent && { 'User-Agent': userAgent }),
				}
				const request = { ...(url && { url }), headers }

				return { ...event, request }
			}

			return event
		})
	}
}

export const initSentry = () => {
	Sentry.init({
		beforeBreadcrumb(breadcrumb) {
			// Filter out any unwanted console messages
			if (isConsoleBreadcrumb(breadcrumb)) return handleConsoleBreadcrumb(breadcrumb)

			// Redact secure amazon URLs
			if (isSecureAmzReq(breadcrumb)) return redactSecureAmzReq(breadcrumb)

			// Don't log push state when it's to the same path
			if (isNavBreadcrumb(breadcrumb)) return handleNavBreadcrumb(breadcrumb)

			return breadcrumb
		},
		beforeSend: normalizeEvent,
		denyUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		],
		dsn: process.env.SENTRY_DSN,
		environment,
		ignoreErrors: [
			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
			// reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
		],
		integrations: (integrations) => {
			return [
				...integrations
					.filter((i) => i.name !== 'UserAgent')
					.map((i) =>
						isLocal && i.name === 'Breadcrumbs'
							? new Sentry.Integrations.Breadcrumbs({ console: false })
							: i,
					),
				new Integrations.BrowserTracing({
					beforeNavigate: (context) => ({ ...context, name: normalizeNavPath(context.name) }),
				}),
				new OurPactUA(),
			]
		},
		normalizeDepth: 10,
		release: process.env.SENTRY_RELEASE,
		// In QA / dev environments (not local), we sample everything
		sampleRate: isNonLocalDev ? 1 : 0.25, // Sample 25% of errors
		tracesSampleRate: isNonLocalDev ? 1 : 0.05, // Trace 5% of transactions
	})
}
