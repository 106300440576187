import { useFn } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { isReqErrorType, ReqErrorCode } from '@op/services'
import { useToasts } from '../components/Toast'

/**
 * A hook to provide generic toast error handling
 */
export const useHandleError = () => {
	const [t] = useT()
	const { addErrorToast } = useToasts()

	return useFn((e: any) => {
		addErrorToast(
			t(
				`error_strings.${
					isReqErrorType(e, ReqErrorCode.NETWORK_ERROR) ? 'network' : 'unknown'
				}_error`,
			),
		)
	})
}
