import type { SystemOfUnits } from './SystemOfUnits'

// FIXME: snake_case -> camelCase
export type AppPrefs = {
	readonly modelVersion: number
	readonly system_of_units: Maybe<SystemOfUnits>
}

export type AccountAttribute = {
	ts: number
	value: string
	type: Maybe<string>
}

export type RawAppPrefs = {
	webapp: AccountAttribute
}

export type RawEmailNotifPref = {
	readonly 'account.marketing_email_optin': AccountAttribute
}

export const DEFAULT_PREFS = {
	modelVersion: 1,
	system_of_units: null,
} satisfies AppPrefs

export const parseRawEmailNotifPref = (raw: RawEmailNotifPref): boolean => {
	try {
		const setting = raw['account.marketing_email_optin'].value
		return Boolean(setting && setting === 'true')
	} catch {
		return false
	}
}

export const parseRawPrefs = (raw: RawAppPrefs): Maybe<AppPrefs> => {
	try {
		return JSON.parse(raw.webapp.value)
	} catch {
		return null
	}
}
