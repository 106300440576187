export type Lang = 'de' | 'en' | 'es' | 'fr' | 'nl' | 'pt-br' | 'zh-cn' | 'zh-tw'

export const DEFAULT_LANGUAGE = 'en' satisfies Lang
export const DEFAULT_LOCALE = 'en-us'
export const SUPPORTED_LANG_LIST: Lang[] = ['de', 'en', 'es', 'fr', 'nl', 'pt-br', 'zh-cn', 'zh-tw']

const SUPPORTED_LANGUAGES: ReadonlySet<Lang> = new Set(SUPPORTED_LANG_LIST)
const TRADITIONAL_CHINESE = new Set(['hk', 'mc', 'sg', 'tw'])

export const isSupportedLang = (l: any): l is Lang => SUPPORTED_LANGUAGES.has(l)

/** Parses out the Lang from the locale */
export const getLangFromLocale = (locale: string): Lang => {
	const normalizedLocale = normalizeLocaleFormat(locale)

	// A locale can be a valid Lang, so we'll try that first
	if (isSupportedLang(normalizedLocale)) return normalizedLocale

	// If it's not a valid lang, we'll try slicing and normalizing
	const lang = normalizedLocale.slice(0, 2)

	// Handle Chinese correctly, with any "Traditional" being mapped to
	// "Traditional Taiwanese" and the rest being mapped to simplified.
	if (lang === 'zh') {
		// If the dialect slice is Traditional Taiwanese Chinese, use that,
		// otherwise use Simplified
		return TRADITIONAL_CHINESE.has(normalizedLocale.slice(3)) ? 'zh-tw' : 'zh-cn'
	}

	// If the sliced version is a valid Lang, use that, otherwise use default
	return isSupportedLang(lang) ? lang : DEFAULT_LANGUAGE
}

/**
 * Normalizes the locale to supported values
 *
 * Make sure that the lang derived from the locale is valid. If it is, normalize
 * the format, and if not, return the default locale.
 */
export const normalizeLocale = (locale: string) =>
	isSupportedLang(getLangFromLocale(locale)) ? normalizeLocaleFormat(locale) : DEFAULT_LOCALE

/** Normalizes locale strings */
export const normalizeLocaleFormat = (locale: string) => {
	const lower = locale.toLowerCase()
	const [lang, code] = lower.split(/[-_]/)

	if (lang.length !== 2) return DEFAULT_LOCALE

	if (!code) return lang

	if (lang === 'zh') {
		// Normalize Apple's stupid strings
		if (code === 'hans') return 'zh-cn'
		if (code === 'hant') return 'zh-tw'
	}

	switch (code.length) {
		case 2:
		case 4:
			return `${lang}-${code}`
	}

	return DEFAULT_LOCALE
}
