import { useT } from '@eturi/react-i18next'
import { lazy, memo } from 'react'
import { LoadPage, NavBar } from '../../components'

const AccountPageContent = /*@__PURE__*/ lazy(() => import('./AccountPageContent'))

const AccountPage = () => {
	const [t] = useT()

	return (
		<article className="is-page-pad-touch page">
			<NavBar.Default>{t('account.header')}</NavBar.Default>

			<LoadPage>
				<AccountPageContent />
			</LoadPage>
		</article>
	)
}

export default /*@__PURE__*/ memo(AccountPage)
