import { getScrollPos } from './getScrollPos'

/**
 * Class to abstract scroll operations between window and elements.
 * NOTE: Might add scroll events if it's a useful abstraction.
 */
export class ScrollHelper {
	constructor(
		private _$scrollEl?: HTMLElement,
		private _isScrollFrozen: boolean = false,
		private _frozenScrollY: number = 0,
	) {}

	private get _hasScrollElement() {
		return this._$scrollEl != null
	}

	get isScrollFrozen() {
		return this._isScrollFrozen
	}

	set isScrollFrozen(isFrozen: boolean) {
		this._isScrollFrozen = isFrozen
	}

	set frozenScrollY(scrollY: number) {
		this._frozenScrollY = scrollY
	}

	get scrollElement(): Window | HTMLElement {
		return this._$scrollEl || window
	}

	get scrollX() {
		return this._hasScrollElement ? this._$scrollEl!.scrollLeft : window.scrollX
	}

	get scrollY() {
		return this._hasScrollElement
			? this._$scrollEl!.scrollTop
			: this._isScrollFrozen
			? this._frozenScrollY
			: window.scrollY
	}

	scrollToElement($el: Maybe<HTMLElement>, offsetY = 0, offsetX = 0) {
		if (!$el) return

		const { x, y } = getScrollPos($el, this._$scrollEl)

		this.scrollToPos(x + offsetX, y + offsetY)
	}

	scrollToPos(x: number, y: number) {
		if (!this._hasScrollElement) return window.scrollTo(x, y)

		const $el = this._$scrollEl!
		$el.scrollLeft = x
		$el.scrollTop = y
	}

	setScrollElement($el?: HTMLElement) {
		this._$scrollEl = $el
	}
}
