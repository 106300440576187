import { lazy } from 'react'

export const ConnectPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'connect' */
			/* webpackPrefetch: 100 */
			'./ConnectPage'
		),
)

export const ForgotPwPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'forgot-pw' */
			/* webpackPrefetch: 10 */
			'./ForgotPwPage'
		),
)

export const LoginPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'login' */
			/* webpackPrefetch: 100 */
			'./LoginPage'
		),
)

export const SchedulePage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'schedule' */
			/* webpackPrefetch: 10 */
			'./SchedulePage'
		),
)

export * from './AllowancePage/lazy'
export * from './AppRulesPage/lazy'
export * from './PairPage/lazy'
export * from './PwResetPage/lazy'
export * from './RestrixPage/lazy'
export * from './SignUpPage/lazy'
export * from './VerifyEmailPage/lazy'
