import type {
	WithAccessState,
	WithAccountState,
	WithAppsState,
	WithAvatarState,
	WithBenefitsState,
	WithBlockStatusState,
	WithDeviceState,
	WithGeoState,
	WithGranState,
	WithNotifState,
	WithPrefsState,
	WithPurchaseState,
	WithRuleState,
	WithSamplesState,
	WithSampleStatsState,
	WithUserState,
	WithVewConfigState,
	WithVewDevicesState,
} from '@op/services'
import {
	accessSlice,
	accessSliceTransformer,
	accountSlice,
	accountSliceTransformer,
	appsSlice,
	appsSliceTransformer,
	avatarSlice,
	avatarSliceTransformer,
	benefitsSlice,
	blockStatusSlice,
	blockStatusSliceTransformer,
	createSamplesSlice,
	createSamplesSliceTransformer,
	deviceSlice,
	deviceSliceTransformer,
	geoSlice,
	geoSliceTransformer,
	granularitySlice,
	granularitySliceTransformer,
	notificationSlice,
	notificationSliceTransformer,
	prefsSlice,
	purchaseSlice,
	ruleSlice,
	ruleSliceTransformer,
	sampleStatsSlice,
	userSlice,
	userSliceTransformer,
	vewConfigSlice,
	vewConfigSliceTransformer,
	vewDevicesSlice,
	vewDevicesSliceTransformer,
} from '@op/services'
import { combineReducers } from '@reduxjs/toolkit'
import identity from 'lodash/identity'
import { combineTransformers, createSliceTransformer } from 'rtk-slice-transformer'
import type { WithAppMiscState } from './app-misc.slice'
import { appMiscSlice, appMiscSliceTransformer } from './app-misc.slice'
import type { WithBrowserInfoState } from './browser-info.slice'
import { browserInfoSlice, browserInfoSliceTransformer } from './browser-info.slice'
import type { WithChatState } from './chat.slice'
import { chatSlice } from './chat.slice'
import type { WithFastPollState } from './fast-poll.slice'
import { fastPollSlice, pollingSliceTransformer } from './fast-poll.slice'
import type { WithGalleryUIState } from './gallery-ui.slice'
import { galleryUISlice, galleryUISliceTransformer } from './gallery-ui.slice'
import type { WithGeoUIState } from './geo-ui.slice'
import { geoUISlice, geoUISliceTransformer } from './geo-ui.slice'
import type { WithHttpRetryState } from './http-retry.slice'
import { httpRetrySlice, httpRetrySliceTransformer } from './http-retry.slice'
import type { WithPairState } from './pair.slice'
import { pairSlice, pairSliceTransformer } from './pair.slice'
import type { WithProductState } from './product.slice'
import { productSlice, productSliceTransformer } from './product.slice'

const samplesSlice = createSamplesSlice((s) => {
	s.src && URL.revokeObjectURL(s.src)
})

type FlatIntersection<T> = {
	readonly [P in keyof T]: T[P]
}

export type RootState = FlatIntersection<
	WithAccessState &
		WithAccountState &
		WithAppMiscState &
		WithAppsState &
		WithAvatarState &
		WithBenefitsState &
		WithBlockStatusState &
		WithBrowserInfoState &
		WithChatState &
		WithDeviceState &
		WithFastPollState &
		WithGalleryUIState &
		WithGeoState &
		WithGeoUIState &
		WithGranState &
		WithHttpRetryState &
		WithNotifState &
		WithPairState &
		WithPrefsState &
		WithProductState &
		WithPurchaseState &
		WithRuleState &
		WithSamplesState &
		WithSampleStatsState &
		WithUserState &
		WithVewConfigState &
		WithVewDevicesState
>

export const sentryTransformer = combineTransformers([
	accessSliceTransformer,
	accountSliceTransformer,
	appMiscSliceTransformer,
	appsSliceTransformer,
	avatarSliceTransformer,
	blockStatusSliceTransformer,
	browserInfoSliceTransformer,
	createSamplesSliceTransformer(samplesSlice),
	createSliceTransformer(benefitsSlice, identity),
	createSliceTransformer(chatSlice, identity),
	createSliceTransformer(purchaseSlice, identity),
	deviceSliceTransformer,
	galleryUISliceTransformer,
	geoSliceTransformer,
	geoUISliceTransformer,
	granularitySliceTransformer,
	httpRetrySliceTransformer,
	notificationSliceTransformer,
	pairSliceTransformer,
	pollingSliceTransformer,
	productSliceTransformer,
	ruleSliceTransformer,
	userSliceTransformer,
	vewConfigSliceTransformer,
	vewDevicesSliceTransformer,
])

export const rootReducer = combineReducers({
	[accessSlice.name]: accessSlice.reducer,
	[accountSlice.name]: accountSlice.reducer,
	[appMiscSlice.name]: appMiscSlice.reducer,
	[appsSlice.name]: appsSlice.reducer,
	[avatarSlice.name]: avatarSlice.reducer,
	[benefitsSlice.name]: benefitsSlice.reducer,
	[blockStatusSlice.name]: blockStatusSlice.reducer,
	[browserInfoSlice.name]: browserInfoSlice.reducer,
	[chatSlice.name]: chatSlice.reducer,
	[deviceSlice.name]: deviceSlice.reducer,
	[fastPollSlice.name]: fastPollSlice.reducer,
	[galleryUISlice.name]: galleryUISlice.reducer,
	[geoSlice.name]: geoSlice.reducer,
	[geoUISlice.name]: geoUISlice.reducer,
	[granularitySlice.name]: granularitySlice.reducer,
	[httpRetrySlice.name]: httpRetrySlice.reducer,
	[notificationSlice.name]: notificationSlice.reducer,
	[pairSlice.name]: pairSlice.reducer,
	[prefsSlice.name]: prefsSlice.reducer,
	[productSlice.name]: productSlice.reducer,
	[purchaseSlice.name]: purchaseSlice.reducer,
	[ruleSlice.name]: ruleSlice.reducer,
	[samplesSlice.name]: samplesSlice.reducer,
	[sampleStatsSlice.name]: sampleStatsSlice.reducer,
	[userSlice.name]: userSlice.reducer,
	[vewConfigSlice.name]: vewConfigSlice.reducer,
	[vewDevicesSlice.name]: vewDevicesSlice.reducer,
})
