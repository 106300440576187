import { lazy } from 'react'

export const SignUpPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'signup' */
			/* webpackPrefetch: 100 */
			'./SignUpPage'
		),
)
