/**
 * ACL privileges for account
 */
export type AccessLevel = {
	readonly allowance: boolean
	readonly blocks_per_month: number
	readonly child_users_per_account: number
	readonly devices_per_account: number
	readonly geolocation: boolean
	readonly grants_per_month: number
	readonly granularity: boolean
	readonly schedules_per_child: number
	// If the user has a high enough sku tier to access vew
	readonly vew: boolean
	// If the user has the vew feature flag enabled at account level
	readonly vew_active?: boolean
	readonly vew_automated: boolean
	// If the user does not have updated account i.e. hash password, account keys
	readonly vew_available?: boolean
	readonly vew_on_demand_per_month: number
}

export const DEFAULT_ACCESS_LEVEL = {
	allowance: false,
	blocks_per_month: 5,
	child_users_per_account: Infinity,
	devices_per_account: 1,
	geolocation: false,
	grants_per_month: Infinity,
	granularity: false,
	schedules_per_child: 1,
	vew: false,
	vew_active: false,
	vew_automated: false,
	vew_available: false,
	vew_on_demand_per_month: 0,
} satisfies AccessLevel
