import isObject from 'lodash/isObject'
import noop from 'lodash/noop'

// FIXME: We should remove all this, since we don't have an Android wrap app
export type GoogleBillingCode = ValueUnion<typeof GoogleBillingCode>
export const GoogleBillingCode = {
	OK: 0,
	CANCELLED: 1,
	//SERVICE_UNAVAILABLE: 2,
	//BILLING_UNAVAILABLE: 3,
	//ITEM_UNAVAILABLE: 4,
	//DEV_ERROR: 5,
	ERROR: 6,
	ALREADY_OWNED: 7,
	//NOT_OWNED: 8,
	// Non-Google, added for our purposes
	REQUEST_ID_MISMATCH: 1024,
} as const

export type GoogleBillingError = {
	readonly code: GoogleBillingCode
	readonly type: 'iap'
}

export type GooglePurchase = {
	readonly cancelled: boolean
	readonly sku: string
	readonly token: string
}

export type GoogleIAPCallback<R> = (
	requestId: string,
	resultCode: GoogleBillingCode,
	result: Maybe<R>,
) => void

/* Called when wrapper gets results back from Google Billing Services */
export type OnPurchasesReceived = GoogleIAPCallback<GooglePurchase[]>

/* Called when wrapper gets results back from Google IAP */
export type OnPurchaseResult = GoogleIAPCallback<GooglePurchase>

/** Initializer fn that returns a request id from the wrapper */
export type InitGoogleIAPReqFn = () => Maybe<string>

export type GoogleIAPCallbackObj = {
	onPurchasesReceived: OnPurchasesReceived
	onPurchaseResult: OnPurchaseResult
}

/**
 * This whole thing is a bit ghetto, but here's how it works.
 *
 * The OurPactWrap defines IAP functions that will return a unique id used to
 * track the callback of the request. If they do not return a string
 * (Maybe<string>) we know there was an error of some kind.
 *
 * In the implementation, we create a promise with resolve / reject exposed via
 * closures. We also define a closure for the unique string (`requestId`). We
 * then define the global callback that will be called for the `initFn` passed,
 * using the `completeKey`. Thus, for `getPurchases`, we define
 * `onPurchaseResult`.
 *
 * After the callback and closures are defined, we invoke the `initFn`, setting
 * `requestId` to the result. We have to do it this way b/c Java requires the
 * callback to be defined at the time the `initFn` is called.
 *
 * NOTE: To simplify this craziness marginally, the `initFn` should have any
 *  arguments already bound into it.
 *
 * The callback accepts the `completeId` as it's first argument, and we compare
 * this to the `requestId` to make sure that the callback has been invoked by
 * the correct function. This should never be called with a different
 * `completeId`, but it's possible if the same `completeKey` callback function
 * is redefined (i.e. invoked twice), before the first resolves. So don't do
 * that (currently we make sure this doesn't happen in ProductsService).
 *
 * The second argument passed to the callback is the GoogleBillingCode enum
 * (`resultCode`). With this, the callback determines whether we received an
 * error that we consider to be fatal, and if it is, we reject the promise.
 *
 * The third and final argument is the response, which is an object determined
 * by the particular function being called. Currently this can be either a
 * `GooglePurchase`, for the `purchaseSku` call, a `GooglePurchase[]` list for
 * the `getPurchases` call, or for either call, null, if there was an error of
 * some sort.
 *
 * If the `resultCode` is OK, we resolve the promise with the response argument.
 * All other result codes are turned into `IAPError`s with the corresponding
 * code, and should be handled downstream.
 *
 * This whole rigmarole thus serves to:
 *
 * 1. Promisify these callback-based async contracts
 * 2. Ensure the callback invoked by the wrapper matches the original call to
 *    the wrapper
 * 3. Properly reject errors
 */
export const createGoogleIAP = <
	T extends GoogleIAPCallbackObj,
	K extends keyof GoogleIAPCallbackObj,
>(
	callbackObj: T,
	completeKey: K,
	initReqFn: InitGoogleIAPReqFn,
) => {
	let reqId: Maybe<string> = null
	let resolve = noop
	let reject = noop

	const promise = new Promise<Parameters<T[K]>[2]>((res, rej) => {
		resolve = res
		reject = rej
	})

	const callback: GoogleIAPCallback<Parameters<T[K]>[2]> = (completeId, resultCode, result) => {
		// We need to make sure we don't receive the result of a different request
		// so we match on it here and reject if there's a mismatch.
		if (completeId !== reqId) resultCode = GoogleBillingCode.REQUEST_ID_MISMATCH
		if (resultCode !== GoogleBillingCode.OK) return reject(createGoogleBillingError(resultCode))
		resolve(result)
	}

	callbackObj[completeKey] = callback

	reqId = initReqFn()

	// Reject with generic error if we don't get a reqId
	if (reqId == null) reject(GoogleBillingCode.ERROR)

	return promise
}

const createGoogleBillingError = (code: GoogleBillingCode): GoogleBillingError => ({
	code,
	type: 'iap',
})

export const isGoogleBillingError = (err: any): err is GoogleBillingError =>
	isObject(err) && (err as any).type === 'iap'

/**
 * Determines whether a billing code is fatal to continuing. Already owned, or
 * cancelled payment window are not fatal, so any other code is fatal. Note that
 * an OK code would also not be fatal, but we should never reject any request
 * w/ the OK code, so we don't include it here.
 */
export const isFatalIAPError = (err: any) =>
	isGoogleBillingError(err) &&
	err.code !== GoogleBillingCode.ALREADY_OWNED &&
	err.code !== GoogleBillingCode.CANCELLED
