import type { ButtonBaseProps, ButtonTags } from '../../types'
import { getBtnCls, getBtnType } from '../../types'

export const Btn = <T extends ButtonTags = 'button'>({
	active = false,
	color = 'teal',
	children,
	className,
	fat = false,
	invert = false,
	size = 'md',
	tag = 'button' as T,
	...p
}: ButtonBaseProps<T>) => {
	const type = getBtnType(tag, p)
	const Wrapper = tag as any

	return (
		<Wrapper
			{...p}
			className={getBtnCls(active, color, fat, invert, size, 'btn', className)}
			type={type}
		>
			{children}
		</Wrapper>
	)
}
