import './RadioCheck.scss'

import { useFn } from '@eturi/react'
import { useKeyboardClick } from '@op/react-web'
import cls from 'classnames'
import type { InputHTMLAttributes, MutableRefObject } from 'react'
import { useContext, useEffect, useMemo, useRef } from 'react'
import type { RadioCheckType } from '../../types'
import { RadioGroupContext } from './RadioGroup'

type CheckProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
	readonly isReversed?: boolean
	readonly isSpaced?: boolean
}

const getRadioCheckCls = (
	checked: CheckProps['checked'],
	className: CheckProps['className'],
	isButton: boolean,
	isReversed: CheckProps['isReversed'],
	isSpaced: CheckProps['isSpaced'],
	type: RadioCheckType,
) =>
	cls(
		className,
		type,
		'radio-check',
		!isButton && 'radio-check--has-label',
		checked && 'is-checked',
		isReversed && 'is-reversed',
		isSpaced && 'is-spaced',
	)

const useRadioCheckFieldClickProps = (
	fakeInputRef: MutableRefObject<Maybe<HTMLSpanElement>>,
	inputRef: MutableRefObject<Maybe<HTMLInputElement>>,
) =>
	useKeyboardClick((ev) => {
		// Click events will affect the input automatically. So we only need to
		// invoke an artificial click on keyboard selected labels. Also, if it's
		// a click, the behavior is not to retain focus according to my testing,
		// so we don't need to put focus on the label here.
		if (ev.type === 'click') return fakeInputRef.current?.blur()

		inputRef.current?.click()
		fakeInputRef.current?.focus()
	})

const checkFactory = (isButton: boolean, displayName: string) => {
	const Check = ({ children, className, isReversed, isSpaced, ...p }: CheckProps) => {
		const { checked } = p
		const fakeInputRef = useRef<HTMLSpanElement>(null)
		const inputRef = useRef<HTMLInputElement>(null)
		const handleFieldClickProps = useRadioCheckFieldClickProps(fakeInputRef, inputRef)

		const FakeInput = useMemo(
			() => (
				<span
					{...handleFieldClickProps}
					aria-checked={checked}
					className="radio-check__elem"
					ref={fakeInputRef}
					role="checkbox"
				>
					<span className="radio-check__elem-inner">
						{isButton ? children : <i className="pakicon-check checkbox__check" />}
					</span>
				</span>
			),
			[checked, children],
		)

		const MainCls = getRadioCheckCls(checked, className, isButton, isReversed, isSpaced, 'checkbox')

		return (
			<label className={MainCls}>
				<input className="sr-only" {...p} ref={inputRef} tabIndex={-1} type="checkbox" />
				{FakeInput}
				{!isButton && <span className="radio-check__label">{children}</span>}
			</label>
		)
	}

	Check.displayName = displayName

	return Check
}

type RadioProps = CheckProps & { readonly tabIdx?: number }

const radioFactory = (isButton: boolean, displayName: string) => {
	const Radio = ({ children, className, isReversed, isSpaced, tabIdx, ...p }: RadioProps) => {
		const { checked } = p
		const fakeInputRef = useRef<Maybe<HTMLSpanElement>>(null)
		const inputRef = useRef<HTMLInputElement>(null)
		const radioGroupCtx = useContext(RadioGroupContext)
		const handleFieldClickProps = useRadioCheckFieldClickProps(fakeInputRef, inputRef)

		delete handleFieldClickProps.tabIndex

		// Ref that adds the fake radio to the radio group for keyboard accessibility
		const fakeInputRefCallback = useFn(($el: Maybe<HTMLSpanElement>) => {
			const prevLabelRef = fakeInputRef.current

			// If we had a different element remove it
			if (prevLabelRef) radioGroupCtx.remove(prevLabelRef)

			if ($el) radioGroupCtx.add($el, tabIdx)

			fakeInputRef.current = $el
		})

		useEffect(
			() => () => {
				const label = fakeInputRef.current
				if (label) radioGroupCtx.remove(label)
			},
			[],
		)

		const FakeInput = useMemo(
			() => (
				<span
					{...handleFieldClickProps}
					aria-checked={checked}
					className="radio-check__elem"
					ref={fakeInputRefCallback}
					role="radio"
					tabIndex={checked ? 0 : -1}
				>
					<span className="radio-check__elem-inner">{isButton ? children : null}</span>
				</span>
			),
			[checked, children],
		)

		const MainCls = getRadioCheckCls(checked, className, isButton, isReversed, isSpaced, 'radio')

		return (
			<label className={MainCls}>
				<input className="sr-only" {...p} ref={inputRef} tabIndex={-1} type="radio" />
				{FakeInput}
				{!isButton && <span className="radio-check__label">{children}</span>}
			</label>
		)
	}

	Radio.displayName = displayName

	return Radio
}

export const Check = checkFactory(false, 'Check')
export const CheckBtn = checkFactory(true, 'CheckBtn')
export const Radio = radioFactory(false, 'Radio')
export const RadioBtn = radioFactory(true, 'RadioBtn')
