import { utcDate } from '@eturi/date-util'
import { sentryError } from '@eturi/sentry'
import {
	accountId$,
	createHttp,
	isAuthenticated$,
	parentSecret$,
	parentSecretId$,
	signPayload,
} from '@op/services'
import { getEndpoints, SessionDeviceId } from './env'
import type { RootState } from './reducers'
import { deleteHttpRetry, getHttpRetry, updateHttpRetry } from './reducers/http-retry.slice'

const doesUrlRequireAuth = async (url: string) => {
	const { sfsh } = await getEndpoints()!

	return url.startsWith(sfsh)
}

type OurPactHeader = {
	readonly Authorization?: string
	readonly UtcDate: string
	readonly 'X-SESSIONID': string
	readonly 'X-DEVICETS': string
}

export const http = createHttp<RootState, any>(
	{
		headers: (extra, url, method, body) => async (dispatch, getState) => {
			const [requiresAuth, sessionDeviceId] = await Promise.all([
				extra.isUnauthenticated ? Promise.resolve(false) : doesUrlRequireAuth(url),
				SessionDeviceId.get(),
			])

			const header: Writable<OurPactHeader> = {
				UtcDate: utcDate(),
				'X-SESSIONID': sessionDeviceId,
				'X-DEVICETS': `${Date.now()}`,
			}

			const state = getState()

			const accountId = accountId$(state)
			const isAuthorized = isAuthenticated$(state)
			const parentSecret = parentSecret$(state)
			const parentSecretId = parentSecretId$(state)

			if (requiresAuth && isAuthorized) {
				header.Authorization = signPayload(
					accountId!,
					parentSecret!,
					parentSecretId,
					url,
					method,
					body,
				)
			}

			return {
				header,
				isAuthorized,
				requiresAuth,
			}
		},

		onAfterFetch: (_, reqId) => (dispatch, getState) => {
			const httpRetry = getHttpRetry(getState(), reqId)

			// If fetch is complete but not errored, the request went through, so remove the retry state
			if (httpRetry && !httpRetry.error) {
				dispatch(deleteHttpRetry(reqId))
			}
		},

		onError: (extra, error, reqId) => (dispatch, getState) => {
			sentryError(error)

			if (!reqId) return

			if (getHttpRetry(getState(), reqId)) {
				dispatch(updateHttpRetry(reqId, { error: true }))
			}
		},

		onRetry: (extra, reqId, retries) => (dispatch) => {
			dispatch(updateHttpRetry(reqId, { retries }))
		},

		// FIXME: Switch to dynamic url!
		normalizeUrl: async (pathOrUrl) => {
			if (pathOrUrl.startsWith('http')) return pathOrUrl

			const { sfsh } = await getEndpoints()!

			if (process.env.NODE_ENV === 'development' && !pathOrUrl.startsWith('/')) {
				throw new Error('Paths should start with "/" and urls with "http"')
			}

			return sfsh + pathOrUrl
		},
	},
	{},
)
