import { activeChildId$ } from '@op/services'
import { createResolverRedirect } from '../../common'
import { resolveSerialHasChildren } from '../../route-resolvers'
import type { AppStore } from '../../types'

export const resolveBaseLocatorPage = /*@__PURE__*/ resolveSerialHasChildren((store: AppStore) => {
	const state = store.getState()
	const activeChildId = activeChildId$(state)

	if (!activeChildId) {
		throw new Error('User state exists but no activeChildId for locator')
	}

	throw createResolverRedirect(`/locator/${activeChildId}`)
})
