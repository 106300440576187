import {
	isOnline,
	PASSIVE_CAPTURE,
	useFn,
	useInterval,
	useThrottle,
	useWindowEvent,
} from '@eturi/react'
import { bup } from '@op/react-web'
import { DEFAULT_LOCALE, getLangFromLocale, isSupportedLang } from '@op/services'
import { memo, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
	hasBrowserFocus$,
	isBrowserInfoInit$,
	isNetworkOnline$,
	setBrowserInfoInit,
	setBrowserLocale,
	setHasBrowserFocus,
	setNetworkOnline,
	setPathname,
	setUserAgent,
} from '../../reducers/browser-info.slice'
import { useAppDispatch } from '../../types'
import { getUserAgent, isAndroidParent } from '../../util'

export const BrowserInfo = () => {
	const d = useAppDispatch()
	const isBrowserInfoInit = useSelector(isBrowserInfoInit$)
	const isNetworkOnline = useSelector(isNetworkOnline$)
	const hasBrowserFocus = useSelector(hasBrowserFocus$)
	const { pathname } = useLocation()

	const handleNetworkChange = useFn(() => {
		if (isNetworkOnline !== isOnline()) d(setNetworkOnline(!isNetworkOnline))
	})

	// We do a visibility check on Android Parent since it may have issues w/
	// focus states, and we don't want to penalize other browsers w/ increased
	// polling for visibility.
	const handleVisibilityChange = useFn(() => {
		const hasFocus =
			document.hasFocus() || (isAndroidParent && document.visibilityState === 'visible')
		if (hasBrowserFocus !== hasFocus) d(setHasBrowserFocus(hasFocus))
	})

	// We throttle the online and offline events in case the internet connection
	// might toggle on and off very quickly
	const handleNetworkChangeThrottle = useThrottle(handleNetworkChange, 3000, {
		leading: true,
		trailing: true,
	})

	// It seems that global handlers don't always fire correctly on Android
	// so we'll check these manually every few seconds.
	useInterval(() => {
		handleNetworkChange()
		handleVisibilityChange()
	}, 3000)

	useWindowEvent('online', handleNetworkChangeThrottle, PASSIVE_CAPTURE)
	useWindowEvent('offline', handleNetworkChangeThrottle, PASSIVE_CAPTURE)
	useWindowEvent('focus', handleVisibilityChange, PASSIVE_CAPTURE)
	useWindowEvent('blur', handleVisibilityChange, PASSIVE_CAPTURE)

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange, PASSIVE_CAPTURE)
		return () =>
			document.removeEventListener('visibilitychange', handleVisibilityChange, PASSIVE_CAPTURE)
	}, [])

	useLayoutEffect(() => {
		d(setPathname(pathname))
	}, [pathname])

	useLayoutEffect(() => {
		if (isBrowserInfoInit) return

		bup(() => {
			handleNetworkChange()
			handleVisibilityChange()
			d(setBrowserLocale(getBrowserLocale()))
			d(setUserAgent(getUserAgent()))
			d(setBrowserInfoInit())
		})
	}, [isBrowserInfoInit])

	return null
}

const getBrowserLocale = (): string =>
	findLocaleFromNavLanguages() ||
	navigator.language ||
	(navigator as any).userLanguage ||
	DEFAULT_LOCALE

const findLocaleFromNavLanguages = (): Maybe<string> => {
	const { languages } = navigator

	if (!Array.isArray(languages)) return

	const lang = languages.find((l) => isSupportedLang(getLangFromLocale(l)))

	// Return first matching locale if found, otherwise return first locale.
	// Since this is intended to get the browser locale, and not necessarily
	// a supported locale. We want to try to use a supported locale, but if
	// it's not available, use the first one.
	return lang || languages[0]
}

export default /*@__PURE__*/ memo(BrowserInfo)
