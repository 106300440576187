import type { Lang } from '@op/services'
import type { InitOptions, LanguageDetectorModule, Services } from 'i18next'
import { LastLang } from './LastLang'

export class LangDetector implements LanguageDetectorModule {
	static readonly type = 'languageDetector'
	readonly type = 'languageDetector'
	services: Services
	i18nextOpts: InitOptions

	init(s: Services, _opts: object, opts: InitOptions = {}) {
		this.services = s
		this.i18nextOpts = opts
	}

	detect(): Lang {
		return LastLang.get()
	}

	cacheUserLanguage() {
		/* Not implemented */
	}
}
