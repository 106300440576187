import { useBoolState, useTimeout } from '@eturi/react'
import type { ReactNode } from 'react'
import { memo } from 'react'
import { ActionBtn, Btn, LoadingBtn } from '../components'
import type { ButtonBaseProps } from '../types'

//eslint-disable-next-line react/display-name
export const ButtonsTestPage = /*@__PURE__*/ memo(() => (
	<div className="is-page-pad-touch">
		<h2 className="mb-4 text-center">Buttons</h2>

		<TestBtnBlock title="Solid Background">
			<TestBtn color="teal">Teal</TestBtn>
			<TestBtn color="gray">Gray</TestBtn>
			<TestBtn color="red">Red</TestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Inverted">
			<TestBtn color="teal" invert>
				Teal
			</TestBtn>

			<TestBtn color="gray" invert>
				Gray
			</TestBtn>

			<TestBtn color="red" invert>
				Red
			</TestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Solid Background Icon Buttons">
			<ActionTestBtn color="teal" />
			<ActionTestBtn color="gray" />
			<ActionTestBtn color="red" />
		</TestBtnBlock>

		<TestBtnBlock title="Inverted Icon Buttons">
			<ActionTestBtn color="teal" invert />
			<ActionTestBtn color="gray" invert />
			<ActionTestBtn color="red" invert />
		</TestBtnBlock>

		<TestBtnBlock title="Loading Buttons">
			<LoadingTestBtn color="teal">Teal</LoadingTestBtn>
			<LoadingTestBtn color="gray">Gray</LoadingTestBtn>
			<LoadingTestBtn color="red">Red</LoadingTestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Inverted Loading Buttons">
			<LoadingTestBtn color="teal" invert>
				Teal
			</LoadingTestBtn>
			<LoadingTestBtn color="gray" invert>
				Gray
			</LoadingTestBtn>
			<LoadingTestBtn color="red" invert>
				Red
			</LoadingTestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Sizes">
			<TestBtn size="lg">Large</TestBtn>
			<TestBtn size="md">Medium (Default)</TestBtn>
			<TestBtn size="sm">Small</TestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Sizes: Icon Buttons">
			<ActionTestBtn size="lg" />
			<ActionTestBtn size="md" />
			<ActionTestBtn size="sm" />
		</TestBtnBlock>

		<TestBtnBlock title="Icon & Text">
			<ActionTestBtn size="lg">Test</ActionTestBtn>
			<ActionTestBtn size="md">Test</ActionTestBtn>
			<ActionTestBtn size="sm">Test</ActionTestBtn>
		</TestBtnBlock>

		<TestBtnBlock title="Fat Buttons">
			<TestBtn fat size="lg">
				Large & Fat
			</TestBtn>

			<TestBtn fat size="md">
				Medium & Fat
			</TestBtn>

			<TestBtn fat size="sm">
				Small & Fat
			</TestBtn>
		</TestBtnBlock>
	</div>
))

const TestBtnBlock = ({ children, title }: { children: ReactNode; title: string }) => (
	<>
		<h4 className="mb-3">{title}</h4>
		<div className="flex flex-wrap items-center justify-around my-4">{children}</div>
	</>
)

const TestBtn = (p: ButtonBaseProps) => {
	const handleClick = () => {
		console.log('Clicked', p.children)
	}

	return <Btn className="m-2" onClick={handleClick} {...p} />
}

const ActionTestBtn = (p: ButtonBaseProps) => {
	const handleClick = () => {
		console.log('Clicked', { color: p.color, invert: p.invert })
	}

	return <ActionBtn className="m-2" icon="app-rules" onClick={handleClick} {...p} />
}

const LoadingTestBtn = (p: ButtonBaseProps) => {
	const [isLoading, startLoading, stopLoading] = useBoolState(false)
	const [stopLoadingTimeout] = useTimeout()

	const handleClick = () => {
		console.log('Clicked', p.children)
		startLoading()
		stopLoadingTimeout(stopLoading, 3_500)
	}

	return <LoadingBtn className="m-2" isLoading={isLoading} onClick={handleClick} {...p} />
}
