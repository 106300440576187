import throttle from 'lodash/throttle'
import { v4 } from 'uuid'
import { asyncSessionStorage as asyncSS } from './async-storage'

export type ServerSub = 'hfbk' | 'sfsh'

export const getPrefixedEndpoint = (prefix: string, sub: ServerSub) => {
	if (process.env.APP_ENV !== 'dev') return ''

	const prefixedSub = prefix ? prefix + '-' + sub : sub

	return `https://${prefixedSub}.ourpact.com`
}

export const normalizePrefixUrl = (prefix: string, sub: ServerSub) =>
	process.env.APP_ENV !== 'dev'
		? ''
		: getPrefixedEndpoint(parseUrlPrefix(getPrefixedEndpoint(prefix, sub)), sub)

export const parseUrlPrefix = (url: string): string => {
	if (process.env.APP_ENV !== 'dev') return ''

	try {
		const parsedUrl = new URL(url)
		// FIXME: When strn/hfbk change to a redirect, or are removed, remove them
		//  from this regexp matcher.
		const prefixMatch = parsedUrl.hostname.match(
			/^([A-Z0-9-]+(?=-(?:app|hfbk|sfsh|strn)\.ourpact\.com$))/i,
		)

		return prefixMatch?.[1] || ''
	} catch {
		return ''
	}
}

const logSSErr = (key: string, action: 'read' | 'write' | 'delete') => (error: unknown) => {
	console.error(`Failed to ${action} storage key ${key}`, error)
}

// Async SessionStorage w/ try / catch built in
const asyncSSTry = {
	getItem: (k: string) => asyncSS.getItem(k).catch(logSSErr(k, 'read')),
	setItem: (k: string, v: string) => asyncSS.setItem(k, v).catch(logSSErr(k, 'write')),
	removeItem: (k: string) => asyncSS.removeItem(k).catch(logSSErr(k, 'delete')),
}

const EPH_ID_KEY = '__OURPACT_EPH__'

/**
 * Entity to get and clear the "session device id". This is really just a UUID
 * that we generate and keep for the session, since web doesn't have access to
 * a unique device id (udid). This is cleared when we log out and immediately
 * regenerated.
 *
 * Thus, `SessionDeviceId.get()`, is always the `getEphemeralId` promise, but
 * when `clear` is called, it's the `getEphemeralId` after waiting for the
 * previous id to be removed.
 */
export const SessionDeviceId = (() => {
	let ephemeralIdPromise: Promise<string> | null

	const createEphemeralId = async (): Promise<string> => {
		const ephemeralId = `eph_${v4()}`.slice(0, 36)

		return asyncSSTry.setItem(EPH_ID_KEY, ephemeralId).then(() => ephemeralId)
	}

	const getEphemeralId = async (): Promise<string> => {
		const ephemeralId = await asyncSSTry.getItem(EPH_ID_KEY)

		return ephemeralId || createEphemeralId()
	}

	return {
		clear: () => (ephemeralIdPromise = asyncSSTry.removeItem(EPH_ID_KEY).then(getEphemeralId)),
		get: () => (ephemeralIdPromise ||= getEphemeralId()),
	}
})()

export type ServerEndpoints = {
	readonly hfbk: string
	readonly sfsh: string
}

const HFBK_KEY = '__OURPACT_HFBK__'
const SFSH_KEY = '__OURPACT_SFSH__'

// Throttle this so it's not thrashing reads
export const getEndpoints = throttle(
	async (): Promise<ServerEndpoints> => {
		// Base endpoints come from env
		const endpoints: Writable<ServerEndpoints> = {
			hfbk: process.env.OURPACT_HFBK,
			sfsh: process.env.OURPACT_SFSH,
		}

		if (process.env.APP_ENV !== 'dev') return endpoints

		// If we're in dev, first try to parse the prefix from the url
		const prefix = parseUrlPrefix(window.location.href)

		// If we have a prefix, set the initial urls based on that
		if (prefix) {
			endpoints.hfbk = getPrefixedEndpoint(prefix, 'hfbk')
			endpoints.sfsh = getPrefixedEndpoint(prefix, 'sfsh')
		}

		// Finally, try to use endpoints from storage if available.

		const [hfbk, sfsh] = await Promise.all([
			asyncSSTry.getItem(HFBK_KEY),
			asyncSSTry.getItem(SFSH_KEY),
		])

		if (hfbk && sfsh) {
			endpoints.hfbk = hfbk
			endpoints.sfsh = sfsh
		}

		return endpoints
	},
	3000,
	{ leading: true, trailing: false },
)

// FIXME: Bridge w/ Android wrap
export const setEndpoints = async (endpoints: ServerEndpoints) => {
	if (process.env.APP_ENV !== 'dev') return

	await Promise.all([
		asyncSSTry.setItem(HFBK_KEY, endpoints.hfbk),
		asyncSSTry.setItem(SFSH_KEY, endpoints.sfsh),
	])

	// Clear out any waiting throttle so any immediately subsequent calls get
	// the updated values.
	getEndpoints.cancel()
}

export const ACCOUNT_TOKEN_EXPIRY_MINUTES = process.env.APP_ENV === 'dev' ? 5 : 20
