/**
 * Implements a Safari-safe memory storage so clients that block all cookies
 * don't fail w/ SecurityError
 */
class MemoryStorage implements Storage {
	private _store: Map<string, string> = new Map()

	get length(): number {
		return this._store.size
	}

	clear() {
		this._store.clear()
	}

	getItem(key: string) {
		return this._store.get(key) ?? null
	}

	key(index: number) {
		return [...this._store.keys()][index] ?? null
	}

	setItem(key: string, value: string) {
		this._store.set(key, value)
	}

	removeItem(key: string) {
		this._store.delete(key)
	}
}

/**
 * Returns a "block all cookies" safe Storage API.
 */
export const getSessionStorage = (() => {
	let memStorage

	return () => {
		try {
			return window.sessionStorage
		} catch {
			return (memStorage ||= new MemoryStorage())
		}
	}
})()

/**
 * Returns a "block all cookies" safe Storage API.
 */
export const getLocalStorage = (() => {
	let memStorage

	return () => {
		try {
			return window.localStorage
		} catch {
			return (memStorage ||= new MemoryStorage())
		}
	}
})()
