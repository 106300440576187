import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import type { PaymentSource } from './PaymentSource'

export type PaymentInfo = {
	readonly cardExp: Moment
	readonly cardholderName: string
	readonly lastFour: string
	readonly paymentCls: string
}

export type CardType =
	| 'american express'
	| 'diners club'
	| 'discover'
	| 'jcb'
	| 'mastercard'
	| 'visa'

export const mapBrandToCls = new Map<CardType, string>([
	['american express', 'amex'],
	['diners club', 'diners'],
	['discover', 'discover'],
	['jcb', 'jcb'],
	['mastercard', 'mastercard'],
	['visa', 'visa'],
])

export const paymentSourceToInfo = (source: Maybe<PaymentSource>): Maybe<PaymentInfo> => {
	if (!source) return null

	return {
		cardExp: moment({
			month: source.exp_month - 1, // JS indexes months with base-0, because why not...
			year: source.exp_year,
		}),
		cardholderName: source.name,
		lastFour: source.last4,
		paymentCls: mapBrandToCls.get(source.brand.toLowerCase() as CardType) || '',
	}
}
