import { selectUnaryEvery } from '@eturi/util'
import { accountLocale$, getLangFromLocale, hasAccountLocale$, normalizeLocale } from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import { browserLocale$, isBrowserInfoInit$ } from '../reducers/browser-info.slice'

const _localeOrLang$ = /*@__PURE__*/ createSelector(
	accountLocale$,
	browserLocale$,
	(al, bl): string => al || bl,
)

export const currentLang$ = /*@__PURE__*/ createSelector(_localeOrLang$, getLangFromLocale)
export const currentLocale$ = /*@__PURE__*/ createSelector(_localeOrLang$, normalizeLocale)
export const isLocaleInit$ = /*@__PURE__*/ createSelector(
	hasAccountLocale$,
	isBrowserInfoInit$,
	selectUnaryEvery,
)
