import { useStateGetter } from '@eturi/react'
import { accountId$ } from '@op/services'
import type { ReactNode } from 'react'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useIsScreenLG } from '../../hooks'
import { NavBarCtx } from './NavBarCtx'
import { NavCtx } from './NavCtx'

const DesktopMenuOpen = (() => {
	const key = (accountId: string) => `__OP_DESKTOP_MENU__:${accountId.slice(0, 6)}`

	return {
		get(accountId: string) {
			try {
				const value = window.localStorage.getItem(key(accountId))
				return (value ?? '1') === '1'
			} catch {
				return true
			}
		},

		set(accountId: string, isOpen: boolean) {
			try {
				window.localStorage.setItem(key(accountId), isOpen ? '1' : '0')
			} catch {
				//
			}
		},
	}
})()

export const NavProvider = ({ children }: { readonly children?: ReactNode }) => {
	const accountId = useSelector(accountId$)
	const isScreenLg = useIsScreenLG()
	const [isOpen, setOpen] = useState(() =>
		accountId ? DesktopMenuOpen.get(accountId) : isScreenLg,
	)
	const [navBar, setNavBar] = useStateGetter<ReactNode>(null)

	useLayoutEffect(() => {
		// On desktop, toggle if not matching persisted value
		if (isScreenLg) {
			let isPersistedOpen
			if (accountId && (isPersistedOpen = DesktopMenuOpen.get(accountId)) !== isOpen) {
				setOpen(isPersistedOpen)
			}
			// Close by default on mobile
		} else if (isOpen) {
			setOpen(false)
		}
	}, [accountId, isScreenLg])

	useEffect(() => {
		if (accountId && isScreenLg) {
			DesktopMenuOpen.set(accountId, isOpen)
		}
	}, [isOpen])

	const value = useMemo(
		(): NavCtx => ({
			isOpen,
			isDesktop: isScreenLg,
			isMobile: !isScreenLg,
			setNavBar,
			toggle() {
				setOpen(!isOpen)
			},
		}),
		[isOpen, isScreenLg],
	)

	return (
		<NavCtx.Provider value={value}>
			<NavBarCtx.Provider value={navBar()}>{children}</NavBarCtx.Provider>
		</NavCtx.Provider>
	)
}
