import { createSentryReduxEnhancer } from '@eturi/sentry'
import type { AccountState, AppsState, GeoState, PurchaseState, UserState } from '@op/services'
import {
	createPersistenceEnhancer,
	createRehydrateAuthState,
	httpUnauthorizedAction,
	persistState,
	resetAction,
} from '@op/services'
import { configureStore } from '@reduxjs/toolkit'
import { asyncSessionStorage } from './async-storage'
import { SessionDeviceId } from './env'
import { http } from './http'
import { rootReducer, sentryTransformer } from './reducers'
import type { AppMiscState } from './reducers/app-misc.slice'
import type { ChatState } from './reducers/chat.slice'
import type { GalleryUIState } from './reducers/gallery-ui.slice'
import type { GeoUIState } from './reducers/geo-ui.slice'
import { rehydrateGeoUIState } from './reducers/geo-ui.slice'
import type { PairState } from './reducers/pair.slice'
import { logoutAction } from './thunks/logout'
import { decryptPrivateKey } from './util/crypto'

const handleCustomActionsMiddleware = () => (next: any) => (action: any) => {
	if (httpUnauthorizedAction.match(action)) return next(logoutAction())
	if (resetAction.match(action)) {
		SessionDeviceId.clear()
	}

	return next(action)
}

// FIXME: Figure out why blockStatus, devices, etc., aren't persisted. Block
//  status makes some sense, but devices really doesn't. Same with notifs,
//  access. It seems like we could cache them as long as we have a state for
//  `isInitialFetchComplete`, so we know everything is up-to-date. Or just
//  make sure that for any actions, we have a fresh state.
export const store = /*@__PURE__*/ configureStore({
	enhancers: [
		createSentryReduxEnhancer(sentryTransformer),

		// NOTE: The `stateKey`s are used by the Android OurPactWrap, so any changes or
		//  additions should be reflected in that project if needed.
		createPersistenceEnhancer(
			[
				// NOTE: Yes this is a lot of keys to persist. I tested an implementation of persistence
				//  enhancer that uses `blockList`, but realized that this adds overhead due to the fact that
				//  we would have to be aware that, when adding any new key to a reducer, we make sure we're
				//  not using `blockList` instead of `allowList`. While this may seem similar to the overhead
				//  of `allowList`, it's more problematic from a security perspective to store a superfluous
				//  key, than to omit one.
				persistState<AccountState>('account', {
					allowList: [
						'account',
						'accountId',
						'email',
						'encryptedPrivate',
						'isLegacy',
						'parentSecret',
						'parentSecretId',
						'token',
						'tokenTS',
						'version',
						// Save password in dev environments. We have an audit script that will detect this and
						// make sure it's absent in production builds.
						...(process.env.APP_ENV === 'dev' && !location.search.includes('no-pw')
							? (['password'] as const)
							: []),
					],
					rehydrate: createRehydrateAuthState(decryptPrivateKey),
				}),

				persistState<AppMiscState>('appMisc', {
					allowList: ['redirect', 'wasAllowanceWelcomeModalShown', 'wasVewInstructionsModalShown'],
				}),
				persistState<AppsState>('apps', { allowList: ['appDetailsMap'] }),
				persistState<ChatState>('chat', { allowList: ['isEnabled'] }),
				persistState<GalleryUIState>('galleryUI', { allowList: ['askOnDelete'] }),
				persistState<GeoUIState>('geoUI', {
					allowList: [
						'deselectedUserIds',
						'isLocationHistoryEnabled',
						'isPlacesVisible',
						'mapTypeId',
					],
					rehydrate: rehydrateGeoUIState,
				}),
				// These keys are persisted so that if the user were to refresh while pairing,
				// we can maintain their pairing state and not have to restart the pairing flow
				persistState<PairState>('pair', {
					allowList: ['pairChildName', 'pairId', 'wasMdmDownloaded'],
				}),
				persistState<PurchaseState>('purchase', { allowList: ['info', 'lastFetchTs'] }),
				persistState<UserState>('user', { allowList: ['activeChildId', 'users'] }),
				persistState<GeoState>('geo', { allowList: ['locationAddressMap'] }),
			],
			asyncSessionStorage,
			window.OURPACT.onStateChange,
		),
	],

	middleware: (getDefaultMiddleware) => [
		handleCustomActionsMiddleware,
		...getDefaultMiddleware({
			thunk: { extraArgument: { http } },
			serializableCheck: false,
		}),
	],

	preloadedState: window.OURPACT.initialState,
	reducer: rootReducer,
})
