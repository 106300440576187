import { now } from '@eturi/date-util'
import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { AccountSKU, PurchaseInfo, PurchaseSummary, SThunkState } from '../types'
import { isPaidTier } from '../types'
import type { WithAccessState } from './access.slice'

export type PurchaseState = {
	readonly info: Maybe<PurchaseInfo>
	readonly lastFetchTs: number
	readonly summary: Maybe<PurchaseSummary>
}

export type WithPurchaseState = {
	readonly purchase: PurchaseState
}

const initialState: PurchaseState = {
	info: null,
	lastFetchTs: -1,
	summary: null,
}

export const purchaseSlice = /*@__PURE__*/ createSlice({
	name: 'purchase',
	initialState,
	reducers: {
		setPurchaseSummary(s, a: PayloadAction<PurchaseSummary>) {
			s.summary = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchPurchases.fulfilled, (s, { meta, payload: purchases }) => {
				if (!purchases) {
					s.info = null
					return
				}

				setIfNotEqual(s, 'info', findActivePurchase(purchases, meta.arg.nextSKU))
				s.lastFetchTs = Date.now()
			})
			.addCase(fetchPurchaseSummary.fulfilled, (s, a) => {
				s.summary = a.payload
			}),
})

export const { setPurchaseSummary } = purchaseSlice.actions

////////// Thunks //////////////////////////////////////////////////////////////

export type PurchaseThunkState = SThunkState & WithAccessState & WithPurchaseState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<PurchaseThunkState>()

type FetchPurchasesArg = HttpExtra & {
	readonly nextSKU: AccountSKU
}

export const fetchPurchases = /*@__PURE__*/ createAsyncThunk(
	'purchases/fetch',
	async ({ nextSKU, ...extra }: FetchPurchasesArg, { dispatch, extra: { http } }) => {
		if (!isPaidTier(nextSKU.tier_def)) return null

		const purchases = await dispatch(http.get<Maybe<PurchaseInfo[]>>('/purchase_info', extra))

		assertNotNullish(purchases, 'PurchaseInfo[]')

		return purchases
	},
	{
		condition: (arg, api) => {
			if (!arg.force && lastFetchPurchasesTs$(api.getState()) > +now.subtract(10, 'm')) return false
		},
	},
)

export const fetchPurchaseSummary = /*@__PURE__*/ createAsyncThunk(
	'purchases/fetch_purchase_summary',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const purchaseSummary = await dispatch(
			http.get<Maybe<PurchaseSummary>>('/purchase_info?query=summary', extra),
		)

		assertNotNullish(purchaseSummary, 'PurchaseInfo')

		return purchaseSummary
	},
	{
		condition(arg, api) {
			// If we've loaded a summary already, don't run unless forced
			if (!arg?.force && api.getState().purchase.summary) return false
		},
	},
)

const findActivePurchase = (purchases: PurchaseInfo[], sku: AccountSKU): Maybe<PurchaseInfo> =>
	find(purchases, { sku_id: sku.sku, status: 'account_active' })

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithPurchaseState>(s: T) => s.purchase

export const lastFetchPurchasesTs$ = /*@__PURE__*/ createSelector(state$, (s) => s.lastFetchTs)
export const purchaseInfo$ = /*@__PURE__*/ createSelector(state$, (s) => s.info)
export const isTrialEligible$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.summary?.is_trial_eligible,
)
