import reduce from 'lodash/reduce'
import type { DeviceOS, ManagedDevice } from './Device'
import type { GranDirectives, GranType } from './Granularity'

// Raw variants
export type RawAppsDataForDevices = {
	readonly [deviceId: string]: RawAppsData
}

export type RawInstalledApps = {
	readonly [bundleId: string]: RawDeviceApp
}

export type RawAppsData = {
	readonly installed_apps: RawInstalledApps
	readonly installed_apps_ts: number
	readonly os: DeviceOS
	readonly updated: boolean
}

export type RawDeviceApp = {
	readonly detailed_version: string
	readonly display_version: string
	readonly installer_package_name?: string
	readonly is_system?: boolean
	readonly supervision_required: boolean
	readonly title: string
}

// Decorated versions
export type AppRules = {
	readonly [appId: string]: DeviceApp
}

export type DeviceApp = RawDeviceApp & {
	readonly bundleId: string
	readonly description: Maybe<string>
	readonly deviceDisplayNames: string[]
	readonly granularity: GranType
	readonly is_system: boolean
	readonly os: DeviceOS
}

export type SpecialApp = ValueUnion<typeof SpecialApp>
export const SpecialApp = {
	APPLE_CAMERA: 'com.apple.camera',
	APPLE_FACETIME: 'com.apple.facetime',
	APPLE_COMBO: 'camera-facetime',
} as const

export const mapRawToDeviceApp = (
	app: RawDeviceApp,
	bundleId: string,
	appDirs: GranDirectives,
	device: ManagedDevice,
): DeviceApp => ({
	...app,
	bundleId,
	description: '',
	deviceDisplayNames: [device.displayName],
	granularity: appDirs[bundleId] || 'S',
	is_system: Boolean(app.is_system),
	os: device.os,
})

const RAW_SAMPLE_DEVICE_APP: RawDeviceApp = {
	detailed_version: '',
	display_version: '',
	is_system: false,
	supervision_required: true,
	title: '',
}

type BundleIdAndTitleTuple = [bundleId: string, title: string]

const SAMPLE_BUNDLE_IDS_AND_TITLES: BundleIdAndTitleTuple[] = [
	['com.facebook.Facebook', 'Facebook'],
	['com.burbn.instagram', 'Instagram'],
	['com.zhiliaoapp.musically', 'TikTok'],
	['com.mojang.minecraftpe', 'Minecraft'],
	['com.google.ios.youtube', 'YouTube'],
	['com.505games.terraria', 'Terraria'],
	['net.whatsapp.WhatsApp', 'WhatsApp'],
	['com.spotify.client', 'Spotify'],
	['com.toyopagroup.picaboo', 'Snapchat'],
]

const SAMPLE_INSTALLED_APPS = reduce(
	SAMPLE_BUNDLE_IDS_AND_TITLES,
	(apps: Writable<RawInstalledApps>, app) => {
		apps[app[0]] = RAW_SAMPLE_DEVICE_APP
		return apps
	},
	{},
)

export const RAW_SAMPLE_APP_DATA: RawAppsDataForDevices = {
	['MOCK_DEVICE']: {
		installed_apps: SAMPLE_INSTALLED_APPS,
		installed_apps_ts: Date.now(),
		os: 'iOS',
		updated: true,
	},
}

const SAMPLE_DEVICE_APP: DeviceApp = {
	bundleId: '',
	description: '',
	detailed_version: '',
	deviceDisplayNames: [''],
	display_version: '',
	granularity: 'S',
	is_system: false,
	os: 'iOS',
	supervision_required: true,
	title: '',
}

export const getSampleDeviceApps = (() => {
	let deviceApps: DeviceApp[] | null = null

	const createSampleDeviceApp = (bundleId: string, title: string): DeviceApp => ({
		...SAMPLE_DEVICE_APP,
		bundleId,
		title,
	})

	return () =>
		(deviceApps ||= SAMPLE_BUNDLE_IDS_AND_TITLES.map(([bundleId, title]) =>
			createSampleDeviceApp(bundleId, title),
		))
})()
