import type { SKUDef } from './SKUDef'
import { intToFloatPrice, toPriceStr } from './SKUDef'

export type BenefitApplyOnly = 'existing' | 'signup' | 'signup,existing'

// The current state of the coupon
export type BenefitStates = 'exhausted' | 'expired' | 'locked' | 'ready' | 'revoked'

export type BenefitAccountUpgrade = {
	// How long, in days, the SKU will be granted for
	readonly grant_days: number

	// The name of the SKU granted
	readonly grant_sku: string
}

export type BenefitExtendedTrial = {
	// Number of this benefit that are available
	readonly available_count: number

	// The number of seconds that this benefit will be available to the account
	// holder after it is redeemed. Basically, how long they have until they
	// need to initiate the Stripe purchase in order to get the deal. 0 for
	// unlimited.
	readonly available_secs?: number

	// May be limited to certain SKUs
	readonly limit_skus?: string[]

	// The length of the trial being granted in seconds
	readonly trial_secs: number
}

export type BenefitGroupMembership = {
	// Links the user to a given group
	readonly group_id: string

	// This link may expire at some point
	readonly group_secs: number
}

export type BenefitStripeCoupon = Pick<
	BenefitExtendedTrial,
	'available_count' | 'available_secs' | 'limit_skus'
> & {
	// Create the subscription with this code. Not sure what this is for.
	readonly stripe_code: string // INTERNAL use
	readonly stripe_percent_off?: number
	readonly stripe_duration_in_months?: number
}

export type BenefitScope = ValueUnion<typeof BenefitScope>
export const BenefitScope = {
	// Upgrade an account
	ACCOUNT_UPGRADE: 'ourpact.account_upgrade',

	// Grant a longer Stripe trial period
	EXTENDED_TRIAL: 'ourpact.extended_trial',

	// Grant group membership
	GROUP_MEMBERSHIP: 'ourpact.group_membership',

	// Apply a Stripe coupon code on subscribe
	STRIPE_COUPON: 'ourpact.stripe_coupon',
} as const

export type BenefitType = 'coupon' | 'group'

export type BaseBenefitGroup = {
	readonly allow_signup?: boolean
	readonly coupon_benefits?: null
	readonly coupon_code?: string
	readonly group_id: string
	readonly logo_url?: string
	readonly scopes: BenefitScope[]
}

export type AccountUpgradeGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitAccountUpgrade
}
export type ExtendedTrialGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitExtendedTrial
}
export type GroupMembershipGroup = BaseBenefitGroup & {
	readonly coupon_benefits: BenefitGroupMembership
}
export type StripeCouponGroup = BaseBenefitGroup & { readonly coupon_benefits: BenefitStripeCoupon }

export type BenefitGroup =
	| BaseBenefitGroup
	| AccountUpgradeGroup
	| ExtendedTrialGroup
	| GroupMembershipGroup
	| StripeCouponGroup

export type BaseBenefitDetails = {
	readonly apply_only: BenefitApplyOnly
	readonly scopes: BenefitScope[]
}

export type BenefitDetailsAccountUpgrade = BaseBenefitDetails & BenefitAccountUpgrade
export type BenefitDetailsExtendedTrial = BaseBenefitDetails & BenefitExtendedTrial
export type BenefitDetailsStripe = BaseBenefitDetails & BenefitStripeCoupon
export type BenefitDetailsGroup = BaseBenefitDetails & BenefitGroupMembership

export type BenefitDetails =
	| BenefitDetailsAccountUpgrade
	| BenefitDetailsExtendedTrial
	| BenefitDetailsGroup
	| BenefitDetailsStripe

export type SystemBenefit = {
	readonly created_ts: number // Not used
	readonly details: BenefitDetails
	// The coupon code
	readonly key: string
	readonly state: BenefitStates
	readonly type: BenefitType
	readonly updated_ts: number // Not used
}

export type AccountBenefitInfo = {
	// When the benefit was applied to the account
	readonly applied_ts: number
	// Not used. This would be when the coupon itself expires. Once it's applied
	// to an account though, this is irrelevant. To calculate how long the user
	// has to redeem we use `applied_ts` and `available_secs`
	readonly expiry_ts: Maybe<number>
	readonly type: BenefitType
}

export type AccountBenefit = SystemBenefit & { readonly account: AccountBenefitInfo }

const hasBenefitInScope = (b: { scopes: BenefitScope[] }, scope: BenefitScope) =>
	b.scopes.includes(scope)

/**
 * These are called on return from '/coupon_details': `AccountBenefit` details.
 * @see AccountBenefit
 * @example
 * ```
 * const accountBenefit = accountBenefit$(state);
 * const details = get(accountBenefit, 'details');
 * const isExtended = details && isExtendedTrialBenefitDetails(details);
 * ```
 */
const isBenefitDetailScope =
	<T extends BenefitDetails>(s: BenefitScope) =>
	<B extends BaseBenefitDetails>(b?: B): b is B & T =>
		!!b && hasBenefitInScope(b, s)

const isAccountBenefitDetails =
	<T extends BenefitDetails>(scope: BenefitScope) =>
	<B extends AccountBenefit>(b: Maybe<B>): b is B & { readonly details: T } =>
		!!b && hasBenefitInScope(b.details, scope)

/**
 * This is called on return from group_details: `BenefitGroup`.
 * @see BenefitGroup
 * @example
 * ```
 * const benefitGroup = benefitGroup$(state);
 * const isExtended = benefitGroup && isExtendedTrialGroup(benefitGroup);
 * ```
 */
//const hasBenefitInGroup =
//	<T extends BenefitGroup>(s: BenefitScope) =>
//	<B extends BaseBenefitGroup>(b: Maybe<B>): b is B & T =>
//		!!b?.coupon_benefits && hasBenefitInScope(b, s)

//export const isAccountUpgradeBenefitDetails =
//	/*@__PURE__*/ isBenefitDetailScope<BenefitDetailsAccountUpgrade>(BenefitScope.ACCOUNT_UPGRADE)
//export const isExtendedTrialBenefitDetails =
//	/*@__PURE__*/ isBenefitDetailScope<BenefitDetailsExtendedTrial>(BenefitScope.EXTENDED_TRIAL)
//export const isGroupBenefitDetails = /*@__PURE__*/ isBenefitDetailScope<BenefitDetailsGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
export const isStripeBenefitDetails = /*@__PURE__*/ isBenefitDetailScope<BenefitDetailsStripe>(
	BenefitScope.STRIPE_COUPON,
)

//export const isAccountUpgradeBenefit =
//	/*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsAccountUpgrade>(BenefitScope.ACCOUNT_UPGRADE)
export const isExtendedTrialBenefit =
	/*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsExtendedTrial>(BenefitScope.EXTENDED_TRIAL)
//export const isGroupBenefit = /*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
export const isStripeCouponBenefit = /*@__PURE__*/ isAccountBenefitDetails<BenefitDetailsStripe>(
	BenefitScope.STRIPE_COUPON,
)

//export const isAccountUpgradeGroup = /*@__PURE__*/ hasBenefitInGroup<AccountUpgradeGroup>(
//	BenefitScope.ACCOUNT_UPGRADE,
//)
//export const isExtendedTrialGroup = /*@__PURE__*/ hasBenefitInGroup<ExtendedTrialGroup>(
//	BenefitScope.EXTENDED_TRIAL,
//)
//export const isGroupMembershipGroup = /*@__PURE__*/ hasBenefitInGroup<GroupMembershipGroup>(
//	BenefitScope.GROUP_MEMBERSHIP,
//)
//export const isStripeCouponGroup = /*@__PURE__*/ hasBenefitInGroup<StripeCouponGroup>(
//	BenefitScope.STRIPE_COUPON,
//)

const getSKUPriceWithBenefit = (
	benefit: Maybe<AccountBenefit>,
	sku: Maybe<SKUDef>,
	isStripe: boolean,
) => {
	const discountMod =
		isStripe && isStripeCouponBenefit(benefit)
			? (100 - (benefit.details.stripe_percent_off || 0)) / 100
			: 1

	return intToFloatPrice(discountMod * (sku?.price_usd || 0))
}

export const getSKUPriceStrWithBenefit = (
	benefit: Maybe<AccountBenefit>,
	sku: Maybe<SKUDef>,
	isStripe: boolean,
) => toPriceStr(getSKUPriceWithBenefit(benefit, sku, isStripe))
