import { Redirect, Route, Switch } from 'react-router-dom'
import { Link } from '../common'
import { ButtonsTestPage } from './ButtonsTestPage'
import { DeviceTestPage } from './DeviceTestPage'
import { RadioCheckTestPage } from './RadioCheckTestPage'

const BaseTestRoute = () => (
	<div className="flex flex-col">
		<h2>Select a page</h2>
		<Link to="/test/buttons">Buttons</Link>
		<Link to="/test/radio-check">Form Radio / Checkboxes</Link>
		<Link to="/test/device-status">Device Status</Link>
	</div>
)

export const TestPage = () => (
	<div>
		<Switch>
			<Route component={BaseTestRoute} path="/test/select" />

			<Route component={RadioCheckTestPage} path="/test/radio-check" />

			<Route component={DeviceTestPage} path="/test/device-status" />

			<Route component={ButtonsTestPage} path="/test/buttons" />

			<Redirect to="/test/select" />
		</Switch>
	</div>
)
