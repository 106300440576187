import { mod } from '@eturi/util'
import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import type { PartialRawRule, RawRule, Rule, WithRuleType } from './Rule'

export type ScheduleRuleType<T extends PartialRawRule> = T & WithRuleType<'temporal'>

export type Schedule = MOmit<Rule, 'type'> &
	WithRuleType<'temporal'> & {
		readonly days: ScheduleDays
		readonly recurring: boolean
		readonly time_duration: string
	}

export type ScheduleDuration = {
	readonly duration: number
	readonly hour: number
	readonly minute: number
}

export type StartTime = Moment
export type EndTime = Moment
export type TimeInterval = [StartTime, EndTime]

export type ScheduleDay = 0 | 1
export type ScheduleDays = [
	sunday: ScheduleDay,
	monday: ScheduleDay,
	tuesday: ScheduleDay,
	wednesday: ScheduleDay,
	thursday: ScheduleDay,
	friday: ScheduleDay,
	saturday: ScheduleDay,
]

export const ONE_WEEK_ROLLOVER = 'one_week_rollover' as const
export const SCHEDULE_TIME_FORMAT = 'HH:mm'
// Schedule duration increments in minutes
export const SCHEDULE_DURATION_INCREMENTS = 1
// One day in minutes
const MAX_SCHEDULE_DURATION = 1440

export const DEFAULT_SCHEDULE_DAYS: ScheduleDays = [0, 0, 0, 0, 0, 0, 0]

// FIXME: Test both in and out
export const normalizeScheduleDays = (days: string | ScheduleDays): ScheduleDays =>
	DEFAULT_SCHEDULE_DAYS.map((d, i) => (Number(days[i]) ? 1 : 0)) as ScheduleDays

export const isScheduleRule = <T extends PartialRawRule>(r: T): r is ScheduleRuleType<T> =>
	r.type === 'temporal'

export const rawRuleToSchedule = (r: ScheduleRuleType<RawRule>): Schedule => ({
	...r,
	days: normalizeScheduleDays(r.days || ''),
	// If we send `one_week_rollover` to the server, it returns an expiry time,
	// so we can consider it recurring if it's null (doesn't have the expiry).
	// FIXME: I found the expiry can be an empty string now as well! Need to talk
	//  to other engineers about this.
	recurring: !r.expiry,
	suspended: !!r.suspended,
	time_duration: r.time_duration || '21:00PT480M',
})

export const scheduleToRawRule = (s: Schedule): RawRule => {
	const rule: Writable<WithOptional<Schedule, 'recurring'>> = { ...s }

	delete rule.recurring

	const isNewSchedule = !rule.rule_id

	return {
		...rule,
		active: isNewSchedule || rule.active,
		// NOTE: We previously didn't make sure `RuleDays` wasn't sparse. Otherwise,
		//  we could just join as is.
		days: normalizeScheduleDays(rule.days).join(''),
		// We send a special string when we don't want a recurring schedule. The
		// server takes this to calculate the expiry, which it returns in the rule.
		expiry: s.recurring ? null : ONE_WEEK_ROLLOVER,
	}
}

type TimeInput = Moment | Date | string

const _normalizeTimeToMoment = (time: TimeInput): Moment =>
	moment.isMoment(time)
		? time
		: moment.isDate(time)
		? moment(time)
		: parseFormattedTimeToMoment(time)

export const parseFormattedTimeToMoment = (time: string) => moment(time, SCHEDULE_TIME_FORMAT)

export const areTimesOverlapping = (start: TimeInput, end: TimeInput) => {
	const startCmp = _normalizeTimeToMoment(start)
	const endCmp = _normalizeTimeToMoment(end)

	return endCmp <= startCmp
}

/** Formats a start and end time to what the server understands for duration */
export const formatServerDuration = (start: TimeInput, end: TimeInput) => {
	start = _normalizeTimeToMoment(start)
	end = _normalizeTimeToMoment(end).clone()

	if (areTimesOverlapping(start, end)) end.add(1, 'days')

	const startFormatted = start.format(SCHEDULE_TIME_FORMAT)

	const dur = moment.duration(+end - +start).as('minutes')

	// Technically, it's possible for `dur` to be a decimal if the inputs are
	// fractionally off. The mod will normalize this to 1 minute increment and
	// the `floor` is only used b/c subtraction could theoretically run into
	// floating point precision.
	const durNormalizedToIncrement = Math.floor(dur - (dur % SCHEDULE_DURATION_INCREMENTS))

	// Also, some clients seem to be able to send > 1440, so we normalize on that
	// Since the `durNormalizedToIncrement` isn't a decimal, we shouldn't need to
	// deal w/ FP.
	const durNormalized =
		durNormalizedToIncrement > MAX_SCHEDULE_DURATION
			? durNormalizedToIncrement % MAX_SCHEDULE_DURATION
			: durNormalizedToIncrement

	return `${startFormatted}PT${durNormalized}M`
}

export const isValidScheduleDuration = (timeDuration = '') => /\d+:\d+PT\d+M/.test(timeDuration)

/** Parses the server duration into a Duration object */
export const parseTimeDuration = (isoString: string): ScheduleDuration => {
	const [hourMinutes, duration] = isoString.split('PT')
	const [hours, minutes] = hourMinutes.split(':')

	return {
		duration: Number.parseInt(duration.replace('M', '')),
		hour: Number.parseInt(hours),
		minute: Number.parseInt(minutes),
	}
}

export const parseTimeIntervalFromDuration = (timeDuration: string): TimeInterval => {
	const { hour, minute, duration } = parseTimeDuration(timeDuration)
	const start = moment({ hour, minute })

	return [start, start.clone().add(duration, 'minutes')]
}

/**
 * Alex found two problems with v1 implementation:
 *
 * For testing used Australia/Sydney tz to get the next day from UTC (e.g., tz
 * weekday local is Tuesday, while UTC is Monday).
 *
 * **First Issue - Wrong `rule_name`:**
 *
 * To catch this bug we need to have two schedules with rollover to the next
 * day. For example:
 *
 * - `Schedule1`: 23:15 - 3:15 (weekday set to current day)
 * - `Schedule2`: 9:00 - 8:00 (weekday set to previous day)
 *
 * The issue presents as follows:
 *
 * If only `Schedule1` is on (state: ACCESS_GRANTED):
 *   - `isScheduleApplied === true` AND
 *   - `isActiveUserScheduleActive$ === false`
 *
 * If only `Schedule2` is on (state: ACCESS_BLOCKED w/ correct rule name):
 *   - `isScheduleApplied === true` AND
 *   - `isActiveUserScheduleActive$ === true`
 *
 * If BOTH schedules are on (state: first rule name incorrectly return w/
 *   ACCESS_BLOCKED):
 *   - `isScheduleApplied === true` for first rule
 *
 * **Second Issue -  No schedules applied:**
 *
 * To catch this bug, we need tz with big offset to UTC, so that start and end
 * both show the previous date to UTC. In this case:
 *
 * - `isScheduleApplied === false` AND
 * - `isActiveUserScheduleActive$ === true`
 */
export const isScheduleApplied = (() => {
	/**
	 * Creates a fast string comparator, equivalent to moment.format('HHmm')
	 */
	const _getScheduleCmp = (d: Moment) => {
		const h = `${d.hour()}`.padStart(2, '0')
		const m = `${d.minute()}`.padStart(2, '0')

		return h + m
	}

	/** Whether schedule is enabled for this day of week */
	const _isEnabled = (schedule: Schedule, day: number) => schedule.days[day] === 1

	/**
	 * Whether the schedule would run now:
	 * - If schedule begins before (or exactly) now AND
	 *   - If schedule ends after (or exactly at) now OR
	 *   - If schedule ends before start (rolls over to next day)
	 */
	const _isScheduledNow = (now: string, start: string, end: string) =>
		start <= now && (end >= now || start > end)

	/**
	 * Whether schedule rolled over from prev day:
	 * - If schedule start time is after end time AND
	 * - If schedule start time is after now AND
	 * - If schedule ends after, or exactly at, now
	 */
	const _isRolledOverFromPrev = (now: string, start: string, end: string) =>
		start > end && start > now && now <= end

	return (schedule: Schedule, tz: Maybe<string>) => {
		if (!(tz && schedule.active)) return false

		const { duration, hour, minute } = parseTimeDuration(schedule.time_duration)
		const nowMoment = moment.tz(tz)
		const currDayOfWeek = nowMoment.day()
		const isEnabledToday = _isEnabled(schedule, currDayOfWeek)
		const isEnabledYesterday = _isEnabled(schedule, mod(currDayOfWeek - 1, 7))

		// Quick check: Schedule is definitely not applied if it's not at least
		// enabled today or yesterday.
		if (!(isEnabledToday || isEnabledYesterday)) return false

		// Do more expensive comparisons:
		const startMoment = moment.tz({ hour, minute }, tz)
		const endMoment = startMoment.clone().add(duration, 'm')

		// Get fast string time comparators
		const nowCmp = _getScheduleCmp(nowMoment)
		const startCmp = _getScheduleCmp(startMoment)
		const endCmp = _getScheduleCmp(endMoment)

		if (isEnabledToday && _isScheduledNow(nowCmp, startCmp, endCmp)) return true

		return isEnabledYesterday && _isRolledOverFromPrev(nowCmp, startCmp, endCmp)
	}
})()

export type AppliedSchedules = [
	appliedSchedule: Maybe<Schedule>,
	laterTodaySchedule: Maybe<Schedule>,
]

export const getAppliedSchedules = (() => {
	/**
	 * Creates a fast string comparator, equivalent to moment.format('HHmm')
	 */
	const _getScheduleCmp = (d: Moment) => {
		const h = `${d.hour()}`.padStart(2, '0')
		const m = `${d.minute()}`.padStart(2, '0')

		return h + m
	}

	/** Whether schedule is enabled for this day of week */
	const _isEnabled = (schedule: Schedule, day: number) => schedule.days[day] === 1

	/**
	 * Whether the schedule would run now:
	 * - If schedule begins before (or exactly) now AND
	 *   - If schedule ends after (or exactly at) now OR
	 *   - If schedule ends before start (rolls over to next day)
	 */
	const _isScheduledNow = (now: string, start: string, end: string) =>
		start <= now && (end >= now || start > end)

	/**
	 * Whether schedule rolled over from prev day:
	 * - If schedule start time is after end time AND
	 * - If schedule start time is after now AND
	 * - If schedule ends after, or exactly at, now
	 */
	const _isRolledOverFromPrev = (now: string, start: string, end: string) =>
		start > end && start > now && now <= end

	return (schedules: Schedule[], tz: Maybe<string>): AppliedSchedules => {
		if (!tz) return [null, null]

		const activeSchedules = schedules.filter((s) => s.active)

		if (!activeSchedules.length) return [null, null]

		const nowMoment = moment.tz(tz)
		const currDayOfWeek = nowMoment.day()
		let nearestScheduleLaterToday: Maybe<Schedule> = null
		let nearestStartCmp: string | null = null

		for (const schedule of activeSchedules) {
			const { duration, hour, minute } = parseTimeDuration(schedule.time_duration)

			const isEnabledToday = _isEnabled(schedule, currDayOfWeek)
			const isEnabledYesterday = _isEnabled(schedule, mod(currDayOfWeek - 1, 7))

			if (!(isEnabledToday || isEnabledYesterday)) continue

			// Do more expensive comparisons:
			const startMoment = moment.tz({ hour, minute }, tz)
			const endMoment = startMoment.clone().add(duration, 'm')

			// Get fast string time comparators
			const nowCmp = _getScheduleCmp(nowMoment)
			const startCmp = _getScheduleCmp(startMoment)
			const endCmp = _getScheduleCmp(endMoment)

			// If schedule is applied
			if (
				(isEnabledToday && _isScheduledNow(nowCmp, startCmp, endCmp)) ||
				(isEnabledYesterday && _isRolledOverFromPrev(nowCmp, startCmp, endCmp))
			) {
				return [schedule, null]
			}

			if (!(isEnabledToday && startCmp > nowCmp)) continue

			if (nearestStartCmp) {
				if (startCmp < nearestStartCmp) {
					nearestStartCmp = startCmp
					nearestScheduleLaterToday = schedule
				}
			} else {
				nearestStartCmp = startCmp
				nearestScheduleLaterToday = schedule
			}
		}

		return [null, nearestScheduleLaterToday]
	}
})()
