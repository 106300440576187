import { useConstant, useFn } from '@eturi/react'
import {
	fetchAccessState,
	fetchCouponDetails,
	fetchDevices,
	fetchPaymentSource,
	fetchPurchases,
	nextSKU$,
	unwrapThunks,
} from '@op/services'
import type { ReactNode } from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { shouldHandleWithGoogleIAP$, subscribingSKU$ } from '../../compound-selectors/product'
import { useHandleError } from '../../hooks'
import { closeProductModal } from '../../reducers/product.slice'
import { purchaseGoogleSKUAction } from '../../thunks/google-purchase'
import { isFatalIAPError, isGoogleBillingError, useAppDispatch } from '../../types'
import type { ChangeSubCtx } from './ChangeSubCtx'
import { ChangeSubContext } from './ChangeSubCtx'

/**
 * This is a set of helpers for doing common subscription change operations
 * (upgrade, downgrade, cancel, etc).
 */
export const ChangeSub = (p: { readonly children?: ReactNode }) => {
	const d = useAppDispatch()
	const nextBilledSKU = useSelector(nextSKU$)
	const shouldHandleWithGoogleIAP = useSelector(shouldHandleWithGoogleIAP$)
	const subscribingSKU = useSelector(subscribingSKU$)

	const [redirect, setRedirect] = useState<Maybe<string>>(null)
	const handleGenericError = useHandleError()

	// Reset redirect after it runs once
	useEffect(() => {
		redirect && setRedirect(null)
	}, [redirect])

	const handleError = (e: any) => {
		if (!isGoogleBillingError(e) || isFatalIAPError(e)) handleGenericError(e)
	}

	// NOTE: We separate out the handling of refresh so we can show a toast error
	//  that is distinct from the main call if it fails. So a change can be
	//  successful and still get an error toast.
	const refreshDataModal = async () => {
		try {
			await Promise.all(
				unwrapThunks([
					d(fetchPurchases({ nextSKU: nextBilledSKU, force: true })),
					d(fetchAccessState({ force: true })),
					d(fetchCouponDetails({ force: true })),
					d(fetchDevices({ force: true })),
					d(fetchPaymentSource({ force: true })),
				]),
			)
		} catch (e) {
			handleError(e)
		}
	}

	const handleSubChange: ChangeSubCtx['handleSubChange'] = useFn(
		async (changeSub, successRoute) => {
			try {
				await changeSub()
				await refreshDataModal()

				setRedirect(successRoute)

				d(closeProductModal())

				return true
			} catch (e) {
				handleError(e)
			}

			return false
		},
	)

	const handleGoogleIAP = useFn(async () => {
		if (!(subscribingSKU && shouldHandleWithGoogleIAP)) return

		const { source, sku: nextSKU } = nextBilledSKU
		const oldSKU = source === 'google' ? nextSKU : null

		return d(purchaseGoogleSKUAction(subscribingSKU.sku, oldSKU))
	})

	const initSubChangeView: ChangeSubCtx['initSubChangeView'] = useFn(async () => {
		await Promise.all(
			unwrapThunks([d(fetchAccessState()), d(fetchDevices()), d(fetchPaymentSource())]),
		)
	})

	const value = useConstant(
		(): ChangeSubCtx => ({
			handleGoogleIAP,
			handleSubChange,
			initSubChangeView,
		}),
	)

	return (
		<ChangeSubContext.Provider value={value}>
			{p.children}
			{redirect && <Redirect to={redirect} />}
		</ChangeSubContext.Provider>
	)
}
