import { lazy } from 'react'

export const VerifyEmailPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'verify-email' */
			/* webpackPrefetch: 10 */
			'./VerifyEmailPage'
		),
)
