import './HybridModal.scss'

import { useFn } from '@eturi/react'
import { useBackButton } from '@op/react-web'
import cls from 'classnames'
import { useIsScreenMobile } from '../../hooks'
import { ActionBtn } from '../Buttons'
import { NavBar } from '../Navigation'
import type { ModalProps } from './Modal'
import { Modal } from './Modal'

export type HybridModalProps = MOmit<WithRequired<ModalProps, 'onClose'>, 'header'> & {
	readonly header: string
}

/**
 * A hybrid component that is a modal when the screen is MD or larger, but
 * takes up the full page view in smaller layouts.
 */
export const HybridModal = (p: HybridModalProps) => {
	const isScreenMobile = useIsScreenMobile()

	// NOTE: Careful when going from hybrid modal to hybrid modal if they
	//  both share the same display state i.e Guard Modals. In Mobile
	//  useBackButton will cause a race condition where one hook starts
	//  listening to history before the previous hook calls history.goBack
	//  (unmount) this will trigger the new hooks new to call onClose causing
	//  the new hybrid modal to immediately close
	useBackButton(p.onClose, !isScreenMobile)

	const renderMobileHeader = useFn(() => (
		<nav className="top-nav-wrapper bg-white sticky left-0 right-0 top-0 z-hack">
			<div className="top-nav flex h-full items-center relative">
				<ActionBtn icon="chevron-left" invert onClick={p.onClose} />
				<div className="flex-auto" />
				<NavBar.Title>{p.header}</NavBar.Title>
				<div className="flex-auto" />
				<div className="btn invisible" />
			</div>
		</nav>
	))

	return (
		<Modal
			{...p}
			className={cls('modal--hybrid', p.className)}
			header={isScreenMobile ? renderMobileHeader : p.header}
		/>
	)
}
