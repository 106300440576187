import { microtask } from '@eturi/util'
import { getLocalStorage, getSessionStorage } from '@op/react-web'
import type { AsyncStorage } from '@op/services'

const asyncifyStorage = (storage: Storage): AsyncStorage => ({
	getItem(key: string): Promise<string | null> {
		return microtask(() => storage.getItem(key))
	},

	removeItem(key: string): Promise<void> {
		return microtask(() => storage.removeItem(key))
	},

	setItem(key: string, value: string): Promise<void> {
		return microtask(() => storage.setItem(key, value))
	},
})

export const asyncLocalStorage = /* @__PURE__ */ asyncifyStorage(/*@__PURE__*/ getLocalStorage())
export const asyncSessionStorage = /* @__PURE__ */ asyncifyStorage(
	/*@__PURE__*/ getSessionStorage(),
)
