import { PASSIVE, useConstant, useFn, useRefGetter, useWindowEvent } from '@eturi/react'
import { ScrollHelper } from '@op/react-web'
import type { ReactNode } from 'react'
import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useBodyClass } from '../../hooks'
import { isScrollFrozen$ } from '../../reducers/app-misc.slice'
import { useAppOverlayVis } from '../Overlay'
import { AppScrollContext } from './AppScrollContext'

/**
 * Component that monitors the scroll position of the window and then freezes
 * scrolling when the `isScrollFrozen` state is true. This occurs when an
 * overlay is on the screen, and we want to block scrolling but maintain the
 * scroll position of the window.
 *
 * To do this, we first save the last known `scrollY` to the `frozenScrollY`.
 * Then we set the body `position` to `fixed` and the `top` to the negative
 * `scrollY`. When setting the body to `position: fixed`, the scrollbars are
 * removed, but this automatically sets the `scrollTop` to 0, so the app would
 * lose its scroll position. By setting the `top` we then move the viewport
 * back to its original position.
 *
 * Finally, when the scroll is unfrozen, we can clear style overrides and
 * use the `frozenScrollY` to scroll the window to its previous values. Note
 * that we have to use a separate `frozenScrollY` because our scroll listener
 * will fire and change the scroll position when we set the body to `fixed`.
 * So we have one reference to track the scroll position using events, and the
 * other tracks it when it's frozen.
 */
export const AppScrollHelper = ({ children }: { readonly children?: ReactNode }) => {
	const isAppOverlayVisible = useAppOverlayVis()
	const _isScrollFrozen = useSelector(isScrollFrozen$)
	const [scrollY, setScrollY] = useRefGetter(0)
	const [frozenScrollY, setFrozenScrollY] = useRefGetter(0)

	const $body = useConstant(() => document.body)
	const $docEl = useConstant(() => document.documentElement)
	const scrollHelpers = useConstant(() => new Map<string, ScrollHelper>())

	const isScrollFrozen = isAppOverlayVisible || _isScrollFrozen

	const add = useFn((id: string, $scrollEl?: HTMLElement) => {
		const prevScrollHelper = scrollHelpers.get(id)

		// If we already have a ScrollHelper with this id, update the scroll element
		if (prevScrollHelper) {
			prevScrollHelper.setScrollElement($scrollEl)
			return prevScrollHelper
		}

		const scrollHelper = new ScrollHelper($scrollEl, isScrollFrozen, frozenScrollY())

		scrollHelpers.set(id, scrollHelper)

		return scrollHelper
	})

	const remove = useFn((id: string) => {
		scrollHelpers.delete(id)
	})

	const CtxValue = useConstant(() => ({ add, remove }))

	useBodyClass('is-scroll-frozen', isScrollFrozen)

	// Whether we're both frozen and have a scrollbar present.
	const hasFrozenScrollBar = useMemo(
		() => isScrollFrozen && $docEl.offsetWidth !== window.innerWidth,
		[isScrollFrozen],
	)

	// Special class for when we freeze and had a scrollbar. This allows CSS
	// to offset by the scrollbar width using padding-right.
	// NOTE: This doesn't account for resizing the window. We only know that the
	//  window had a scrollbar when scroll freeze is set to true. Once scrolling
	//  is frozen, the body is position:fixed and there are no scrollbars on the
	//  app frame (there can be within other fixed position elements on screen).
	//  So if the window is resized, we can't really tell if the underlying view
	//  _would_ have a scrollbar without removing the position: fixed. Also, the
	//  current scrolling position is stored so it can be restored, and resizing
	//  totally screws this up. There's not really anything we can do, at least
	//  nothing that would be worth the marginal benefits. Most users probably
	//  don't resize windows a lot, or don't do it w/ scrolling frozen, or if
	//  they do both things, don't likely care if positioning is slightly off
	//  since that's pretty much always a side effect of resizing.
	useBodyClass('is-scroll-frozen--sb', hasFrozenScrollBar)

	// NOTE: May need to detect shitty iOS and ignore scrolling when frozen?
	useWindowEvent(
		'scroll',
		() => {
			setScrollY(window.scrollY)
		},
		PASSIVE,
	)

	useLayoutEffect(() => {
		if (isScrollFrozen) {
			const sY = scrollY()

			scrollHelpers.forEach((s) => {
				s.isScrollFrozen = true
				s.frozenScrollY = sY
			})

			setFrozenScrollY(sY)

			Object.assign($body.style, {
				left: '0',
				position: 'fixed',
				right: '0',
				top: `-${scrollY()}px`,
			})

			return
		}

		scrollHelpers.forEach((s) => {
			s.isScrollFrozen = false
		})

		Object.assign($body.style, {
			left: '',
			position: '',
			right: '',
			top: '',
		})

		window.scrollTo(0, frozenScrollY())
	}, [isScrollFrozen])

	useLayoutEffect(() => {
		const $container = document.createElement('div')
		const $measure = document.createElement('div')
		const cs = $container.style
		const ms = $measure.style

		cs.width = cs.height = '100px'
		ms.width = ms.height = '100%'
		$container.appendChild($measure)
		$body.appendChild($container)

		const m1 = $measure.offsetWidth
		cs.overflow = 'scroll'
		const scrollbarWidth = m1 - $measure.offsetWidth

		$docEl.style.setProperty('--sb', `${scrollbarWidth}px`)
		$container.remove()
	}, [])

	return <AppScrollContext.Provider value={CtxValue}>{children}</AppScrollContext.Provider>
}
