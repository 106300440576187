import type { PaidTier, SKUDef } from '@op/services'
import {
	availableBenefit$,
	getSKUPriceStrWithBenefit,
	isExtendedTrialBenefit,
	isPlatformSKU,
	isStripeCouponBenefit,
	mapTierToFullName,
	mapTierToName,
	skuDefs$,
	stripeSKUPrem$,
	stripeSKUPremPlus$,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { CANCEL_PREM_FEATURES, CANCEL_PREM_PLUS_FEATURES } from '../types'
import { isSubscribingStripe$, subscribingSKU$ } from './product'

export const subscribingPriceStr$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	subscribingSKU$,
	isSubscribingStripe$,
	getSKUPriceStrWithBenefit,
)

export const benefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefit$,
	skuDefs$,
	stripeSKUPrem$,
	stripeSKUPremPlus$,
	(benefit, skuDefs, stripeSKUPrem, stripeSKUPremPlus): Maybe<SKUDef> => {
		if (!(isExtendedTrialBenefit(benefit) || isStripeCouponBenefit(benefit))) return

		const benefitSKUs = benefit.details.limit_skus

		if (isEmpty(benefitSKUs)) return stripeSKUPremPlus

		const stripeSKUs = skuDefs.filter((s) => isPlatformSKU(s, 'stripe'))

		// `benefitSKUs` is an array if it's not empty, which we checked above
		return benefitSKUs!.reduce((subscribingSKU: SKUDef, sku) => {
			const newDef = stripeSKUs.find((s) => s.sku === sku)
			return newDef && newDef.weight > subscribingSKU.weight ? newDef : subscribingSKU
		}, stripeSKUPrem)
	},
)

export const benefitSKUId$ = /*@__PURE__*/ createSelector(benefitSKU$, (s) => s?.sku || '')
export const benefitSKUTier$ = /*@__PURE__*/ createSelector(
	benefitSKU$,
	(s) => s?.tier_def as Maybe<PaidTier>,
)
export const benefitSKUName$ = /*@__PURE__*/ createSelector(benefitSKUTier$, mapTierToName)
export const benefitSKUFullName$ = /*@__PURE__*/ createSelector(benefitSKUTier$, mapTierToFullName)
export const hasPromoBenefit$ = /*@__PURE__*/ createSelector(benefitSKU$, Boolean)
export const benefitFeatures$ = /*@__PURE__*/ createSelector(benefitSKUTier$, (t) =>
	t === 'premium_plus' ? CANCEL_PREM_FEATURES : CANCEL_PREM_PLUS_FEATURES,
)
