import { useFn } from '@eturi/react'
import { pick } from '@eturi/util'
import type { ManagedUser } from '@op/services'
import { activeChildId$, children$ } from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import { lazy, useMemo } from 'react'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadComponent, NavBar } from '../../components'

type UserIdAndName = MPick<ManagedUser, 'user_id' | 'user_name'>

const LocatorNavBarActions = /*@__PURE__*/ lazy(() => import('./LocatorNavBarActions'))

const users$ = createSelector(children$, (users): UserIdAndName[] =>
	users.map((u) => pick(u, ['user_id', 'user_name'])),
)

export const LocatorNavBar = () => {
	const h = useHistory()
	const activeChildId = useSelector(activeChildId$)
	const users = useSelector(users$, isEqual)

	const handleSelect = useFn((userId: string) => {
		h.push(`/locator/${userId}`)
	})

	return useMemo(
		() => (
			<NavBar>
				<NavBar.BackBtn />

				<NavBar.TitleWrap>
					<select onChange={(ev) => handleSelect(ev.target.value)} value={activeChildId || ''}>
						{users.map((u) => (
							<option key={u.user_id} value={u.user_id}>
								{u.user_name}
							</option>
						))}
					</select>
				</NavBar.TitleWrap>

				<div className="flex-auto" />

				<LoadComponent>
					<LocatorNavBarActions />
				</LoadComponent>
			</NavBar>
		),
		[activeChildId, users],
	)
}
