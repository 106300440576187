import './NavBar.scss'
import './NavPopper.scss'
import { memo, useContext } from 'react'
import { Overlay } from '../Overlay'
import { NavBarCtx } from './NavBarCtx'
import { NavSlideBox } from './NavSlideBox'

const NavBarOutlet = () => {
	const navBar = useContext(NavBarCtx)

	if (!navBar) return null

	return (
		<nav className="top-nav-wrapper app-fixed-width fixed left-0 right-0 top-0 z-hack">
			<NavSlideBox className="top-nav is-sb-pad bg-white flex h-full items-center relative">
				{navBar}

				<Overlay />
			</NavSlideBox>
		</nav>
	)
}

export default /*@__PURE__*/ memo(NavBarOutlet)
