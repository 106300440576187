import { nullFn } from '@op/util'
import cls from 'classnames'

import { Field } from 'react-final-form'
import type { RadioCheckGroupProps } from '../../types'

export const CheckboxGroup = <T,>({
	children,
	className,
	isStacked,
	labelledBy,
	name,
	validate,
}: RadioCheckGroupProps<T>) => {
	return (
		<div
			aria-labelledby={labelledBy}
			className={cls(
				'checkbox-group radio-check-group',
				className,
				isStacked && 'radio-check-group--is-stacked',
			)}
			role="select"
		>
			{children}
			{name && validate && <Field name={name} render={nullFn} validate={validate} />}
		</div>
	)
}
