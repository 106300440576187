import { useFn } from '@eturi/react'
import { useEffect, useRef, useState } from 'react'

/**
 * A simple component that can be added anywhere to draw page focus. This
 * useful in big context changes like modals so the focus is closer to the
 * content being presented.
 *
 * - NOTE: Requires `.sr-only` class to be hidden properly
 */
export const PageFocus = () => {
	const focusElRef = useRef<HTMLButtonElement>(null)
	const [tabIndex, setTabIndex] = useState(0)

	// Don't allow the item to be re-focused after it's been blurred.
	const handleBlur = useFn(() => setTabIndex(-1))

	useEffect(() => {
		focusElRef.current?.focus()
	}, [])

	return <button className="sr-only" ref={focusElRef} tabIndex={tabIndex} onBlur={handleBlur} />
}
