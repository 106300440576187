import cls from 'classnames'
import type { ReactNode, Ref } from 'react'
import { forwardRef, useMemo } from 'react'
import { ZAboveOverlay } from '../../constants/z-index'
import type { WrapperTag, WrapperTagProps } from '../../types'
import { useAppOverlayVis } from './useOverlay'

type AboveOverlayProps<T extends WrapperTag> = {
	readonly children?: ReactNode
	readonly className?: string
	readonly tag?: T
} & WrapperTagProps<T>

const AboveOverlayInner = <T extends WrapperTag = WrapperTag>(
	{ children, className, style, tag = 'div' as T, ...props }: AboveOverlayProps<T>,
	ref: Ref<JSX.IntrinsicElements[T]>,
) => {
	const isOverlayVis = useAppOverlayVis()
	const Wrapper = tag as any

	const resolvedStyle = useMemo(
		() => ({
			...style,
			zIndex: isOverlayVis ? ZAboveOverlay : undefined,
		}),
		[isOverlayVis, style],
	)

	return (
		<Wrapper {...props} className={cls('relative', className)} style={resolvedStyle} ref={ref}>
			{children}
		</Wrapper>
	)
}

export const AboveOverlay = forwardRef(AboveOverlayInner) as <T extends WrapperTag = WrapperTag>(
	props: AboveOverlayProps<T>,
	ref: Ref<JSX.IntrinsicElements[T]>,
) => ReturnType<typeof AboveOverlayInner>
