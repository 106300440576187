import { useFn, useStateGetter, useTimeout } from '@eturi/react'
import { bup } from '@op/react-web'
import { a, useSpring } from '@react-spring/web'
import { useEffect, useMemo } from 'react'
import type { ButtonBaseProps, ButtonTags } from '../../types'
import { getBtnCls, getBtnType } from '../../types'
import { LoadingSpinner } from '../Loading'

type LoadingBtnProps<T extends ButtonTags = 'button'> = ButtonBaseProps<T> & {
	readonly isLoading: boolean
}

const LOADING_SPRING_CONFIG = {
	friction: 50,
	mass: 1,
	tension: 400,
}

export const LoadingBtn = <T extends ButtonTags = 'button'>({
	active = false,
	color = 'teal',
	children,
	className,
	fat = false,
	invert = false,
	isLoading,
	size = 'md',
	tag = 'button' as T,
	...p
}: LoadingBtnProps<T>) => {
	const [isSpinnerVisible, _setSpinnerVisibility] = useStateGetter(false)
	const [setLoadingTimeout, clearLoadingTimeout] = useTimeout()

	// Sets style visibility based on opacity.
	const interpolateVis = useFn((o: number) => (o === 0 ? 'hidden' : 'visible'))

	// Getter for main button content shown when not loading
	const getContentSpringProps = useFn(
		() =>
			({
				config: LOADING_SPRING_CONFIG,
				// Fade in / out based on loading state
				opacity: isSpinnerVisible() ? 0 : 1,
			}) as const,
	)

	const getSpinnerSpringProps = useFn(
		() =>
			({
				config: LOADING_SPRING_CONFIG,
				// Fade + scale in / out based on loading state
				opacity: isSpinnerVisible() ? 1 : 0,
				transform: `scale(${isSpinnerVisible() ? 0.66 : 2})`,
			}) as const,
	)

	const [contentSpringProps, contentSpringApi] = useSpring(getContentSpringProps)
	const [spinnerSpringProps, spinnerSpringApi] = useSpring(getSpinnerSpringProps)

	// Since we use a state getter, we can combine all state updates into one
	const setSpinnerVisibility = useFn((isSpinnerVisible) => {
		bup(() => {
			_setSpinnerVisibility(isSpinnerVisible)
			contentSpringApi.start(getContentSpringProps())
			spinnerSpringApi.start(getSpinnerSpringProps())
		})
	})

	// FIXME: Verify this is true in react-spring v9
	// Since the useSpring props only change when the setter is called, we
	// can memoize the styles. We need to use `interpolate` to set the visibility
	// based on the opacity. This is required so that the invisible loader isn't
	// picked up by screen readers and can't be interacted with when not loading.
	const contentSpringStyle = useMemo(
		() => ({
			...contentSpringProps,
			visibility: contentSpringProps.opacity.to(interpolateVis),
		}),
		[contentSpringProps],
	)

	const spinnerSpringStyle = useMemo(
		() => ({
			...spinnerSpringProps,
			visibility: spinnerSpringProps.opacity.to(interpolateVis),
		}),
		[spinnerSpringProps],
	)

	const btnCls = getBtnCls(
		active,
		color,
		fat,
		invert,
		size,
		'btn overflow-hidden relative',
		isSpinnerVisible() && '!pointer-events-none',
		className,
	)
	const type = getBtnType(tag, p)
	const Wrapper = tag as any

	useEffect(() => {
		// Reset any timeouts every time loading changes. This has a debounce effect.
		clearLoadingTimeout()

		// Show spinner if we aren't loading any more but the spinner is visible
		if (!isLoading && isSpinnerVisible()) {
			return setSpinnerVisibility(false)
		}

		// Start the timer for showing the spinner if we are loading, but the
		// spinner is not yet visible.
		if (isLoading && !isSpinnerVisible()) {
			setLoadingTimeout(() => isLoading && setSpinnerVisibility(true), 200)
		}
	}, [isLoading])

	return (
		<Wrapper {...p} className={btnCls} type={type}>
			<a.span className="flex-center relative z-10" style={contentSpringStyle}>
				{children}
			</a.span>

			<a.span className="absolute fill flex-center opacity-0 z-20" style={spinnerSpringStyle}>
				<LoadingSpinner className="is-md" />
			</a.span>
		</Wrapper>
	)
}
