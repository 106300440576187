import { lazy } from 'react'

export const EmailVerificationModal = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'email-verification-modal' */
			/* webpackPrefetch: 0 */
			'./EmailVerificationModal'
		),
)
