import { ImmutSet, setIfNotEqual } from '@eturi/util'
import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import { createSliceTransformer, stripPayload } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import type {
	IdPayloadAction,
	VewNowChildReqMap,
	VewNowDeviceUpdatePayload,
	VewNowInitPayload,
	VewNowReqState,
} from '../types'
import { createIdPayloadPrepare, pickIdPayload } from '../types'
import { castImmutSet } from '../util'

export type VewDevicesState = {
	readonly presentDeviceIds: ImmutSet<string>
	readonly shouldSubscribePresence: boolean
	readonly vewNowRequests: VewNowChildReqMap
	readonly wasPresenceChecked: boolean
}

export type WithVewDevicesState = {
	readonly vewDevices: VewDevicesState
}

const initialState: VewDevicesState = {
	presentDeviceIds: new ImmutSet(),
	shouldSubscribePresence: false,
	vewNowRequests: {},
	wasPresenceChecked: false,
}

const ensureVewNowReqState = (
	s: Draft<VewDevicesState>,
	id: string,
	idStateUpdate?: Partial<VewNowReqState>,
) =>
	castDraft(
		Object.assign(
			(s.vewNowRequests[id] ||= { complete: false, devices: {}, requestTs: -1 }),
			idStateUpdate,
		),
	)

export const vewDevicesSlice = /*@__PURE__*/ createSlice({
	name: 'vewDevices',
	initialState,
	reducers: {
		addPresentDevice(_s, a: PayloadAction<string>) {
			const s = castImmutSet<VewDevicesState>(_s)
			s.presentDeviceIds = s.presentDeviceIds.add(a.payload)
		},

		completeVewNow: {
			prepare: createIdPayloadPrepare(),
			reducer(s, a: IdPayloadAction) {
				ensureVewNowReqState(s, a.payload.id, { complete: true })
			},
		},

		initVewNow: {
			prepare: createIdPayloadPrepare<VewNowInitPayload>(),
			reducer(s, a: IdPayloadAction<VewNowInitPayload>) {
				const [id, { deviceIds, requestTs }] = pickIdPayload(a)

				const devices = deviceIds.reduce(
					(deviceStatusMap, deviceId) =>
						Object.assign(deviceStatusMap, { [deviceId]: { complete: false } }),
					{},
				)

				ensureVewNowReqState(s, id, { complete: false, devices, requestTs })
			},
		},

		removePresentDevice(_s, a: PayloadAction<string>) {
			const s = castImmutSet<VewDevicesState>(_s)
			s.presentDeviceIds = s.presentDeviceIds.delete(a.payload)
		},

		removeVewNowDevice: {
			prepare: createIdPayloadPrepare<string>(),
			reducer(s, a: IdPayloadAction<string>) {
				const [userId, deviceId] = pickIdPayload(a)
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete ensureVewNowReqState(s, userId).devices[deviceId]
			},
		},

		setPresentDevices(_s, a: PayloadAction<ImmutSet<string>>) {
			const s = castImmutSet<VewDevicesState>(_s)
			s.presentDeviceIds = a.payload
		},

		setWasPresenceChecked(s, a: PayloadAction<boolean>) {
			s.wasPresenceChecked = a.payload
		},

		subscribeToPresence(s, a: PayloadAction<boolean>) {
			s.shouldSubscribePresence = a.payload
		},

		updateVewNowDevice: {
			prepare: createIdPayloadPrepare<VewNowDeviceUpdatePayload>(),
			reducer(s, a: IdPayloadAction<VewNowDeviceUpdatePayload>) {
				const [userId, deviceUpdate] = pickIdPayload(a)
				const reqState = ensureVewNowReqState(s, userId)
				const { deviceId, ...deviceStateUpdate } = deviceUpdate
				const currentState = reqState.devices[deviceId]

				if (!currentState) return

				setIfNotEqual(reqState.devices, deviceId, { ...currentState, ...deviceStateUpdate })
			},
		},
	},

	extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
})

export const {
	addPresentDevice,
	completeVewNow,
	initVewNow,
	removePresentDevice,
	removeVewNowDevice,
	setPresentDevices,
	setWasPresenceChecked,
	subscribeToPresence,
	updateVewNowDevice,
} = vewDevicesSlice.actions

const shouldStripVewDeviceActionPayload = /*@__PURE__*/ isAnyOf(
	addPresentDevice,
	completeVewNow,
	initVewNow,
	removePresentDevice,
	removeVewNowDevice,
	setPresentDevices,
	updateVewNowDevice,
)

export const vewDevicesSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	vewDevicesSlice,
	(s) => ({
		...s,
		presentDeviceIds: [...s.presentDeviceIds],
	}),
	(a) => (shouldStripVewDeviceActionPayload(a) ? stripPayload(a) : a),
)

// NOTE: This needs to be used if we ever persist this state
export const rehydrateVewDeviceState = (p: Partial<VewDevicesState>): VewDevicesState => ({
	...initialState,
	...p,
	presentDeviceIds: new ImmutSet(p.presentDeviceIds),
})

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithVewDevicesState>(s: T) => s.vewDevices
export const presentDeviceIds$ = /*@__PURE__*/ createSelector(state$, (s) => s.presentDeviceIds)
export const shouldSubscribePresence$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.shouldSubscribePresence,
)
export const vewNowRequests$ = /*@__PURE__*/ createSelector(state$, (s) => s.vewNowRequests)
export const wasPresenceChecked$ = /*@__PURE__*/ createSelector(state$, (s) => s.wasPresenceChecked)
