import { duration } from '@eturi/date-util'
import { uuidObject } from '@op/util'
import isPlainObject from 'lodash/isPlainObject'
import type {
	CryptoWorkerPLType,
	DecryptBufferPL,
	DecryptJsonPL,
	DecryptPrivateKeyPL,
	DecryptTempKeysPL,
	EncryptPrivateKeyPL,
	GeneratePwHashPL,
	TempKeyMap,
} from './types'

export type CryptoWorkerPL<T> = {
	readonly id: string
	readonly payload: T
	readonly type: CryptoWorkerPLType
}

type CryptoWorkerState = {
	readonly handles: number
	readonly lastHandleTs: number
	readonly worker: Worker
}

const WORKER_TIMEOUT = /*@__PURE__*/ duration(30, 's')
const WATCH_WORKER_INTERVAL = /*@__PURE__*/ duration(5, 's')

export const registerCryptoWorker = (() => {
	const _workerPool = new Map<Worker, CryptoWorkerState>()
	let _intervalId = -1
	let _isWatching = false

	// Terminate threads that have run longer than 30 seconds without having
	// a new operation called. We'll scan every 5 seconds for long-running threads.
	// In case a thread has a bug or some infinite loop, we can potentially recover.
	// but really this is just to be somewhat more responsible w/ resources.
	const _watch = () => {
		if (_isWatching) return

		_isWatching = true
		_intervalId = setInterval(() => {
			const now = Date.now()

			_workerPool.forEach((workerState, worker, pool) => {
				if (now - workerState.lastHandleTs > WORKER_TIMEOUT) {
					worker.terminate()
					pool.delete(worker)
				}
			})

			if (!_workerPool.size) {
				_isWatching = false
				clearInterval(_intervalId)
			}
		}, WATCH_WORKER_INTERVAL)
	}

	const _updateHandles = (worker: Worker, handlesChange: number) => {
		const state = _workerPool.get(worker) || { handles: 0, lastHandleTs: -1 }

		// Update timestamp when handles are added
		const lastHandleTs = handlesChange > 0 ? Date.now() : state.lastHandleTs

		// Add the handles change
		_workerPool.set(worker, {
			handles: state.handles + handlesChange,
			lastHandleTs,
			worker,
		})
	}

	const _getWorker = (): Worker => {
		// Start watching the thread pool
		_watch()

		let worker: Worker

		// Keep at most 3 workers. This is arbitrary based on virtual cores and may
		// not even matter, but it's here nonetheless. It should allow us to delegate
		// a ton of stuff to workers without spawning some absurd number.
		if (_workerPool.size < 3) {
			worker = new Worker(new URL('./crypto.worker.ts', import.meta.url), { type: 'module' })
		} else {
			// We allocate fairly naively by just picking the first worker w/ the fewest
			// handles. First turn the pool states into a list, then find the state with
			// the fewest handles.
			const workerStates = [..._workerPool.values()]
			const stateWithFewestHandles = workerStates.reduce(
				(fewestState: CryptoWorkerState, state) =>
					fewestState.handles > state.handles ? state : fewestState,
				workerStates[0],
			)

			worker = stateWithFewestHandles.worker
		}

		// Increment the handle count of that worker and return it
		_updateHandles(worker, 1)

		return worker
	}

	const _removeWorker = (worker: Worker) => {
		if (!_workerPool.has(worker)) return
		_updateHandles(worker, -1)
	}

	function registerCryptoWorker(type: 'decrypt_buf', payload: DecryptBufferPL): Promise<ArrayBuffer>
	function registerCryptoWorker<R>(type: 'decrypt_json', payload: DecryptJsonPL): Promise<R>
	function registerCryptoWorker(type: 'decrypt_pk', payload: DecryptPrivateKeyPL): Promise<string>
	function registerCryptoWorker(
		type: 'decrypt_tmp',
		payload: DecryptTempKeysPL,
	): Promise<TempKeyMap>
	function registerCryptoWorker(type: 'enc_pk', payload: EncryptPrivateKeyPL): Promise<string>
	function registerCryptoWorker(type: 'gen_pk'): Promise<string>
	function registerCryptoWorker(type: 'gen_pw_hash', payload: GeneratePwHashPL): Promise<string>
	function registerCryptoWorker(type: CryptoWorkerPLType, payload?: any): Promise<any> {
		const worker = _getWorker()

		return new Promise((resolve, reject) => {
			const messagePayload: CryptoWorkerPL<any> = uuidObject({ type, payload })
			const handleMessage = ({ data }: MessageEvent) => {
				// Guard on plain objects for type safety and filter on payload id to match
				// the promise to the message.
				if (!(isPlainObject(data) && data.id === messagePayload.id)) return

				// If the guard is passed, clean up the handlers, resolve the data and
				// remove the worker.
				worker.removeEventListener('error', reject)
				worker.removeEventListener('message', handleMessage)
				resolve(data.payload)
				_removeWorker(worker)
			}

			worker.addEventListener('error', reject)
			worker.addEventListener('message', handleMessage)
			worker.postMessage(messagePayload)
		})
	}

	return registerCryptoWorker
})()
