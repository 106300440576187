import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import type { Draft } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { InitState, SThunkState, VewRequestBody, VewStatsMap, VewStatsRes } from '../types'

export type UserStatsState = InitState & {
	readonly stats: Maybe<VewStatsMap>
}

export type SampleStatsState = {
	readonly [userId: string]: UserStatsState
}

export type WithSampleStatsState = {
	readonly sampleStats: SampleStatsState
}

export const createUserStatsState = (): UserStatsState => ({
	isInit: false,
	stats: null,
})

const initialState: SampleStatsState = {}

const ensureUserState = (s: Draft<SampleStatsState>, userId: string) =>
	(s[userId] ||= castDraft(createUserStatsState()))

export const sampleStatsSlice = /*@__PURE__*/ createSlice({
	name: 'sampleStats',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchUserSampleStats.fulfilled, (s, { meta, payload: stats }) => {
				const userState = ensureUserState(s, meta.arg.userId)

				userState.isInit = true
				setIfNotEqual(userState, 'stats', stats)
			}),
})

////////// Thunks //////////////////////////////////////////////////////////////

export type SampleStatsThunkState = SThunkState & WithSampleStatsState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<SampleStatsThunkState>()

type FetchUserSampleStatsArg = HttpExtra & {
	readonly userId: string
}

// FIXME: Try/catch client impl
export const fetchUserSampleStats = /*@__PURE__*/ createAsyncThunk(
	'sampleStats/fetch',
	async ({ userId, ...extra }: FetchUserSampleStatsArg, { dispatch, extra: { http } }) => {
		const data: VewRequestBody = {
			request_type: 'get_sample_stats',
			user_id: userId,
		}

		const res = await dispatch(http.post<Maybe<VewStatsRes>>('/vew_request', { ...extra, data }))

		const sampleStats = res?.sample_stats

		assertNotNullish(sampleStats, 'VewStatsRes')

		return sampleStats
	},
	{
		condition: (arg, api) => {
			if (!arg.force && sampleStatsState$(api.getState())[arg.userId]?.isInit) return false
		},
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

export const sampleStatsState$ = <T extends WithSampleStatsState>(s: T) => s.sampleStats
