import { duration } from '@eturi/date-util'
import { useFn } from '@eturi/react'
import type { MouseEvent } from 'react'
import { memo, useState } from 'react'
import { ZModal } from '../constants/z-index'
import { ActionBtn } from './Buttons'

const COOKIE_NOTICE_KEY = 'cookieNoticeAccepted'

const CookieNotice = () => {
	const [cookieNoticeAccepted, setCookieNoticeAccepted] = useState(() =>
		getCookie(COOKIE_NOTICE_KEY),
	)

	const handleClose = useFn((ev: MouseEvent) => {
		ev.preventDefault()

		const acceptedValue = 'true'

		setCookie('cookieNoticeAccepted', acceptedValue)
		setCookieNoticeAccepted(acceptedValue)
	})

	if (cookieNoticeAccepted) return null

	return (
		<section
			className="border border-teal-2lt fixed bottom-0 right-0 max-w-screen-sm w-full z-hack"
			style={{ zIndex: ZModal + 1 }}
		>
			<div className="clear-both bg-white shadow-2xl px-5 py-4">
				<ActionBtn className="float-right ml-3 -mr-1" icon="x" onClick={handleClose} />

				<p className="text-sm">
					This website uses cookies to ensure the best possible experience. By closing, dismissing
					this notice, clicking a link or button, or by continuing to browse, you accept the use of
					cookies or other identifiers.
				</p>
			</div>
		</section>
	)
}

export default /*@__PURE__*/ memo(CookieNotice)

const normalizeWrite = (value: string) =>
	encodeURIComponent(value).replace(/%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g, decodeURIComponent)

const normalizeRead = (value: string) => value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent)

const setCookie = (cookieName: string, cookieValue: string, expiry: number = duration(1, 'y')) => {
	const d = new Date()
	d.setTime(d.getTime() + expiry)
	document.cookie = `${cookieName}=${normalizeWrite(
		cookieValue,
	)}; expires=${d.toUTCString()}; path=/`
}

const getCookie = (cookieName: string): Maybe<string> => {
	const cookies = document.cookie ? document.cookie.split('; ') : []

	for (const cookie of cookies) {
		const [key, value] = cookie.split('=')

		if (key !== cookieName) continue

		try {
			return normalizeRead(value)
		} catch {
			return null
		}
	}
}
