import { useT } from '@eturi/react-i18next'
import { lazy, memo } from 'react'
import { LoadPage, NavBar } from '../../components'

const NotifsPageContent = lazy(() => import('./NotifsPageContent'))

const NotifsPage = () => {
	const [t] = useT()

	return (
		<div className="page">
			<NavBar.Default>{t('notifications.header')}</NavBar.Default>

			<LoadPage>
				<NotifsPageContent />
			</LoadPage>
		</div>
	)
}

export default /*@__PURE__*/ memo(NotifsPage)
