import { useInit, usePrevious } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import {
	activeChildId$,
	activeChildName$,
	fetchVewUserConfig,
	isActiveUserUnmanaged$,
} from '@op/services'
import { lazy, memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { LoadEmpty, NavBar } from '../../components'
import { useAppDispatch } from '../../types'

const GalleryNavBtns = /*@__PURE__*/ lazy(() => import('./GalleryNavBtns'))

export const GalleryNavBar = () => {
	// Extra check to handle, 401/403 logout cases. When that happens
	// activeChild is null for a split second before logout redirect occurs
	const userId = useSelector(activeChildId$)
	const userName = useSelector(activeChildName$)

	return userId ? (
		<GalleryNavBarImpl userId={userId} userName={userName} />
	) : (
		<NavBar.Default>{userName}</NavBar.Default>
	)
}

export default /*@__PURE__*/ memo(GalleryNavBar)

type GalleryNavBarImplProps = {
	readonly userId: string
	readonly userName: string
}

const GalleryNavBarImpl = ({ userId, userName }: GalleryNavBarImplProps) => {
	const d = useAppDispatch()
	const [t] = useT('disabled_gallery')
	const isActiveUserUnmanaged = useSelector(isActiveUserUnmanaged$)
	const isInit = useInit()
	const prevUserId = usePrevious(userId)

	useEffect(() => {
		// When the user goes from being unmanaged to managed, we want to get the
		// latest vew config for the user since the users vew config changes when
		// the user becomes managed. If the activeChildId is not the same as
		// prevUserId, we probably either just loaded or switched children
		// and thus don't want to fetch.
		if (isInit() && (isActiveUserUnmanaged || userId !== prevUserId)) return

		d(fetchVewUserConfig({ force: 'soft', userId }))
	}, [isActiveUserUnmanaged, userId])

	return useMemo(
		() => (
			<NavBar>
				<NavBar.BackBtn />

				<NavBar.Title>{/*FIXME: Child dropdown*/ userName}</NavBar.Title>

				<div className="flex-auto" />

				<LoadEmpty>
					<GalleryNavBtns userId={userId} />
				</LoadEmpty>
			</NavBar>
		),
		[t, userName, userId],
	)
}
