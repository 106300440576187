/**
 * Features for paid subscriptions
 */

export type FeatureData = {
	readonly clsId: FeatureClsId
	readonly hasLimit: boolean
	readonly hasTableTitle: boolean
	readonly hasTitleLimitStart: boolean
	readonly icon: string
	readonly transKey: string
}

const createFeatureData = (
	clsId: FeatureClsId,
	icon: string,
	transKey: string,
	hasLimit = false,
	hasTitleLimitStart = false,
	hasTableTitle = false,
): FeatureData => ({
	clsId,
	icon,
	transKey,
	hasLimit,
	hasTitleLimitStart,
	hasTableTitle,
})

export type FeatureClsId =
	| 'allowance'
	| 'app-alerts'
	| 'app-organizer'
	| 'app-rules'
	| 'block-sites'
	| 'block-text'
	| 'blocks-grants'
	| 'categories'
	| 'devices'
	| 'gallery'
	| 'ios-restrix'
	| 'locator'
	| 'on-demand'
	| 'periodic-vews'
	| 'places'
	| 'schedules'
	| 'web-filter'

const APP_ALERTS_FEATURE = /*@__PURE__*/ createFeatureData(
	'app-alerts',
	'info-bubble',
	'app_alerts',
)
const APP_ORGANIZER_FEATURE = /*@__PURE__*/ createFeatureData(
	'app-organizer',
	'arrange',
	'app_organizer',
)
const BLOCK_GRANT_FEATURE_FREE = /*@__PURE__*/ createFeatureData(
	'blocks-grants',
	'check-cancel',
	'grants_blocks_free',
	true,
	true,
)
const BLOCK_GRANT_FEATURE_PLUS = { ...BLOCK_GRANT_FEATURE_FREE, transKey: 'grants_blocks_plus' }
const BLOCK_SITES_FEATURE = /*@__PURE__*/ createFeatureData(
	'block-sites',
	'block-sites',
	'block_sites',
)
const BLOCK_TEXT_FEATURE = /*@__PURE__*/ createFeatureData(
	'block-text',
	'block-texting',
	'block_text',
)
const CATEGORY_FEATURE = /*@__PURE__*/ createFeatureData('categories', 'search-doc', 'vew_category')
const DEVICES_FEATURE_FREE = /*@__PURE__*/ createFeatureData(
	'devices',
	'add-user',
	'devices_free',
	true,
)
const DEVICES_FEATURE_PLUS = { ...DEVICES_FEATURE_FREE, transKey: 'devices_plus' }
const DEVICES_FEATURE_PREM = { ...DEVICES_FEATURE_FREE, transKey: 'devices_premium' }
const GALLERY_FEATURE = /*@__PURE__*/ createFeatureData('gallery', 'gallery-img', 'vew_gallery')
const IOS_RESTRIX_FEATURE = /*@__PURE__*/ createFeatureData(
	'ios-restrix',
	'restrix',
	'ios_restrictions',
)
const ON_DEMAND_FEATURE = /*@__PURE__*/ createFeatureData('on-demand', 'vew-now', 'vew_on_demand')
const PERIODIC_VEWS_FEATURE = /*@__PURE__*/ createFeatureData(
	'periodic-vews',
	'vew-periodic',
	'periodic_vews',
)
const SCHEDULE_FEATURE_FREE = /*@__PURE__*/ createFeatureData(
	'schedules',
	'clock',
	'schedules_free',
	true,
	true,
)
const SCHEDULE_FEATURE_PLUS = { ...SCHEDULE_FEATURE_FREE, transKey: 'schedules_plus' }

// Premium exports for free view
export const ALLOWANCE_FEATURE = /*@__PURE__*/ createFeatureData(
	'allowance',
	'allowance',
	'allowance',
)
export const APP_RULES_FEATURE = /*@__PURE__*/ createFeatureData(
	'app-rules',
	'app-rules',
	'app_rules',
)
export const LOCATOR_FEATURE = /*@__PURE__*/ createFeatureData('locator', 'location', 'locator')
export const PLACES_FEATURE = /*@__PURE__*/ createFeatureData('places', 'geo', 'places')
export const WEB_FILTER_FEATURE = /*@__PURE__*/ createFeatureData(
	'web-filter',
	'web',
	'web_filter',
	false,
	false,
	true,
)

/**
 * Downgrade: Premium -> Free
 *
 * - Multiple Child Devices
 * - App Rules
 * - Unlimited Block Schedules
 * - Block Texting
 * - Unlimited Manual Blocks
 * - App List
 *
 * Cancel: Premium -> Grandfathered
 * Downgrade: Premium -> Plus
 *
 * - 20 Devices
 * - App Rules
 * - Block Texting
 * - App List
 *
 * Cancel: Plus -> Free
 *
 * - Multiple Child Devices
 * - Unlimited Block Schedules
 * - Unlimited Manual Blocks
 */

export const DOWNGRADE_PREM_FEATURES: FeatureData[] = [
	DEVICES_FEATURE_PREM,
	PLACES_FEATURE,
	IOS_RESTRIX_FEATURE,
	APP_RULES_FEATURE,
	BLOCK_TEXT_FEATURE,
	APP_ORGANIZER_FEATURE,
	ALLOWANCE_FEATURE,
	WEB_FILTER_FEATURE,
	LOCATOR_FEATURE,
	BLOCK_SITES_FEATURE,
]

export const DOWNGRADE_PREM_PLUS_FEATURES: FeatureData[] = [
	PERIODIC_VEWS_FEATURE,
	ON_DEMAND_FEATURE,
	GALLERY_FEATURE,
]

export const CANCEL_PLUS_FEATURES: FeatureData[] = [
	SCHEDULE_FEATURE_PLUS,
	BLOCK_GRANT_FEATURE_PLUS,
	DEVICES_FEATURE_PLUS,
]

export const CANCEL_PREM_FEATURES: FeatureData[] = [
	SCHEDULE_FEATURE_PLUS,
	ALLOWANCE_FEATURE,
	WEB_FILTER_FEATURE,
	BLOCK_GRANT_FEATURE_PLUS,
	LOCATOR_FEATURE,
	BLOCK_TEXT_FEATURE,
	APP_RULES_FEATURE,
	PLACES_FEATURE,
	APP_ALERTS_FEATURE,
]

export const CANCEL_PREM_PLUS_FEATURES: FeatureData[] = [
	PERIODIC_VEWS_FEATURE,
	ON_DEMAND_FEATURE,
	GALLERY_FEATURE,
	SCHEDULE_FEATURE_PLUS,
	BLOCK_GRANT_FEATURE_PLUS,
	APP_RULES_FEATURE,
	ALLOWANCE_FEATURE,
	LOCATOR_FEATURE,
	PLACES_FEATURE,
	WEB_FILTER_FEATURE,
	BLOCK_TEXT_FEATURE,
	APP_ALERTS_FEATURE,
]

export const PREM_PLUS_FEATURES: FeatureData[] = [
	PERIODIC_VEWS_FEATURE,
	ALLOWANCE_FEATURE,
	ON_DEMAND_FEATURE,
	LOCATOR_FEATURE,
	GALLERY_FEATURE,
	PLACES_FEATURE,
	SCHEDULE_FEATURE_PLUS,
	WEB_FILTER_FEATURE,
	BLOCK_GRANT_FEATURE_PLUS,
	BLOCK_TEXT_FEATURE,
	APP_RULES_FEATURE,
	APP_ALERTS_FEATURE,
]

export const TABLE_PREM_FEATURES: FeatureData[] = [
	SCHEDULE_FEATURE_PLUS,
	BLOCK_GRANT_FEATURE_PLUS,
	APP_RULES_FEATURE,
	ALLOWANCE_FEATURE,
	LOCATOR_FEATURE,
	WEB_FILTER_FEATURE,
	BLOCK_TEXT_FEATURE,
	APP_ALERTS_FEATURE,
	DEVICES_FEATURE_PREM,
]

export const TABLE_PREM_PLUS_FEATURES: FeatureData[] = [
	PERIODIC_VEWS_FEATURE,
	ON_DEMAND_FEATURE,
	GALLERY_FEATURE,
	CATEGORY_FEATURE,
	SCHEDULE_FEATURE_PLUS,
	BLOCK_GRANT_FEATURE_PLUS,
	APP_RULES_FEATURE,
	ALLOWANCE_FEATURE,
	LOCATOR_FEATURE,
	WEB_FILTER_FEATURE,
	BLOCK_TEXT_FEATURE,
	APP_ALERTS_FEATURE,
	DEVICES_FEATURE_PREM,
]
