import { useFn, usePrevious } from '@eturi/react'
import { hasChildren$, isAuthenticated$ } from '@op/services'
import type { MemoryHistory } from 'history'
import { memo, useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setCanShowMenuView } from '../reducers/app-misc.slice'
import { useAppDispatch } from '../types'
import { isAndroidParent } from '../util'

const NO_NAV_PATHS = ['/forgot', '/login', '/pair', '/promo', '/reset', '/signup', '/verify']

const RESET_PATH_REGEXP = /^\/reset\/?$/

const RouteWatcher = () => {
	const d = useAppDispatch()
	const { pathname } = useLocation()
	const hasChildren = useSelector(hasChildren$)
	const history = useHistory()
	const isAuthenticated = useSelector(isAuthenticated$)
	const wasAuthenticated = usePrevious(isAuthenticated)
	const didHaveChildren = usePrevious(hasChildren)

	/**
	 * We want to redirect to the login page if we go from authenticated to
	 * un-authenticated. But only if we're not on the password reset page. When
	 * we do `resetPwAction`, we log in to register a key pair, then log back
	 * out so the user can confirm their credentials. This isn't necessary and
	 * may change in the future, but for now we need to have a special case for
	 * the `/reset` page.
	 *
	 * NOTE: We have to do a RegExp test for reset b/c the server currently
	 *  includes a trailing `/` which will be removed.
	 *
	 * @see resetPwAction
	 */
	const shouldRedirectToLogin =
		!isAuthenticated && wasAuthenticated && !RESET_PATH_REGEXP.test(pathname)

	const handlePageView = useFn(() => {
		const paths = pathname.split('/')
		const totalPaths = paths.length
		const startPath = paths[1]

		if (
			(startPath === 'devices' && totalPaths === 3) ||
			(startPath === 'manage' && totalPaths === 4)
		) {
			paths.splice(2, 1, ':userId')
		}

		const normalizedPage = paths.join('/')

		// Send the pageview and set the current page
		ga('send', { page: normalizedPage, hitType: 'pageview' })
		ga('set', 'page', normalizedPage)
	})

	// Catch logout / de-auth and send to login
	useEffect(() => {
		shouldRedirectToLogin && history.replace('/login')
	}, [shouldRedirectToLogin])
	// Redirect to /connect when logged in and going from having children to not
	// logging out will trigger this unless we check if we're authenticated still
	useEffect(() => {
		if (isAuthenticated && didHaveChildren && !hasChildren) history.replace('/add-child')
	}, [didHaveChildren, hasChildren, isAuthenticated])

	// Record page views and set navigation visibility based on pathname
	useLayoutEffect(() => {
		d(setCanShowMenuView(!NO_NAV_PATHS.some((p) => pathname.startsWith(p))))
		handlePageView()
	}, [pathname])

	useEffect(() => {
		// Handler for Android Parent Wrap, when the back button is pressed
		if (!isAndroidParent) return

		window.OURPACT.onBackPressed = () => {
			// The history contains the canGo method but the types don't expose it
			if (!(history as MemoryHistory).canGo(-1)) return false

			history.go(-1)

			return true
		}
	}, [])

	return null
}

export default /*@__PURE__*/ memo(RouteWatcher)
