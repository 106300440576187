import { pick } from '@eturi/util'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import type { DeviceOS } from './Device'
import type { PartialRawRule, RuleType } from './rules'
import { isOverrideAllow } from './rules'
import type { RawUsage, Usage, UsageAppliedState } from './Usage'
import { mapRawToUsage } from './Usage'

export type BlockState =
	| 'allowed'
	| 'allowing'
	| 'blocked'
	| 'blocking'
	| 'management removed'
	| 'Unknown'

// This is the block status state mapped how we show it to users. This is not
// a simple mapping, and so we decorate it rather than calculating the states
// downstream. Ideally this would change to be more downstream, however.
export type ViewBlockState = 'allowed' | 'blocked' | 'paused' | 'pending' | 'unmanaged'
export type VewDeviceStatus = 'active' | 'inactive' | /* unknown */ ''

export type RawBlockStatus = {
	readonly applied_state: UsageAppliedState
	readonly devices: RawDeviceBlockStatus[]
	readonly rule: PartialRawRule
	readonly state: BlockState
	readonly usage: Maybe<RawUsage>
	readonly user_id: string
}

export type RawDeviceBlockStatus = {
	readonly device_id: string
	readonly last_seen: number
	readonly management_removed: boolean
	readonly not_now?: boolean
	readonly os: DeviceOS
	readonly status: BlockState
	readonly view_status?: VewDeviceStatus
}

// The pruned stuff allows us to ignore fields that we don't care about, so they
// don't cause state recalculations when they change.
const PRUNED_RAW_BLOCK_STATUS_KEYS = ['applied_state', 'rule', 'state', 'user_id'] as const
export type PrunedRawBlockStatus = Pick<
	RawBlockStatus,
	(typeof PRUNED_RAW_BLOCK_STATUS_KEYS)[number]
>

const PRUNED_RAW_DEVICE_BLOCK_STATUS_KEYS = ['device_id', 'os', 'status'] as const
export type PrunedRawDeviceBlockStatus = Pick<
	RawDeviceBlockStatus,
	(typeof PRUNED_RAW_DEVICE_BLOCK_STATUS_KEYS)[number]
>

export type DeviceBlockStatus = PrunedRawDeviceBlockStatus & {
	readonly vewStatus: VewDeviceStatus
	readonly viewState: ViewBlockState
}

export type BlockStatusMap = {
	readonly [userId: string]: BlockStatus
}

export type BlockStatus = PrunedRawBlockStatus & {
	readonly devices: DeviceBlockStatus[]
	readonly usage: Maybe<Usage>
	readonly viewState: ViewBlockState
}

export const mapRawToBlockStatus = (raw: RawBlockStatus): BlockStatus => {
	const rule = raw.rule
	const usage = raw.usage
		? mapRawToUsage(raw.usage, isOverrideAllow(rule), _isAllowanceBlocked(rule.type, raw.state))
		: null

	// Get base decorated status type of block state
	let viewState = _getViewState(raw, usage)

	// Decorate device statuses: Map viewState to each device
	const deviceStatuses = raw.devices.map((d) => _decorateDeviceStatus(raw, d, usage))

	// Determine whether we're unmanaged if there are no devices, or if all
	// devices are unmanaged
	const isUnmanaged = isEmpty(deviceStatuses) || every(deviceStatuses, { viewState: 'unmanaged' })

	// Block status is unmanaged if so
	viewState = isUnmanaged ? 'unmanaged' : viewState

	return {
		...pick(raw, PRUNED_RAW_BLOCK_STATUS_KEYS),
		devices: deviceStatuses,
		usage,
		viewState,
	}
}

const _decorateDeviceStatus = (
	raw: RawBlockStatus,
	ds: RawDeviceBlockStatus,
	usage: Maybe<Usage>,
): DeviceBlockStatus => ({
	...pick(ds, PRUNED_RAW_DEVICE_BLOCK_STATUS_KEYS),
	vewStatus: ds.view_status || '',
	viewState: _getDeviceStatusType(raw, ds, usage),
})

const _getDeviceStatusType = (
	raw: RawBlockStatus,
	ds: RawDeviceBlockStatus,
	usage: Maybe<Usage>,
): ViewBlockState => {
	const { device_id, status } = ds

	// If device is unmanaged, other states don't matter
	if (status === 'management removed') return 'unmanaged'

	// We change "blocking" or "allowing" to "pending"
	if (status === 'allowing' || status === 'blocking') return 'pending'

	// If allowance applied and state is known (if unmanaged/pending previous
	// conditions will apply). If we have usage info, get the state of the device
	// from that
	if (usage && _isAllowanceRule(raw) && _isAllowanceEnabled(raw)) {
		switch (usage.requested_state) {
			case 'expired':
				// Expired usage is blocked
				return 'blocked'

			case 'pause':
				// Device is "paused" if `requested_state` is in "pause" and `status`
				// is `blocked`. Otherwise, it's pending
				return status === 'blocked' ? 'paused' : 'pending'

			case 'play':
				return usage.devices_in_use.includes(device_id) ? 'allowed' : 'pending'
		}
	}

	return status === 'blocked' ? 'blocked' : 'allowed'
}

const _getViewState = (raw: RawBlockStatus, usage: Maybe<Usage>): ViewBlockState => {
	switch (raw.state) {
		case 'management removed':
			return 'unmanaged'

		case 'allowing':
		case 'blocking':
			return 'pending'

		case 'blocked': {
			if (usage?.displayState === 'paused' && _isAllowanceRule(raw) && _isAllowanceEnabled(raw))
				return 'paused'

			return 'blocked'
		}

		default:
			return 'allowed'
	}
}

const _isAllowanceBlocked = (ruleType: Maybe<RuleType>, state: BlockState) =>
	state === 'blocked' && (ruleType === 'temporal' || ruleType === 'override')

const _isAllowanceEnabled = ({ usage }: RawBlockStatus) => usage?.requested_state != null

// FIXME: How TF is 'TEMPORAL_DEFAULT' considered an 'allowance' rule. Seems
//  more like it's just being used for calculating state.
const _isAllowanceRule = ({ rule }: RawBlockStatus) =>
	Boolean(rule && (rule.type === 'allowance' || rule.type === 'temporal_default'))

/**
 * The "desired" state reflects the state that the user status is trying to
 * resolve to. Thus, it is always either allowed, blocked, or paused. This
 * state will be the same when whether pending or not, and it's generally used
 * for styling and other purposes.
 *
 * Also note that "unmanaged" is not included as a possibility here, as that is
 * handled separately.
 */
export const getDesiredVewBlockState = (
	state: Maybe<BlockState>,
	viewState: Maybe<ViewBlockState>,
): ViewBlockState =>
	viewState === 'paused'
		? viewState
		: state === 'blocked' || state === 'blocking'
		? 'blocked'
		: 'allowed'
