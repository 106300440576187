import { useConstant } from '@eturi/react'
import { Trans, useT } from '@eturi/react-i18next'
import type { ViewBlockState } from '@op/services'
import { memo } from 'react'
import { useStatusTextColor } from './useStatusColor'

type ChildStatusPendingTextProps = {
	readonly desiredViewState: ViewBlockState
}

const ChildStatusPendingText = ({ desiredViewState }: ChildStatusPendingTextProps) => {
	const [t] = useT('block_statuses')
	const color = useStatusTextColor(desiredViewState)
	const PendingStateKeyMap = useConstant(
		() =>
			new Map<ViewBlockState, string>([
				['allowed', 'grant'],
				['blocked', 'block'],
				['paused', 'pause'],
			]),
	)
	const pendingStateKey = PendingStateKeyMap.get(desiredViewState)
	const pendingState = pendingStateKey ? t(`blockStates.${pendingStateKey}`) : ''

	return <Trans i18nKey="pendingState_pending" t={t} tOptions={{ color, pendingState }} />
}

export default /*@__PURE__*/ memo(ChildStatusPendingText)
