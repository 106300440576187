/// <reference lib="dom" />

export const toParams = <T extends Record<string, unknown>>(o: T) => {
	const p = new URLSearchParams()

	for (const k of Object.keys(o)) {
		const v = o[k as keyof T]

		// Filter out null or undefined values
		if (v == null) continue

		p.set(k, String(v))
	}

	p.sort()

	return p
}

export const toParamsURL = <T extends Record<string, unknown>>(url: string, o: T) =>
	`${url}?${toParams(o).toString()}`
