type DateTimeInput = Date | number | string

// Server uses the date format "YYYY-MM-DD" however when using that format
// Date assumes it's UTC midnight and will apply a timezone offset. In order to
// avoid that we pass in T00:00:00 so that it forces Date to assume midnight
// local time and avoid applying an offset
const serverDateRegex = /^\d{4}-\d{2}-\d{2}$/
export const serverISOToClient = (date: string): string => {
	if (serverDateRegex.test(date)) return `${date}T00:00:00`

	return date
}

// The server uses ISO-sh format for location history dates (YYYY-MM-DD).
// It's not fully ISO since it doesn't contain time/timezone as well. Server
// expects these dates to be in the users time not UTC (which ISO is). The
// server takes the date and translates them to at UTC timestamp serverside
export const getISODateStr = (date: DateTimeInput = new Date()) => {
	date = date instanceof Date ? date : new Date(date)

	return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
		date.getDate(),
	).padStart(2, '0')}`
}
