import { duration } from '@eturi/date-util'
import { useConstant, useFn, useStateGetter } from '@eturi/react'
import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { v4 } from 'uuid'
import type { ActionToastProps, ConfirmToastProps, ToastProps, ToastType } from './Toast'
import { ActionToast, ConfirmToast, Toast } from './Toast'

const ACTION_TOAST_DURATION = /*@__PURE__*/ duration(30, 's')
const CONFIRM_TOAST_DURATION = /*@__PURE__*/ duration(1, 'm')
const DEFAULT_TOAST_DURATION = /*@__PURE__*/ duration(8, 's')

type ToastNode = {
	id: string
	tId: number
	toast: ReactNode
}

export type ActionToastOpts = Omit<ActionToastProps, 'close'>
export type ConfirmToastOpts = Omit<ConfirmToastProps, 'close'>
export type ToastOpts = Omit<ToastProps, 'close'>

type AnyToastOpts = ActionToastOpts | ConfirmToastOpts | ToastOpts

type ToastApiCtx = {
	readonly addActionToast: (opts: ActionToastOpts) => string
	readonly addConfirmToast: (opts: ConfirmToastOpts) => string
	readonly addErrorToast: (optsOrMsg: ToastOpts | string) => string
	readonly addInfoToast: (optsOrMsg: ToastOpts | string) => string
	readonly removeToast: (id: string) => void
}

// NOTE: Toast API and toasts themselves are separate contexts so that updating
//  a list of toasts doesn't cause components using the toast API to re-render.
//  Only the ToastContainer(s) will re-render when toasts are added/removed.
const ToastApiContext = /*@__PURE__*/ createContext<ToastApiCtx>(null as any)
export const ToastsContext = /*@__PURE__*/ createContext<ToastNode[]>([])

export const ToastManager = (p: { readonly children?: ReactNode }) => {
	// NOTE: Need to use state getter in case setToasts is called multiple times
	//  synchronously (which would overwrite each value). This can be reproduced
	//  by having a confirm toast, that onConfirm, triggers an en error toast.
	const [toasts, setToasts] = useStateGetter<ToastNode[]>([])

	const addNewToast = useFn((anyToastOpts: AnyToastOpts, type: ToastType): string => {
		const id = v4()
		const removeNewToast = () => removeToast(id)
		let duration = DEFAULT_TOAST_DURATION
		let toast: ReactNode
		const props = { ...anyToastOpts, close: removeNewToast }

		switch (type) {
			case 'action':
				duration = ACTION_TOAST_DURATION
				toast = <ActionToast {...(props as ActionToastProps)} />
				break

			case 'confirm':
				duration = CONFIRM_TOAST_DURATION
				toast = <ConfirmToast {...(props as ConfirmToastProps)} />
				break

			case 'error':
			case 'info':
				toast = <Toast {...props} type={type} />
				break
		}

		const tId = !anyToastOpts.persistent ? window.setTimeout(removeNewToast, duration) : -1

		setToasts([...toasts(), { id, tId, toast }])

		return id
	})

	const removeToast = useFn((id: string) => {
		const newToasts = toasts().filter((t) => {
			if (t.id !== id) return true
			if (t.tId !== -1) window.clearTimeout(t.tId)
			return false
		})

		setToasts(newToasts)
	})

	const normalizeOpts = (optsOrMsg: ToastOpts | string): ToastOpts =>
		typeof optsOrMsg === 'string' ? { msg: optsOrMsg } : optsOrMsg

	const toastApiCtx = useConstant(
		(): ToastApiCtx => ({
			addActionToast: (opts) => addNewToast(opts, 'action'),
			addConfirmToast: (opts) => addNewToast(opts, 'confirm'),
			addErrorToast: (optsOrMsg) => addNewToast(normalizeOpts(optsOrMsg), 'error'),
			addInfoToast: (optsOrMsg) => addNewToast(normalizeOpts(optsOrMsg), 'info'),
			removeToast,
		}),
	)

	return (
		<ToastApiContext.Provider value={toastApiCtx}>
			<ToastsContext.Provider value={toasts()}>{p.children}</ToastsContext.Provider>
		</ToastApiContext.Provider>
	)
}

export const useToasts = () => useContext(ToastApiContext)
