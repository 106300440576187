import type { TOptions } from 'i18next'

/**
 * This constant is defined as such so that when it is used, attention is paid
 * to the source and safety of the inputs used to define the interpolate values.
 *
 * It is very important that this is only used in cases where the values come
 * from trusted sources.
 */
export const TRANSLATE_UNSAFE_RAW = { interpolation: { escapeValue: false } as const } as const

export const tRaw = <T extends TOptions>(o: T): T & typeof TRANSLATE_UNSAFE_RAW => ({
	...o,
	...TRANSLATE_UNSAFE_RAW,
})
