import './Modal.scss'
// We have to import this here due to CSS sucking
import './PicModal.scss'

// NOTE: This is one weird case where eslint thinks this is a type-only import
//  when it's not. It appears to occur due to how we use `typeof ActionBtn`, or
//  perhaps even `Parameters<typeof ActionBtn>`. It then somehow does not "see"
//  the usage of `<ActionBtn {...p}/>` below. This is not an issue with the
//  other "odd" attributes of ActionBtn, such as it being generic, casting its
//  return, etc., b/c that works fine in `Btn`, `OPLink`, etc. Setting the
//  param of `RenderClose` to `p: ActionBtnProps<T>` with the proper generics
//  works to fix the issue with eslint import, but it would require anything
//  using `renderClose` to cast `...p` to `any`. This way is annoying, but
//  is better from a usage / types perspective.
/* eslint-disable-next-line */
import { useFn, useWindowEvent } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { isEscape, PageFocus, useHandleSynStop } from '@op/react-web'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { ReactNode } from 'react'
import { useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useFreezeScroll } from '../../hooks'
import { ActionBtn } from '../Buttons'
import { NavSlideBox } from '../Navigation/NavSlideBox'
import { useNav } from '../Navigation/useNav'

export type ModalSize = 'default' | 'fluid' | 'info' | 'md'

type RenderClose = (p: Parameters<typeof ActionBtn>[number]) => ReactNode

const DEFAULT_RENDER_CLOSE: RenderClose = (p) => <ActionBtn {...p} />

export type ModalProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly close?: RenderClose
	readonly dialogCls?: string
	readonly disableBgClose?: boolean
	readonly disableEscClose?: boolean
	readonly header?: ReactNode | (() => ReactNode)
	readonly onClose?: () => void
	readonly size?: ModalSize
}

const $MODAL_PORTAL_EL = document.getElementById('modal-container')!

/**
 * Component that creates a container for generic modals
 */
export const Modal = ({
	children,
	className,
	close = DEFAULT_RENDER_CLOSE,
	dialogCls,
	disableBgClose = false,
	disableEscClose = false,
	header,
	onClose = noop,
	size = 'default',
}: ModalProps) => {
	const [t] = useT()
	const freezeScrolling = useFreezeScroll()
	const enableKeyDownListener = !disableEscClose
	const nav = useNav()

	const handleCloseButtonClick = useHandleSynStop(onClose)
	const handleBackdropClick = useFn(() => {
		!disableBgClose && onClose()
	})
	const stopProp = useHandleSynStop(() => {
		if (nav.isOpen && nav.isMobile) nav.toggle()
	})

	useWindowEvent('keydown', (ev) => isEscape(ev) && onClose(), undefined, enableKeyDownListener)

	useEffect(() => {
		freezeScrolling(true)

		return () => {
			freezeScrolling(false)
		}
	}, [])

	const ModalHeader = useMemo(
		() =>
			header &&
			(typeof header === 'function' ? (
				header()
			) : (
				<header className="modal__header">
					<div className="modal__header-wrap">
						{header && <h2 className="modal__heading-main">{header}</h2>}
					</div>

					{close({
						icon: 'x',
						onClick: handleCloseButtonClick,
						title: t('actions.close'),
						invert: true,
					})}
				</header>
			)),
		[t, close, header],
	)

	// We add `!pl-0` so the slide box isn't affected by desktop nav. Our only reason for
	// using `NavSlideBox` is the mobile menu transform, especially for `HybridModal`.
	return createPortal(
		<NavSlideBox className={cls('modal !pl-0', className)} onClick={handleBackdropClick}>
			<PageFocus />
			<div className="modal__scroll touch-scroll-y">
				<div className={cls(`modal__dialog modal__dialog--${size}`, dialogCls)} onClick={stopProp}>
					{ModalHeader}

					<div className="modal__content">{children}</div>
				</div>
			</div>
		</NavSlideBox>,
		$MODAL_PORTAL_EL,
	)
}
