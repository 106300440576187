import type { ReactNode } from 'react'
import { createContext } from 'react'

/**
 * We use a single context for just the NavBar ReactNodes. The only thing that
 * will consume this context is the `NavBarOutlet`, so this avoids superfluous
 * render (and possible render loops), if we were to put this in the `NavCtx`.
 *
 * We do put the function to set the `NavBar` in the `NavCtx`, since we don't
 * want to have to memoize the `NavBarCtx` value. This is semantically an odd
 * decision, because you'd think that doing `setNavBar` should occur via the
 * `NavBarCtx`. However, due to the structure of `NavBar`, `NavBarOutlet`, and
 * the providers, separating the node from the function results in fewer
 * renders.
 *
 * Since the only component that uses the `NavBarCtx` is the `NavBarOutlet`,
 * the awkward semantics aren't really a concern.
 */
export type NavBarCtx = ReactNode

export const NavBarCtx = createContext<NavBarCtx>(null)
