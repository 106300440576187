import { lazy } from 'react'

export const PairAndroidPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-android' */
			/* webpackPrefetch: 0 */
			'./PairAndroidPage'
		),
)

export const PairErrorPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-error' */
			/* webpackPrefetch: 0 */
			'./PairErrorPage'
		),
)

export const PairAddPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-add' */
			/* webpackPrefetch: 0 */
			'./PairAddPage'
		),
)

export const PairChildAppPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-child-app' */
			/* webpackPrefetch: 0 */
			'./PairChildAppPage'
		),
)

export const PairConnectPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-connect' */
			/* webpackPrefetch: 0 */
			'./PairConnectPage'
		),
)

export const PairSelectPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-select' */
			/* webpackPrefetch: 0 */
			'./PairSelectPage'
		),
)

export const PairSuccessPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-success' */
			/* webpackPrefetch: 0 */
			'./PairSuccessPage'
		),
)

export const PairUnsupportedPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair-unsupported' */
			/* webpackPrefetch: 0 */
			'./PairUnsupportedPage'
		),
)

export const PairPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair' */
			/* webpackPrefetch: 100 */
			'./PairPage'
		),
)
