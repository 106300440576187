import './DeviceDetailsView.scss'

import { useBoolState, useFn, useMounted } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import type { DeviceBlockStatus, ManagedDevice, RawManagedUser } from '@op/services'
import { isIOSDevice, removeDevice } from '@op/services'
import cls from 'classnames'
import { useMemo } from 'react'
import { tRaw } from '../../common'
import { useAppDispatch } from '../../types'
import { ActionBtn } from '../Buttons'
import { useToasts } from '../Toast'
import { ChildAppInstallBtn } from './ChildAppInstallBtn'
import { DeviceStatus } from './DeviceStatus'

type DeviceViewProps = {
	readonly device: ManagedDevice
	readonly deviceStatus: DeviceBlockStatus
	readonly onClose: () => void
	readonly onAndroidRemoveManagement: () => void
	readonly user: RawManagedUser
}

export const DeviceDetailsView = ({
	deviceStatus,
	device,
	onAndroidRemoveManagement,
	user,
	...p
}: DeviceViewProps) => {
	const d = useAppDispatch()
	const [t] = useT('devices.device')
	const [isDeletingDevice, setDeletingDevice, setNotDeletingDevice] = useBoolState(false)
	const { addConfirmToast, addErrorToast, addInfoToast } = useToasts()
	const isMounted = useMounted()

	const deviceName = device.displayName
	const isApple = device.make.toLowerCase() === 'apple'
	const isPending = deviceStatus.viewState === 'pending'
	const isManaged = deviceStatus.viewState !== 'unmanaged'
	const userName = user.user_name || ''

	const showPendingDetails = isManaged && isPending
	const showUnmanagedAppleDetails = !isManaged && isApple
	const showUnmanagedAndroidDetails = !isManaged && !isApple
	const showChildAppInstall = isManaged && device.os === 'iOS'

	// Once user has confirmed delete we go through and make the delete requests
	const handleConfirmDeleteDevice = useFn(async () => {
		const translationOpt = tRaw({ child: userName, deviceName })

		try {
			await d(removeDevice(device.device_id)).unwrap()

			addInfoToast(t.$('messages.delete_device_success', translationOpt))

			p.onClose()
		} catch {
			addErrorToast(t.$('messages.delete_device_error', translationOpt))
		}
	})

	// Initiates the "delete" flow by first prompting with a confirmation toast
	const handleDeleteDevice = useFn(() => {
		if (isDeletingDevice) return

		setDeletingDevice()

		const cancelDeleting = () => isMounted() && setNotDeletingDevice()

		// Check to see if it is a deletable android device
		const cannotDeleteDevice =
			!isIOSDevice(device) &&
			!device.management_removed &&
			Number.parseInt(device.app_version, 10) < 4

		if (cannotDeleteDevice) {
			// If the device cannot be deleted (android management needs to be
			// removed first) then we show a modal with instructions. Since this
			// is currently displayed in a modal we must show that modal and
			// call close
			onAndroidRemoveManagement()
			return p.onClose()
		}

		addConfirmToast({
			msg: t.$('actions.delete_confirmation', tRaw({ value: deviceName })),
			onConfirm: handleConfirmDeleteDevice,
			onCancel: cancelDeleting,
			onClose: cancelDeleting,
		})
	})

	const PendingDetails = useMemo(
		() =>
			showPendingDetails && (
				<div className="device-details-view__bottom">
					<header className="device-details-view__bottom-header">
						{t('pending_description_header')}
					</header>

					<ol className="list-decimal">
						<li>{t('pending_reason_1')}</li>
						<li>{t('pending_reason_2')}</li>
						<li>{t('pending_reason_3')}</li>
					</ol>
				</div>
			),
		[t, showPendingDetails],
	)

	// Shows unmanaged device text for iOS Devices
	const UnmanagedApple = useMemo(
		() =>
			showUnmanagedAppleDetails && (
				<div className="device-details-view__bottom">
					<header className="device-details-view__bottom-header">
						{t('unmanaged_apple_header', tRaw({ deviceName }))}
					</header>
				</div>
			),
		[t, deviceName, showUnmanagedAppleDetails],
	)

	// Shows unmanaged device text for Android Devices
	const UnmanagedAndroid = useMemo(
		() =>
			showUnmanagedAndroidDetails && (
				<div className="device-details-view__bottom">
					<header className="device-details-view__bottom-header">
						{t('unmanaged_android_header')}
					</header>

					<ol className="list-decimal">
						<li>{t('repair_android_step_1', tRaw({ childName: userName, deviceName }))}</li>
						<li>{t('repair_android_step_2')}</li>
						<li>{t('repair_android_step_3')}</li>
						<li>{t('repair_android_step_4')}</li>
						<li>{t('repair_android_step_5')}</li>
					</ol>
				</div>
			),
		[t, deviceName, showUnmanagedAndroidDetails, userName],
	)

	// Shown when an iOS devices is managed but child app is not installed
	const InstallAppSection = useMemo(
		() =>
			showChildAppInstall && (
				<div className="device-details-view__bottom flex flex-col items-center mt-5 break-word">
					<h5 className="font-bold mb-3">{t('ourpact_jr')}</h5>
					<p className="text-sm sm:text-base">{t('install_child_app_description')}</p>
				</div>
			),
		[t, showChildAppInstall],
	)

	const InstallAppBtn = useMemo(
		() =>
			showChildAppInstall && (
				<ChildAppInstallBtn
					childId={user.user_id}
					deviceId={device.device_id}
					disabled={isDeletingDevice}
				/>
			),
		[showChildAppInstall, user.user_id, device.device_id, isDeletingDevice],
	)

	return (
		<>
			<section className="device-details-view">
				<DeviceStatus device={device} status={deviceStatus} />

				{PendingDetails}
				{UnmanagedApple}
				{UnmanagedAndroid}
				{InstallAppSection}
			</section>

			<div className={cls('modal__bottom', !InstallAppBtn && 'no-margin')}>
				{InstallAppBtn}
				<ActionBtn
					color="red"
					className="is-right"
					icon="trash"
					invert
					onClick={handleDeleteDevice}
				/>
			</div>
		</>
	)
}
