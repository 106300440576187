import { lazy } from 'react'

export const NavNotifBtn = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'nav-notif-btn' */
			/* webpackPrefetch: 0 */
			'./NavNotifBtn'
		),
)
