import {
	fetchAccessState,
	fetchBlockStatuses,
	fetchEncryptedPrivate,
	fetchPurchaseSummary,
	isAuthenticated$,
	unwrapThunk,
	unwrapThunks,
} from '@op/services'
import type { History } from 'history'
import { createResolverRedirect, resolveSerial } from '../common'
import { setRedirect } from '../reducers/app-misc.slice'
import { fetchGooglePurchasesAction } from '../thunks/google-purchase'
import type { AppStore } from '../types'
import { accountGenArgs } from '../util/crypto'

const isAuthenticatedResolver = async (store: AppStore, history: History) => {
	const isAuth = isAuthenticated$(store.getState())

	if (!isAuth) {
		/** @see resolveSerial */
		await Promise.resolve()
		store.dispatch(setRedirect(history.location.pathname))
		throw createResolverRedirect('/login')
	}
}

const rootStateResolver = async (store: AppStore) => {
	unwrapThunk(store.dispatch(fetchPurchaseSummary()))

	await Promise.all(
		unwrapThunks([
			store.dispatch(fetchAccessState()),
			store.dispatch(fetchBlockStatuses()),
			store.dispatch(fetchEncryptedPrivate(accountGenArgs())),
		]),
	)

	await store.dispatch(fetchGooglePurchasesAction())
}

export const resolveSerialRootAuth = resolveSerial.bind(
	null,
	isAuthenticatedResolver,
	rootStateResolver,
)
export const resolveAuthRoot = /*@__PURE__*/ resolveSerialRootAuth()
