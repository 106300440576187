import type { TextComponentProps } from '@eturi/react-i18next'

type TextTags = 'strong' | 'i' | 'text'
type TextExtraProps = {
	readonly bold?: boolean
	readonly color?: string
	readonly italic?: boolean
}

export const TextTrans = ({
	bold,
	children,
	color,
	italic,
	type,
}: TextComponentProps<TextTags, TextExtraProps>) => {
	bold ||= type === 'strong' || undefined
	italic ||= type === 'i' || undefined

	// No reason to create a new node for plain text
	if (!(bold || color || italic)) return children as JSX.Element

	return (
		<span
			className="text-node"
			style={{
				color,
				fontWeight: bold ? 'bold' : undefined,
				fontStyle: italic ? 'italic' : undefined,
			}}
		>
			{children}
		</span>
	)
}
