import { useConstant } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { PREM_PLUS_NAME } from '@op/services'
import { lazy, memo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { LoadPage, NavBar } from '../../components'
import { trialDurationInDays$ } from '../../compound-selectors/product'
import { useSearchParams } from '../../hooks/useSearchParams'
import type { SubscriptionType } from '../../types'
import { isSubscriptionType } from '../../types'

// We normalize the type in case someone accesses the url directly without
// query params
const normalizeSubType = (type: Maybe<string>): SubscriptionType =>
	isSubscriptionType(type) ? type : 'options'

const SubscriptionPageContent = lazy(() => import('./SubscriptionPageContent'))

const SubscriptionPage = () => {
	const [t] = useT()
	const history = useHistory()
	const trialDurationInDays = useSelector(trialDurationInDays$)
	const searchParams = useSearchParams()

	const [chooseSubType, goBack] = useConstant(
		() =>
			[
				normalizeSubType(searchParams.get('chooseSubType')),
				searchParams.get('goBack') === '1',
			] as const,
	)

	const PageTitle = (() => {
		switch (chooseSubType) {
			case 'options':
				return t('subscription.subscription_options')

			case 'prem_plus':
			case 'prem_plus_trial':
				return t('actions.upgrade_to_tier', { tier: PREM_PLUS_NAME })

			case 'trial':
				return t('subscription.title_trial', { trialDays: trialDurationInDays })

			case 'upgrade':
				return t('subscription.upgrade_options')
		}
	})()

	// When the page loads we clear query search params from url
	useEffect(() => {
		history.replace({ search: '' })
	}, [])

	return (
		<article className="page is-page-pad-touch">
			<NavBar.Default goBack={goBack}>{PageTitle}</NavBar.Default>

			<LoadPage>
				<SubscriptionPageContent type={chooseSubType} />
			</LoadPage>
		</article>
	)
}

export default /*@__PURE__*/ memo(SubscriptionPage)
