import './LoadingSpinner.scss'

import { useTimeoutReady } from '@eturi/react'
import cls from 'classnames'
import { memo, useMemo } from 'react'

type LoadingSpinnerProps = {
	readonly className?: string
	readonly timeout?: number
}

export const LoadingSpinner = ({ className, timeout = 200 }: LoadingSpinnerProps) => {
	const [isReady] = useTimeoutReady(timeout)

	return useMemo(
		() => (isReady() ? <figure className={cls('loading-spinner', className)} /> : null),
		[isReady()],
	)
}

export const PageLoadingSpinner = /*@__PURE__*/ memo(() => (
	<div className="page-loading-spinner">
		<LoadingSpinner />
	</div>
))

PageLoadingSpinner.displayName = 'PageLoadingSpinner'
