import { lazy } from 'react'

export const ProductModal = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'product-modal' */
			/* webpackPrefetch: 0 */
			'./ProductModal'
		),
)

export const StripeForm = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'stripe-form' */
			/* webpackPrefetch: -10 */
			'./StripeForm'
		),
)
