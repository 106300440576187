import { durationAs } from '@eturi/date-util'
import { notIdentity } from '@op/util'
import { createSelector } from '@reduxjs/toolkit'
import { activeChildId$, blockStatusMap$, isVewEnabled$ } from '../reducers'
import type { BlockStatus, ViewBlockState } from '../types'
import { getDesiredVewBlockState, isIOSDevice, isOverrideRule } from '../types'
import { validManagedDevices$ } from './device'
import { hasActiveUserOverrideRule$ } from './rule'
import { isVewEnabledForActive$ } from './vew'

export const activeUserBlockStatus$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	blockStatusMap$,
	(id, bsm): Maybe<BlockStatus> => bsm[id || ''],
)

export const activeUserUsage$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	(s) => s?.usage,
)

const ALL_DAY_SECS = durationAs(1, 'd', 's')

export const canShowActiveUsageTimeRemaining$ = /*@__PURE__*/ createSelector(
	activeUserUsage$,
	(u) => (u?.time_limit ?? ALL_DAY_SECS) < ALL_DAY_SECS,
)

/**
 * This selector couples rule state and block status state to make sure that
 * _both_ have an override rule state. This gives the most consistent result
 * when rules and block status aren't necessarily in sync.
 */
export const hasActiveUserOverride$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	hasActiveUserOverrideRule$,
	(blockStatus, hasOverrideRule) => isOverrideRule(blockStatus?.rule) && hasOverrideRule,
)

export const isActiveUserScheduleActive$ = /*@__PURE__*/ createSelector(
	hasActiveUserOverride$,
	activeUserBlockStatus$,
	(hasOverride, status) =>
		!hasOverride && status?.state === 'blocked' && status?.rule.type !== 'allowance',
)

/**
 * State defaults to allowed when we don't have data, although, this should be
 * behind a loading state anyway.
 */
export const activeUserViewBlockState$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	(s): ViewBlockState => s?.viewState || 'allowed',
)

export const activeUserDesiredVewBlockState$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	(blockStatus) => getDesiredVewBlockState(blockStatus?.state, blockStatus?.viewState),
)

export const isActiveUserStatusPending$ = /*@__PURE__*/ createSelector(
	activeUserViewBlockState$,
	(s) => s === 'pending',
)

export const isActiveUserUnmanaged$ = /*@__PURE__*/ createSelector(
	activeUserViewBlockState$,
	(s) => s === 'unmanaged',
)

export const isActiveUserManaged$ = /*@__PURE__*/ createSelector(
	isActiveUserUnmanaged$,
	notIdentity,
)

export const isActiveUserUsagePaused$ = /*@__PURE__*/ createSelector(
	activeUserUsage$,
	(u) => u?.displayState === 'paused',
)

export const isActiveUserUsagePlaying$ = /*@__PURE__*/ createSelector(
	activeUserUsage$,
	(u) => u?.displayState === 'playing',
)

export const isActiveUserBlockedByAllowance$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	(status) => status?.state === 'blocked' && status?.rule.type === 'allowance',
)

export const isActiveUserBlockedByVew$ = /*@__PURE__*/ createSelector(
	activeUserBlockStatus$,
	(status) =>
		status?.state === 'allowed' &&
		status?.devices.some((d) => d.viewState === 'allowed' && d.vewStatus === 'inactive'),
)

export const showVewBlockStatusIOSInfo$ = /*@__PURE__*/ createSelector(
	isVewEnabled$,
	isVewEnabledForActive$,
	validManagedDevices$,
	(isVewEnabled, isVewEnabledForActive, devices) =>
		isVewEnabled && isVewEnabledForActive && devices.some((d) => isIOSDevice(d)),
)
